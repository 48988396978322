import React, { useEffect } from 'react'
// import { Chart } from 'react-charts'
import { DocumentIcon, HandThumbUpIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import { Helmet } from 'react-helmet'

import useRootStore from '../../../hooks/useRootStore'
import Select from '../../forms/SelectMenus/Select'
import StatCard from '../../dataDisplay/stats/StatCard'

function Home () {
  const rootStore = useRootStore()
  const [chartData, setChartData] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [range, setRange] = React.useState(7)

  useEffect(() => {
    async function init () {
      const { charts } = await rootStore.fetch(`/data/pages/property-dashboard?range=${range}`)
      setChartData(charts)
      setLoading(false)
    }
    init()
  }, [rootStore, range])
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
        <div className='flex justify-between items-center'>
          <div>
            <Select
              value={range}
              onChange={e => setRange(Number(e.target.value))}
              options={[
                { label: 'Last 24 hours', value: 1 },
                { label: 'Last 7 days', value: 7 },
                { label: 'Last 30 days', value: 30 },
                { label: 'Last 90 days', value: 90 },
                { label: 'Last 180 days', value: 180 },
                { label: 'Last 365 days', value: 365 }
              ]}
            />
          </div>
        </div>
        {!loading && (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <StatCard
            Icon={DocumentIcon}
            label='Total Applications'
            stat={chartData.currentTotalApps}
            previousStat={chartData.prevTotalApps}
            to='/applications'
          />

          <StatCard
            percentage
            Icon={HandThumbUpIcon}
            label='Approval Rate'
            stat={chartData.currentTotalApps === 0 ? chartData.currentTotalApps : Math.round(chartData.currentTotalApprovedApps / (chartData.currentTotalApprovedApps + chartData.currentTotalDeclinedApps) * 100 * 100) / 100}
            previousStat={chartData.prevTotalApps === 0 ? chartData.prevTotalApps : Math.round(chartData.prevTotalApprovedApps / (chartData.prevTotalApprovedApps + chartData.prevTotalDeclinedApps) * 100 * 100) / 100}
            to='/applications?stage=lender-approved|lender-funded'
          />
          <StatCard
            dollar
            Icon={BanknotesIcon}
            label='Total Funded'
            stat={0}
            previousStat={0}
            to='/applications?stage=lender-funded'
          />
        </dl>
        )}
      </div>
    </>
  )
}

export default Home
