import React, { useEffect } from 'react'
import { BriefcaseIcon, DocumentMagnifyingGlassIcon, HomeIcon } from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Toaster } from 'react-hot-toast'

import AddMerchantModal from '../../modals/AddNewMerchantModal'
import EditMerchantModal from '../../modals/EditMerchantModal'
import InviteUserToMerchantModal from '../../modals/InviteUserToMerchantModal'
import useRootStore from '../../../hooks/useRootStore'
import MainWrapper from '../MainWrapper'

function PropertyWrapper () {
  const rootStore = useRootStore()
  const { pathname } = useLocation()

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: pathname === '/' },
    { name: 'Applications', href: '/applications', icon: DocumentMagnifyingGlassIcon, current: pathname === '/applications' },
    { name: 'Merchants', href: '/merchants', icon: BriefcaseIcon, current: pathname === '/merchants' }
  ]

  // Load properties and merchant that this user belongs too (actually do on serveApp)

  useEffect(() => rootStore.initSocketIO(), [rootStore])
  return (
    <>
      <MainWrapper navigation={navigation} />

      {/* Modals */}
      <AddMerchantModal
        isOpen={rootStore.currentDialog === 'addMerchant'}
        onClose={rootStore.closeDialog}
        elements={[]}
        onSave={async (data) => {
          const merchant = await rootStore.fetch('/api/merchants/create', 'POST', data)
          rootStore.merchantStore.addMerchantFromJSON(merchant)
        }}
      />

      <EditMerchantModal
        isOpen={rootStore.currentDialog.includes('editMerchant')}
        onClose={rootStore.closeDialog}
        merchantID={rootStore.currentDialog.split('.')[1]}
        onSave={async (data) => {
          const json = await rootStore.fetch(`/api/merchants/${rootStore.currentDialog.split('.')[1]}/update`, 'POST', { data })
          rootStore.merchantStore.updateMerchantFromJSON(json)
        }}
      />

      <InviteUserToMerchantModal
        isOpen={rootStore.currentDialog.includes('inviteUserToMerchant')}
        merchantID={rootStore.currentDialog.split('.')[1]}
        onClose={rootStore.closeDialog}
        sendInvite={async (data) => {
          const json = await rootStore.fetch(`/api/merchants/${rootStore.currentDialog.split('.')[1]}/invite`, 'POST', data)
          if (!json.error) rootStore.inviteStore.addInviteFromJSON(json)
          else console.log('display error', json.error) // add toastr
        }}
      />
      <Toaster position='top-right' />
    </>
  )
}

export default observer(PropertyWrapper)
