import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'

import PrimaryNavigation from '../../../../navigation/vertical/PrimaryNavigation'
import Header from '../Header'
import Footer from '../Footer'
import CloseButton from './CloseButton'

function MobileSidebar ({ sidebarOpen, setSidebarOpen, links, openHelpSidebar, hideFooter }) {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
        {/* Background */}
        <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        {/* Overlay */}
        <div className="fixed inset-0 flex z-40">
          <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-primaryDark">
              {/* Close Button */}
              <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div>
                  <CloseButton onClick={() => setSidebarOpen(false)} />
                </div>
              </Transition.Child>

              {/* Main */}
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <Header />
                <div className='mt-6 px-4'>
                  <PrimaryNavigation links={links} />
                </div>
              </div>
              {/* Footer */}
              {!hideFooter &&
              <Footer openHelpSidebar={openHelpSidebar} />
              }
            </Dialog.Panel>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

MobileSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  openHelpSidebar: PropTypes.func.isRequired,
  hideFooter: PropTypes.bool
}

export default MobileSidebar
