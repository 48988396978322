import React from 'react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Card from '../../layout/panels/BasicCard'
import integrations from '../../../constants/integrations'
import lenders from '../../../constants/lenders'
import Application from '../../../stores/Application'

function Task ({ complete, label, danger }) {
  return (
    <div className='py-2 sm:py-4 px-4 sm:px-6 flex items-center'>
      <div className='flex items-center'>
        <div className={classNames('border-2 w-6 h-6 sm:w-9 sm:h-9 flex items-center justify-center rounded-full mr-4 shrink-0', {
          'bg-green-500 border-green-500 text-white': complete,
          'text-gray-500 border-gray-300': !complete,
          'bg-red-500 border-red-500 text-white': danger
        })}>
          {!danger && <CheckIcon className='w-4 h-4 sm:w-5 sm:h-5' />}
          {!!danger && <XMarkIcon className='w-4 h-4 sm:w-5 sm:h-5' />}
        </div>
        <div className={classNames('font-medium text-sm')}>
          {label}
        </div>
      </div>
    </div>
  )
}

Task.propTypes = {
  complete: PropTypes.bool,
  label: PropTypes.string.isRequired,
  danger: PropTypes.bool
}

function ChecklistCard ({ application }) {
  const lenderDetails = integrations[application.suggestedLender]
  const lender = lenders[application.suggestedLender]

  return (
    <Card>
      <div className="px-4 py-2 sm:py-5 sm:px-6">
        <h2 id="notes-title" className="sm:text-base md:text-lg font-medium text-gray-900">
          Project Status
        </h2>
      </div>
      <div className='border-t py-2'>
        <Task complete label={`Prequalified with ${lenderDetails?.name}`} />
        <Task complete={!!application.lenderRelationship} label={`Application completed and sent to ${lenderDetails?.name}`} />
        {lender.getChecklistItems(application).map((item, i) => (
          <Task key={i} complete={item.isComplete(application)} label={item.label} danger={item.danger} />
        ))}
      </div>
    </Card>
  )
}

ChecklistCard.propTypes = {
  application: PropTypes.instanceOf(Application).isRequired
}

export default ChecklistCard
