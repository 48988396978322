import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import * as Sentry from '@sentry/react'

// util
import RootStore, { Context as RootStoreContext } from '../../stores/RootStore'
// wrappers
import ApplicantWrapper from './ApplicantWrapper'
import DeveloperWrapper from './DeveloperWrapper'
import MerchantWrapper from './MerchantWrapper'
import PropertyWrapper from './PropertyWrapper'
// pages
import AdminPostmark from '../pages/AdminPostmark'
import APISettings from '../pages/APISettings'
import ApplicationDetail from '../pages/ApplicationDetail'
import ApplicationList from '../pages/ApplicationList'
import DeveloperDashboard from '../pages/DeveloperDashboard'
import GeneralSettings from '../pages/GeneralSettings'
import GuestUserLanding from '../pages/GuestUserLanding'
import Home from '../pages/Home'
import IntegrationSettings from '../pages/IntegrationSettings'
import LenderApplication from '../pages/LenderApplication'
import Login from '../pages/Login'
import MerchantDetails from '../pages/MerchantDetails'
import MerchantDetailsMembers from '../pages/MerchantDetailsMembers'
import MerchantDetailsOverview from '../pages/MerchantDetailsOverview'
import MerchantDetailsSettings from '../pages/MerchantDetailsSettings'
import MerchantInbox from '../pages/MerchantInbox'
import MerchantList from '../pages/MerchantList'
import NoMatch from '../pages/404'
import PreQualification from '../pages/PreQualification'
import PrequalificationSettings from '../pages/PrequalificationSettings'
import PropertyAlerts from '../pages/PropertyAlerts'
import PropertyMembers from '../pages/PropertyMembers'
import Register from '../pages/Register'
import SelectLender from '../pages/SelectLender'
import MerchantTeam from '../pages/MerchantTeam'
import SettingsWrapper from './SettingsWrapper'

dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App ({ rootStore }) {
  if (rootStore.currentUser && !rootStore.currentProperty && !rootStore.currentMerchant && rootStore.currentUser.isDeveloper) {
    return (
      <RootStoreContext.Provider value={rootStore}>
        <Router>
          <Routes>
            <Route path="/" element={<DeveloperWrapper />}>
              <Route index element={<DeveloperDashboard />} />
            </Route>
            <Route path='*' element={<NoMatch />} />
          </Routes>
        </Router>
      </RootStoreContext.Provider>
    )
  }
  if (rootStore.currentUser && !rootStore.currentProperty && !rootStore.currentMerchant) {
    // random user not associated with anything
    return (
      <RootStoreContext.Provider value={rootStore}>
        <Router>
          <SentryRoutes>
            <Route path="/" element={<GuestUserLanding />} />
            <Route path='*' element={<NoMatch />} />
          </SentryRoutes>
        </Router>
      </RootStoreContext.Provider>
    )
  }

  if (rootStore.currentUser && rootStore.currentMerchant) {
    return (
      <RootStoreContext.Provider value={rootStore}>
        <Router>
          <SentryRoutes>
            <Route path="/" element={<MerchantWrapper />}>
              <Route index element={<MerchantInbox />} />
              <Route path="/applications" element={<ApplicationList />} />
              <Route path='/applications/:id' element={<ApplicationDetail />} />
              <Route path='/team' element={<MerchantTeam />} />
            </Route>
            <Route path='*' element={<NoMatch />} />
          </SentryRoutes>
        </Router>
      </RootStoreContext.Provider>
    )
  }
  if (rootStore.currentUser) {
    return (
      <RootStoreContext.Provider value={rootStore}>
        <Router>
          <SentryRoutes>
            <Route path="/" element={<PropertyWrapper />}>
              <Route index element={<Home />} />
              <Route path="/applications" element={<ApplicationList />} />
              <Route path='/applications/:id' element={<ApplicationDetail />} />
              <Route path="/merchants" element={<MerchantList />} />
              <Route path="/merchants/:id" element={<MerchantDetails />}>
                <Route index element={<MerchantDetailsOverview />} />
                <Route path='members' element={<MerchantDetailsMembers />} />
                <Route path='settings' element={<MerchantDetailsSettings />} />
              </Route>
            </Route>
            <Route path="/settings" element={<SettingsWrapper />}>
              <Route index element={<GeneralSettings />} />
              <Route path='team' element={<PropertyMembers />} />
              <Route path='alerts' element={<PropertyAlerts />} />
              <Route path='prequalification' element={<PrequalificationSettings />} />
              <Route path='api' element={<APISettings />} />
              <Route path='integrations/:integration' element={<IntegrationSettings />} />
            </Route>
            <Route path="/postmark" element={<AdminPostmark />} />
            <Route path='*' element={<NoMatch />} />
          </SentryRoutes>
        </Router>
      </RootStoreContext.Provider>
    )
  }

  if (rootStore.currentProperty) {
    return (
      <RootStoreContext.Provider value={rootStore}>
        <Router>
          <SentryRoutes>
            <Route path="/" element={<Navigate replace to="/apply" />} />
            <Route path="/register" element={<Register />} />
            <Route path="/:merchantSlug/apply" element={<ApplicantWrapper />}>
              <Route index element={<PreQualification />} />
              <Route path=':id' element={<SelectLender />} />
              <Route path=':id/:complete' element={<SelectLender />} />
              <Route path=':id/:lenderSubdomain' element={<LenderApplication />} />
            </Route>
          </SentryRoutes>
        </Router>
      </RootStoreContext.Provider>
    )
  }
  return (
    <RootStoreContext.Provider value={rootStore}>
      <Router>
        <SentryRoutes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path='*' element={<NoMatch />} />
        </SentryRoutes>
      </Router>
    </RootStoreContext.Provider>
  )
}

App.propTypes = {
  rootStore: PropTypes.instanceOf(RootStore).isRequired
}
export default App
