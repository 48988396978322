import validate from '../../../../utils/validate'

const dobGetErrorMessage = ({ answer }) => {
  const [m, d, y] = answer.split('/')
  const month = Number(m)
  const day = Number(d)
  const year = Number(y)
  if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    return 'Please enter a valid date.'
  }
  if (month < 1 || month > 12) {
    return 'Please enter a valid month.'
  }
  if (day < 1 || day > 31) {
    return 'Please enter a valid day.'
  }
  if (year < 1900 || year > 2021) {
    return 'Please enter a valid year.'
  }
  const today = new Date()
  const birthDate = new Date(answer)
  let age = today.getFullYear() - birthDate.getFullYear()
  const ageM = today.getMonth() - birthDate.getMonth()
  if (ageM < 0 || (ageM === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  if (age < 18) return 'You must be at least 18 years old to apply.'
}

const primaryApplicant = {
  name: 'Homeowner 1',
  key: 'primary',
  number: '02',
  required: () => true,
  isRemovable: () => false,
  startRemoved: () => false,
  subsections: [
    {
      questions: [
        [
          {
            label: 'First Name',
            key: 'primaryApplicant.firstName',
            type: 'text',
            span: 'sm:col-span-2',
            required: true,
            disabled: true
          },
          {
            label: 'Middle Name',
            placeholder: '(optional)',
            key: 'primaryApplicant.middleName',
            type: 'text',
            span: 'sm:col-span-2',
            required: false
          },
          {
            label: 'Last Name',
            key: 'primaryApplicant.lastName',
            type: 'text',
            span: 'sm:col-span-2',
            required: true,
            disabled: true
          },
          {
            label: 'Email Address',
            key: 'primaryApplicant.email',
            type: 'email',
            span: 'sm:col-span-6',
            required: true,
            getErrorMessage: ({ answer }) => {
              if (!answer) return 'Please enter an email address.'
              if (!validate.email(answer)) return 'Please enter a valid email address.'
            }
          },
          {
            label: 'Mobile Number',
            key: 'primaryApplicant.mobile',
            type: 'phone',
            span: 'sm:col-span-6',
            required: false,
            getErrorMessage: ({ answer }) => {
              if (!answer) return 'Please enter a mobile number.'
              if (!validate.phone(answer)) return 'Please enter a valid mobile number.'
            }
          },
          {
            label: 'I want to opt-in to receive SMS notifications.',
            key: 'primaryApplicant.smsOptIn',
            type: 'checkbox',
            description: 'I want to opt-in to receive SMS notifications.',
            span: 'sm:col-span-6',
            className: '-mt-5 justify-end',
            required: true
          },
          {
            label: 'Social Security Number',
            key: 'primaryApplicant.ssn',
            type: 'ssn',
            required: true,
            getErrorMessage: ({ answer }) => {
              if (answer && !validate.ssn(answer)) {
                return 'Please provide a valid social security number.'
              }
            }
          },
          {
            label: 'Date of Birth',
            key: 'primaryApplicant.dob',
            type: 'day',
            required: false,
            getErrorMessage: dobGetErrorMessage
          }
        ],
        [
          {
            label: 'Billing Address',
            key: 'primaryApplicant.address',
            type: 'address',
            canBeSameAs: 'installationAddress',
            canBeSameAsLabel: 'Same as Project Address',
            required: true
          }
        ],
        [
          {
            label: 'Income Type',
            key: 'primaryApplicant.employmentType',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Employment Income', value: 'full-time' },
              { label: 'Retirement Income', value: 'retired' },
              { label: 'Disability Income', value: 'disability' }
            ]
          },
          {
            label: 'Employer Name',
            key: 'primaryApplicant.employerName',
            type: 'text',
            keyDependency: 'primaryApplicant.employmentType',
            dependencyValue: 'full-time',
            required: false
          },
          {
            label: 'Annual Income',
            key: 'primaryApplicant.annualIncome',
            type: 'dollar',
            required: true
          }
        ]
      ]
    }
  ]
}

export default primaryApplicant
