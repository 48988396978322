import PropTypes from 'prop-types'
import React from 'react'

function RawInformation ({ message, className, cy, disabled, Icon, inputWidth, disclosure, label, name, getErrorMessage, onChange, placeholder, type, value }) {
  return (
    <div className='mt-2' style={{ fontSize: 10 }}>
      {message}
    </div>
  )
}

RawInformation.propTypes = {
  className: PropTypes.string,
  cy: PropTypes.string,
  disabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  inputWidth: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  getErrorMessage: PropTypes.func,
  disclosure: PropTypes.string
}
RawInformation.defaultProps = {
  type: 'text'
}

export default RawInformation
