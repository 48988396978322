import React, { useState, useEffect } from 'react'
import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table'

import useRootStore from '../../../hooks/useRootStore'
import Invite from '../../../stores/Invite'
import Table from '../../elements/lists/Table'
import Badge from '../../elements/badges/Simple'

const columnHelper = createColumnHelper()
const getColumns = (rootStore, id) => [
  columnHelper.accessor('id', {
    header: 'Members',
    cell: data => data.row.original instanceof Invite
      ? <span><i>{data.row.original.userInfo ? `${data.row.original.userInfo.firstName} ${data.row.original.userInfo.lastName}` : '-'}</i> <Badge label="Pending Invite" /></span>
      : `${data.row.original.firstName} ${data.row.original.lastName}`
  }),
  columnHelper.accessor('email', {
    header: 'Email'
  }),
  columnHelper.accessor('isAdmin', {
    header: 'Role',
    cell: data => (
      <div
        className={data.getValue() ? 'text-amber-500 font-bold' : 'text-gray-500'}
        onClick={() => {
          rootStore.fetch(`/api/merchants/${id}/set-member-role`, 'POST', { email: data.row.original.email, isAdmin: !data.row.original.isAdmin })
        }}>
        {data.getValue() ? 'Admin' : 'User'}
      </div>
    )
  })
]

function MerchantTeam () {
  const rootStore = useRootStore()
  const [data, setData] = useState([])

  const table = useReactTable({
    data,
    columns: getColumns(rootStore, rootStore.currentMerchantID),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    async function init () {
      const { relationships, invites } = await rootStore.fetch(`/data/pages/merchant/${rootStore.currentMerchantID}/members`)
      setData([
        ...invites.map(rootStore.inviteStore.addInviteFromJSON),
        ...relationships.sort((a, b) => Number(b.isAdmin) - Number(a.isAdmin)).map(relationship => {
          const user = rootStore.userStore.addUserFromJSON(relationship.user)
          return { firstName: user.firstName, lastName: user.lastName, email: user.email, isAdmin: relationship.isAdmin, id: relationship._id }
        })
      ])
    }
    init()
  }, [rootStore])

  return (
    <div>
      <Table tableInstance={table} />
    </div>
  )
}

MerchantTeam.propTypes = {}

export default MerchantTeam
