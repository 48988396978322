import { makeObservable, observable, action } from 'mobx'
import Log from './Log'

class Logs {
  logs = []
  constructor (rootStore, logs = []) {
    this.root = rootStore
    this.logs = logs.map(json => new Log(this, json))
    makeObservable(this, {
      logs: observable,
      addLogFromJSON: action
    })
  }

  findById = (id) => this.logs.find(log => log.id === id)

  addLogFromJSON = (json) => {
    if (!this.findById(json._id)) {
      this.logs.push(new Log(this, json))
      return this.logs[this.logs.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }
}

export default Logs
