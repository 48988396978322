import React from 'react'
import PropTypes from 'prop-types'
import { Bars3Icon } from '@heroicons/react/24/outline'

function MobileHeader ({ setSidebarOpen }) {
  return (
    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
      <button
        type="button"
        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryLighter"
        onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  )
}

MobileHeader.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired
}

export default MobileHeader
