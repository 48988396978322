import convertUpdate from './convertUpdate'
const apiURL = () => {
  return process.env.NODE_ENV === 'production' ? 'https://beta.apptracker.ftlfinance.com' : 'https://staging-apptracker.herokuapp.com'
}
export default async function fetchUpdate (application, relationship, config) {
  if (!application.populated('merchant')) {
    await application.populate('merchant')
  }
  console.log('getting', `${apiURL()}/api/v1/applications/${relationship.id}?dealer_id=${application.merchant.lenderIDs?.ftl}&api_key=${config.submitApiKey}`)
  const response = await fetch(`${apiURL()}/api/v1/applications/${relationship.id}?dealer_id=${application.merchant.lenderIDs?.ftl}&api_key=${config.submitApiKey}`)
  const data = await response.json()
  console.log(11, data)
  if (data.error) {
    return
  }
  return convertUpdate(application, relationship, data)
}
