import React from 'react'
import PropTypes from 'prop-types'

function Simple ({ label, subLabel, buttonLabel, buttonCy, buttonOnClick }) {
  return (
    <div className="text-center">
      <h3 className="text-sm font-medium text-gray-900">{label}</h3>
      {subLabel &&
      <p className="mt-1 text-sm text-gray-500">{subLabel}</p>
      }

      {buttonLabel &&
      <div className="mt-6">
        <button
          type="button"
          data-cy={buttonCy}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryLight hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryLighter"
          onClick={buttonOnClick}>
          {buttonLabel}
        </button>
      </div>
      }
    </div>
  )
}

Simple.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonCy: PropTypes.string,
  buttonOnClick: PropTypes.func
}
export default Simple
