export default function (addressComponents) {
  const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.short_name
  const streetName = addressComponents.find(component => component.types.includes('route'))?.short_name
  const city = addressComponents.find(component => component.types.includes('locality'))?.short_name
  const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name
  const zipCode = addressComponents.find(component => component.types.includes('postal_code'))?.short_name

  return {
    streetAddress: `${streetNumber} ${streetName}`,
    city,
    state,
    zipCode
  }
}
