import React from 'react'
import PropTypes from 'prop-types'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react'

function SimpleWithChevrons ({ pages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-2">
        {pages.map((page, i) => (
          <li key={page.to}>
            <div className="flex items-center">
              <Link
                to={page.to}
                className={classNames('mr-2 text-m font-medium', page.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700')}
                aria-current={page.current ? 'page' : undefined}>
                {page.label}
              </Link>
              {i < pages.length - 1 && (
              <ChevronRightIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

SimpleWithChevrons.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    current: PropTypes.bool
  })).isRequired
}

export default observer(SimpleWithChevrons)
