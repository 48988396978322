import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'

import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import Toggle from '../../forms/toggles/Simple'

function InviteUserToPropertyModal ({ sendInvite, isOpen, onClose }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryLighter"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Invite User To Property
                    </Dialog.Title>
                    <div className="mt-2">
                      <span className="flex space-x-6 w-full">
                        <InputWithLabel
                          className="mb-3"
                          label="First Name"
                          name="firstName"
                          value={firstName || ''}
                          onChange={e => {
                            setFirstName(e.target.value)
                          }}
                        />
                        <InputWithLabel
                          className="mb-3"
                          label="Last Name"
                          name="lastName"
                          value={lastName || ''}
                          onChange={e => {
                            setLastName(e.target.value)
                          }}
                        />
                      </span>
                      <InputWithLabel
                        className="mb-3"
                        label="Email"
                        name="email"
                        value={email || ''}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex">
                  <div className='flex items-center'>
                    <div className='mr-2 text-xs'>Admin</div>
                    <Toggle label='Admin' enabled={isAdmin} setEnabled={setIsAdmin} />
                  </div>
                  <div className='sm:flex'>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        sendInvite({ firstName, lastName, email, isAdmin })
                        onClose(false)
                      }}>
                      Invite
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryLighter sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
InviteUserToPropertyModal.propTypes = {
  sendInvite: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default InviteUserToPropertyModal
