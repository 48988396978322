import React from 'react'
import { Link } from 'react-router-dom'

import integrations from '../constants/integrations'
import logKeys from '../constants/logKeys'

class Log {
  constructor (store, json) {
    this.store = store
    this.id = json._id.toString()
    this.updateFromJSON(json)
  }

  updateFromJSON (json) {
    this.level = json.level
    this.key = json.key
    this.data = json.data
    this.metadata = json.metadata

    this.userID = json.user
    this.applicationID = json.application
    this.propertyID = json.property
    this.merchantID = json.merchant

    this.createdAt = json.createdAt
  }

  get application () {
    return this.store.root.applicationStore.findById(this.applicationID)
  }

  get content () {
    switch (this.key) {
      case logKeys.PREQUALIFICATION_INVITE:
        return <span><Link to={`/users/${this.data.userId}`} className="text-blue-500 hover:text-blue-800">{this.data.user}</Link> invited <b>{this.data.contact}</b> to complete an application</span>
      case logKeys.INTEGRATION_FAILED:
        return <span>{this.data.integration} Failed: {this.data.reason}</span>
      case logKeys.LENDER_OFFERED:
        return <span>Prequalified for {integrations[this.data.lender].name}</span>
      case logKeys.LENDER_SUBMITTED:
        return <span>Submitted to {integrations[this.data.lender].name}</span>
      case logKeys.LENDER_ACTION_SUBMITTED:
        return <span>Information for {this.data.action} has been submitted</span>
      case logKeys.LENDER_APPROVED:
        return <span>Approved by {integrations[this.data.lender].name}</span>
      case logKeys.LENDER_DECLINED:
        return <span>Declined by {integrations[this.data.lender].name}</span>
      case logKeys.LENDER_ACTIVITY:
        return <span>{this.data && this.data.action}</span>
      default:
        return <span>{this.key}</span>
    }
  }
}

export default Log
