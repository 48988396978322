import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import useRootStore from '../../../hooks/useRootStore'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import Button from '../../elements/buttons/Primary'

function APISettings () {
  const rootStore = useRootStore()
  const [token, setToken] = useState(rootStore.currentProperty.apiToken || '')

  useEffect(() => {
    async function init () {}
    init()
  }, [rootStore])

  return (
    <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8'>
      <div className='flex justify-between items-center mb-12'>
        <div>
          <h1 id="message-heading" className="text-lg font-medium text-gray-900">
            API Tokens
          </h1>
          <p className="mt-1 text-sm text-gray-500 truncate">
            The API tokens are used to authenticate with the API and give full access to the property.
          </p>
        </div>
      </div>

      <div className='flex justify-between'>
        <div className='space-y-4 max-w-xl flex-1'>
          <InputGroup
            disabled
            label='Property API Token'
            value={token}
            name='api-token'
            onChange={(e) => setToken(e.target.value)}
          />
          <Button
            label='Generate New Token'
            onClick={async () => {
              const { apiToken } = await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/generate-api-token`, 'POST')
              if (apiToken) {
                rootStore.currentProperty.apiToken = apiToken
                setToken(apiToken)
              }
            }}
          />

          <div>
            View API docs at <a href='https://api.apptracker.dev/reference' target='_blank' rel='noreferrer' className='text-blue-500'>https://api.apptracker.dev</a>
          </div>
        </div>
      </div>
    </div>
  )
}

APISettings.propTypes = {}

export default observer(APISettings)
