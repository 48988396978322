const apiURL = (env) => {
  return env === 'production' ? 'https://api.greensky.com/transaction/submit' : 'https://api.uat1.greensky.com/transaction/submit'
}
const equipmentApiURL = (env) => {
  return env === 'production' ? 'https://beta.apptracker.ftlfinance.com' : 'https://staging-apptracker.herokuapp.com'
}
const completeProjectEquipment = {
  id: 1,
  title: 'Project Details',
  description: 'The homeowner for this application has finished signing their loan documents. Please enter the project details, including equipment information and installation date, to get funded.',
  modalTitle: 'Project Details',
  label: 'Complete Project',
  shouldShow: (application) => {
    const loanActivated = application.lenderRelationship?.metadata?.loanActivated
    const isEquipment = application.service === 'equipment'
    const isComplete = application.lenderRelationship?.metadata?.equipmentEntered
    return loanActivated && isEquipment && !isComplete
  },
  shouldShowQuery: { 'application.service': 'equipment', 'metadata.equipmentEntered': { $exists: false }, 'metadata.lenderLoanCreated': true, 'application.suggestedLender': 'greensky' },
  submit: async (data, application, relationship, integrationConfig) => {
    const response = await fetch(apiURL(process.env.NODE_ENV), {
      method: 'POST',
      // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': application?.merchant?.lenderIDs?.gsky_x_api_key
      },
      body: JSON.stringify({
        amount: data.chargeAmount,
        totalJobAmount: relationship?.metadata?.actualRequestedAmount,
        chargeType: 'Job Complete',
        invoiceNumber: data.invoice,
        applicationId: relationship?.id,
        creditCardUID: relationship?.metadata?.creditCardUID,
        txnCode: '101',
        channel: 'External-API-OptimusPlus'
      })
    })
    try {
      if (response.status === 200) {
        const json = await response.json()
        if (json.errors) return { success: false, error: json.errors[0].message }
        relationship.metadata = { ...relationship.metadata, txnNumber: json.txnNumber, equipmentEntered: true, equipment: data.equipment }
        await relationship.save()
        return true
      } else {
        const json = await response.json()
        return { success: false, error: json.error.message }
      }
    } catch {
      console.log('error')
    }
  },
  questions: [
    {
      key: 'equipment',
      label: 'What equipment did you purchase?',
      type: 'array',
      addLabel: 'Add Equipment',
      emptyMessage: 'No equipment',
      questions: [
        {
          label: 'Where was the equipment or parts purchased?',
          shortLabel: 'Distributor',
          key: 'distributor',
          hideInPreview: true,
          type: 'option',
          options: async (application, integrationConfig, depend, env) => {
            const response = await fetch(`${equipmentApiURL(env)}/api/v1/applications/${application.lenderRelationship?.id}/distributors?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
            const distributors = await response.json()
            return [
              { label: 'Select the distributor', value: '' },
              ...distributors.map(distributor => ({ label: distributor.name, value: distributor.id }))
            ]
          },
          span: 'sm:col-span-3',
          required: true
        },
        {
          label: 'What is the brand of the equipment being installed?',
          shortLabel: 'Brand',
          key: 'brand',
          type: 'option',
          keyDependency: 'distributor',
          options: async (application, integrationConfig, dependency, env) => {
            // TODO fix!!
            const response = await fetch(`https://staging-apptracker.herokuapp.com/api/v1/applications/${application.lenderRelationship?.id}/distributors?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
            const distributors = await response.json()
            const distributor = distributors.find(distributor => distributor.id === dependency)
            return [
              { label: 'Select the brand', value: '' },
              ...distributor.brands.map(brand => ({ label: brand, value: brand }))
            ]
          },
          span: 'sm:col-span-3',
          required: true
        },
        {
          label: 'Type the brand here',
          shortLabel: 'Brand',
          key: 'brandCustom',
          hideInPreview: true,
          type: 'text',
          shouldShow: (application, answers) => {
            return answers.brand === 'Other'
          }
        },
        {
          key: 'model',
          shortLabel: 'Model #',
          label: 'Model Number',
          type: 'text',
          required: true
        },
        {
          key: 'serialNumber',
          shortLabel: 'Serial #',
          label: 'Serial Number',
          type: 'text',
          required: true
        },
        {
          key: 'description',
          shortLabel: 'Description',
          label: 'Equipment Description',
          type: 'text',
          required: true
        }
      ]
    },
    {
      label: 'What date was the project completed?',
      key: 'installationDate',
      type: 'date',
      required: true
    },
    {
      label: 'Project Amount',
      key: 'chargeAmount',
      type: 'dollar',
      required: true
    },
    {
      label: 'Invoice (optional)',
      key: 'invoice',
      type: 'text',
      required: false
    }
  ]
}

const completeProjectService = {
  id: 2,
  shouldShow: (application) => {
    const loanActivated = application.lenderRelationship?.metadata?.loanActivated
    const isEquipment = application.service === 'equipment'
    const isComplete = application.lenderRelationship?.metadata?.equipmentEntered
    return loanActivated && !isEquipment && !isComplete
  },
  shouldShowQuery: { 'application.service': { $ne: 'equipment' }, 'metadata.equipmentEntered': { $exists: false }, 'metadata.lenderLoanCreated': true, 'application.suggestedLender': 'greensky' },
  title: 'Project Details',
  description: 'The homeowner for this application has finished signing their loan documents. Please enter the project details, including equipment information and installation date, to get funded.',
  modalTitle: 'Project Details',
  label: 'Complete Project',
  submit: async (data, application, relationship, integrationConfig) => {
    const response = await fetch(apiURL(process.env.NODE_ENV), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': integrationConfig.x_api_key
      },
      body: JSON.stringify({
        amount: data.chargeAmount,
        totalJobAmount: application.lenderRelationship?.metadata?.approvedAmount,
        chargeType: 'Job Complete',
        invoiceNumber: data.invoice,
        applicationId: application.lenderRelationship?.id,
        creditCardUID: data.creditCardUID,
        txnCode: '101',
        channel: 'External-API-OptimusPlus'
      })
    })
    try {
      if (response.status === 200) {
        const json = await response.json()
        if (json.error) return { success: false, error: json.error.message }
        relationship.metadata = { ...relationship.metadata, equipmentEntered: true }
        relationship.reason = 'action-gsky-2'
        await relationship.save()
        return true
      } else {
        const json = await response.json()
        return { success: false, error: json.error.message }
      }
    } catch {
      console.log('error-')
    }
  },
  questions: [
    {
      label: 'What date was the project completed?',
      key: 'installationDate',
      type: 'date',
      required: true
    },
    {
      label: 'Please describe the service being performed',
      key: 'serviceDescription',
      type: 'text',
      required: true,
      placeholder: 'Description...'
    },
    {
      label: 'Project Amount',
      key: 'chargeAmount',
      type: 'dollar',
      required: true
    },
    {
      label: 'Invoice',
      key: 'invoice',
      type: 'text',
      required: false
    }
  ]
}

const notifyHomeowner = {
  id: 3,
  shouldShow: (application) => {
    const loanCreated = application.lenderRelationship?.metadata?.lenderLoanCreated
    const loanActivated = application.lenderRelationship?.metadata?.loanActivated
    const isComplete = application.lenderRelationship?.metadata?.equipmentEntered
    return loanCreated && !loanActivated && !isComplete
  },
  className: 'text-red-600',
  shouldShowQuery: { 'metadata.lenderLoanCreated': true, 'metadata.equipmentEntered': { $exists: false }, 'metadata.lenderStatus.loan': false },
  title: 'Project Details',
  description: 'The homeowner needs to activate their loan. Please contact them to complete activation and then return to enter the project details.'
}

const actions = [
  completeProjectEquipment,
  completeProjectService,
  notifyHomeowner
]
export default actions
