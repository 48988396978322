import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import LenderApplicationStatus from '../LenderApplicationStatus'
import LenderApplicationSubmitted from '../LenderApplicationSubmitted'
import lenders from '../../../constants/lenders'
import useRootStore from '../../../hooks/useRootStore'

function LenderApplicationComplete () {
  const { merchantSlug, id } = useParams()
  const rootStore = useRootStore()
  const [offers, setOffers] = useState()
  const [errors, setErrors] = useState()
  const statusState = useState()

  const application = rootStore.applicationStore.findById(id)

  const lender = lenders[application.suggestedLender]
  useEffect(() => {
    async function applicationUpdate () {
      const statusUpdate = setInterval(async () => {
        if (errors) clearInterval(statusUpdate)
        if (application.isDeclined() || application.isOnHold()) clearInterval(statusUpdate)
        if (application.isLoanCreated()) {
          clearInterval(statusUpdate)
        } else {
          if (lender.getApplicationOffers && !application.isLoanCreated() && !application.isReadyToOffer()) {
            const { lenderRelationShip, error } = await application.getApplicationUpdate()
            // status update
            statusState[1](lenderRelationShip?.metadata?.lenderStatus && lenderRelationShip?.metadata?.lenderStatus?.state)
            application.lenderRelationShip = lenderRelationShip
            application.updateFromJSON(application)
            if (error) {
              console.log(error, 'ERROR')
              setErrors(error)
              clearInterval(statusUpdate)
            }
          }
          if (application.isReadyToOffer() && !offers) {
            const { lenderOffers, lenderRelationShip } = await application.getApplicationOffers()
            application.lenderRelationShip = lenderRelationShip
            application.updateFromJSON(application)
            if (lenderOffers) setOffers(lenderOffers)
            clearInterval(statusUpdate)
          }
        }
      }, 1000)
    }
    applicationUpdate()
  }, [rootStore, id, application, errors, lender, offers])

  if (!application) return <div>Loading...</div>
  if (['prequalification-invite', 'prequalification', 'lender-application'].includes(application.stage)) {
    window.location.href = `/${merchantSlug}/apply/${id}`
  }

  return (
    <div className='flex-1 flex flex-col'>
      <div className="max-w-9xl mx-auto px-4 sm:px-6 lg:px-8 py-8 w-full flex-1 flex flex-col">
        <div className="max-w-4xl mx-auto mt-10 flex-1 w-full">
          {!application.getStatusDisplay() &&
            <LenderApplicationSubmitted />
          }
          {!!application.getStatusDisplay() && <LenderApplicationStatus offers={offers} setOffers={setOffers} />}
        </div>
      </div>
    </div>
  )
}

LenderApplicationComplete.propTypes = {}

export default observer(LenderApplicationComplete)
