import React from 'react'
import PropTypes from 'prop-types'

import SimpleEmptyState from '../../feedback/emptyStates/Simple'

function EmptyState ({ isFiltered, onClickClearFilters }) {
  return (
    <div className="flex-1 flex items-center flex-col justify-center h-full">
      <SimpleEmptyState
        label={isFiltered ? 'We can\'t find any matching applications for your search.' : 'No applications yet'}
        subLabel={isFiltered ? 'Double-check for any typos or try a different search term.' : 'Create an application to get started'}
      />
      {isFiltered &&
      <div className="text-sm text-blue-500 mt-6" onClick={() => onClickClearFilters({})}>
        Back to List
      </div>
      }
    </div>
  )
}

EmptyState.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  onClickClearFilters: PropTypes.func.isRequired
}

export default EmptyState
