const testBureau = {
  type: 'credit',
  name: 'Test Credit Bureau',
  logo: 'https://taxguru.in/wp-content/uploads/2021/02/Credit-Score.jpg',
  config: {}
}

const experian = {
  type: 'credit',
  name: 'Experian',
  logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVBOYGn4BMJwNAavqukysIYaXZhDVlUakjheQdku65IIiogmc-nx11tGfhCOpRRQeIMq0&usqp=CAU',
  config: {
    username: { type: 'text', label: 'Username' },
    password: { type: 'password', label: 'Password' },
    clientID: { type: 'text', label: 'Client ID' },
    clientSecret: { type: 'password', label: 'Client Secret' },
    subscriberCode: { type: 'text', label: 'Subscriber Code' }
  }
}

const ftl = {
  type: 'lender',
  name: 'FTL Finance',
  description: 'HVAC Financing Company based in St. Charles, MO with over 15 years of experience.',
  logo: '/img/lenders/ftl.png',
  logoFull: '/img/lenders/ftl-logo.png',
  propertySupportEmail: 'support@ftlfinance.com',
  propertySupportPhone: '(800) 555-2233',
  website: 'https://ftlfinance.com/',
  config: {
    apiKey: { type: 'text', label: 'API Key', placeholder: 'Enter your API Key found on the api docs' },
    submitApiKey: { type: 'text', label: 'Submit API Key', placeholder: 'Enter your submit API Key found on the api docs' }
  }
}

const greensky = {
  type: 'lender',
  name: 'GreenSky',
  description: 'HVAC Financing Company based in Atlanta, GA with over 15 years of experience.',
  logo: '/img/lenders/greensky.png',
  logoFull: '/img/lenders/greensky-logo.png',
  propertySupportEmail: 'support@greensky.com',
  propertySupportPhone: '8005552233',
  website: 'https://greensky.com/',
  config: {
    apiKey: { type: 'text', label: 'API Key', placeholder: 'Enter your API Key found on the api docs' },
    x_api_key: { type: 'text', label: 'Search API Key', placeholder: 'Enter your search API Key found on the api docs' },
    plans: { type: 'array', label: 'Standard Plan(s)', placeholder: 'Enter a standard plan ID' }
  }
}

const postmark = {
  type: 'email',
  name: 'Postmark',
  description: 'Postmark is a service that allows you to send emails through your own server.',
  logo: 'https://postmarkapp.com/images/logo-stamp-social.png',
  config: {
    apiKey: { type: 'text', label: 'API Token', placeholder: 'Enter your Server Token' },
    serverID: { type: 'number', label: 'Server ID', placeholder: 'Enter your Server ID' },
    serverName: { type: 'text', label: 'Server Name', placeholder: 'Enter your Server Name' }
  }
}

/**
 * This is the config file for all integrations. It can be used by the frontend
 * so it should not include any node.js code.
 */

export default {
  testBureau,
  experian,

  ftl,
  greensky,

  postmark
}
