import dayjs from 'dayjs'
import React from 'react'

import questionSections from './questions'
import actionSections from './actions'
import submitPrequalification from './submitPrequalification'
import getApplicantEndpoint from './getApplicantEndpoint'
import convertUpdate from './convertUpdate'
import fetchUpdate from './fetchUpdate'

// const requiredMapping = {
//   ownership: 'Proof of Home Ownership',
//   proposal: 'Proposal',
//   'co-owner': '',
//   income: '',
//   verification: ''
// }

const ftl = {
  prequalificationPreCheck: null,
  recalculateOffers: null,
  automaticStatusFetch: null,
  submitPrequalification,
  getApplicantEndpoint,
  fetchUpdate,
  getIDFromUpdate: (update) => update.id,
  convertUpdate,
  questionSections,
  actionSections,
  prequalificationResult: (application, lender) => {
    return (
      <div className='p-4'>
        <p className='text-base text-black-500 text-center mb-8'>
          {application.primaryApplicant.fullName}, you are prequalified for financing with <b>{lender.name}</b>!
        </p>
        <div className='flex w-full items-center justify-between text-sm mb-1'>
          <div className='font-medium'>Amount limit:</div>
          <div>$35,000.00</div>
        </div>
        <div className='flex w-full items-center justify-between text-sm mb-1'>
          <div className='font-medium'>Terms:</div>
          <div>up to 120 months</div>
        </div>
        <div className='flex w-full items-center justify-between text-sm'>
          <div className='font-medium'>APR:</div>
          <div>15.99%-22.99%</div>
        </div>
      </div>
    )
  },
  getChecklistItems: (application) => {
    const items = [
      { label: 'Homeowner selected a loan option', isComplete: (application) => !!application.lenderRelationship?.metadata?.documentsCreatedOn },
      { label: 'Homeowner 1 signed the contract', isComplete: (application) => !!application.lenderRelationship?.metadata?.primaryApplicantSigned },
      { label: 'Project details entered and completion certificate sent', isComplete: (application) => application.lenderRelationship?.metadata?.completionCertificateSent },
      { label: 'Homeowner confirmed project completion', isComplete: (application) => !!application.lenderRelationship?.metadata?.completedCertificateBy },
      { label: 'Funds authorized for deposit', isComplete: (application) => application.stage === 'lender-funded' }
    ]
    if (!application.lenderRelationship?.metadata?.approvedWith) items.unshift({ label: 'Approved by FTL Finance', isComplete: (application) => !!application.lenderRelationship?.metadata?.approvedOn })
    // push at the front of items
    for (const { needed, decision } of application.lenderRelationship?.metadata?.approvedWith || []) {
      items.unshift({ label: 'Proof accepted', isComplete: (application) => decision === 'approved' })
      items.unshift({ label: `Approved with ${needed}`, isComplete: (application) => true })
    }
    if (application.stage === 'lender-declined') {
      return [{ label: 'Declined by FTL Finance', isComplete: () => false, danger: true }]
    }
    return items
  },
  getPreviewData: (application) => {
    const previewData = {}
    const lenderMetadata = application.lenderRelationship?.metadata || {}

    if (lenderMetadata.expiresOn) previewData.Expires = dayjs(lenderMetadata.expiresOn).format('MMM Do')
    if (lenderMetadata.installationDate) previewData['Installation Date'] = lenderMetadata.installationDate
    if (lenderMetadata.APR) previewData['Interest Rate'] = lenderMetadata.APR
    if (lenderMetadata.term) previewData.Term = lenderMetadata.term
    if (lenderMetadata.monthlyPayment) previewData['Monthly Payment'] = lenderMetadata.monthlyPayment

    if (lenderMetadata.equipmentEntered && lenderMetadata.equipment?.length) {
      previewData.Equipment = lenderMetadata.equipment.map(equipment => ({
        Brand: equipment.brand,
        Model: equipment.model,
        'Serial #': equipment.serialNumber
      }))
    }
    return previewData
  },
  reloadTabs: () => { return false },
  getPreSubmitQuestions: (application) => {
    if (!application.secondaryApplicant) {
      return {
        label: 'Are you sure you want to submit without a co-applicant?',
        description: 'If there are two homeowners for this property, it is best both are added to your application.',
        buttons: [
          {
            disabled: (submitting) => submitting,
            label: 'Add a co-applicant',
            onClick: (submit, closeModal, setStep) => {
              application.setAnswer('secondaryApplicant.firstName', '')
              setStep(2)
              closeModal()
            },
            secondary: true
          },
          {
            disabled: (submitting) => submitting,
            cy: 'secondary-submit',
            label: (submitting) => submitting ? 'Submitting...' : 'Yes, continue without a co-applicant',
            onClick: (submit, closeModal) => {
              return submit()
            }
          }
        ]
      }
    }
  }
}
export default ftl
