import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Menu, Transition } from '@headlessui/react'

function Dropdown ({ cy, button, content }) {
  return (
    <Menu as='div' className='relative' data-cy={cy}>
      <Menu.Button data-cy={`${cy}-button`} className=''>
        {button}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        {content}
      </Transition>
    </Menu>
  )
}

Dropdown.propTypes = {
  cy: PropTypes.string,
  button: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
}

export default Dropdown
