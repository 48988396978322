const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/merchantvalidation' : 'https://api1.uat.greensky.com/merchantvalidation'
}

export default async function prequalificationPreCheck (application, config, data) {
  const response = await fetch(`${apiURL(process.env.NODE_ENV)}?program=GreenSky+Consumer+Projects&dealer=${application?.merchant?.lenderIDs?.greensky || '80000134'}&channel=External-API-OptimusPlus&role=Merchant&sc_email=optimus2@microf.com&sc_phone=415-555-1212&sc_last_name=User+TF`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey
    }
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
  })

  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }

  const json = await response.json()
  const { isValid, token, minimumLoanAmount, maximumLoanAmount } = json

  return {
    success: !!isValid,
    token,
    min: minimumLoanAmount,
    max: maximumLoanAmount
  }
}
