import React from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'

import Application from '../../../stores/Application'
import Question from './Question'

function shouldShowQuestion (question, application) {
  if (question.showIfDependencyEquals) {
    let val
    if (question.keyDependency.includes('.')) {
      const [parentKey, childKey] = question.keyDependency.split('.')
      val = application[parentKey] && application[parentKey][childKey]
    } else {
      val = application[question.keyDependency]
    }
    if (val !== question.showIfDependencyEquals) {
      return false
    }
  }
  return true
}

function Step ({ subsections, application }) {
  return (
    <div className='space-y-8'>
      {subsections.map((subsection, i) => (
        <div key={i} className=''>
          {subsection.label &&
          <div className='mb-4'>{subsection.label}</div>
          }
          <div className='space-y-6'>
            {subsection.questions.map((questionGroup, j) => (
              <div key={j} className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                {questionGroup.filter(question => shouldShowQuestion(question, application)).map((question) => <Question key={question.key} question={question} application={application} questionGroup={questionGroup} />)}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

Step.propTypes = {
  subsections: PropTypes.array.isRequired,
  application: PropTypes.instanceOf(Application).isRequired
}

export default observer(Step)
