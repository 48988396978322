import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'

import lenders from '../../../constants/lenders'
import PanelsSteps from '../../navigation/steps/Panels'
import PrimaryButton from '../../elements/buttons/Primary'
import Step from './Step'
import useRootStore from '../../../hooks/useRootStore'
import ModalWrapper from '../../modals/ModalWrapper'

const goToTop = () => {
  window.scrollTo(0, 0)
}

function LenderApplication () {
  const { id, merchantSlug } = useParams()
  const rootStore = useRootStore()

  const [step, setStep] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [stepDisplay, setStepDisplay] = useState()
  const [submitQuestions, setSubmitQuestions] = useState()
  const [reloaded, setReloaded] = useState(false)
  // const [error, setError] = useState()

  const application = rootStore.applicationStore.findById(id)

  const lender = application ? lenders[application.suggestedLender] : null

  useEffect(() => {
    if (application && !stepDisplay) {
      setStepDisplay(lender.questionSections.map(section => !section.startRemoved(application)))
    }
  }, [application, stepDisplay, lender])

  useEffect(() => {
    if (lender.onApplicationLoad) {
      lender.onApplicationLoad(window, document, rootStore.environment)
    }
  }, [lender, rootStore])

  if (!application || !stepDisplay) return <div>Loading...</div>
  if (application.lenderRelationship?.id && application.stage !== 'lender-application') {
    window.location.href = `/${merchantSlug}/apply/${application.id}/complete`
  }

  if (lender.reloadTabs(application) && !reloaded) {
    setStepDisplay(lender.questionSections.map(section => !section.startRemoved(application)))
    setReloaded(true)
  }
  const isStepComplete = application.isLenderStepComplete(step)
  const isStepValid = application.isLenderStepValidated(step)
  const hiddenInputs = lender.hiddenInputs || []
  console.log(isStepComplete, isStepValid)

  return (
    <form
      className='flex-1 flex flex-col'
      onSubmit={async (e) => {
        e.preventDefault()
        const nextIndex = lender.questionSections.findIndex((section, j) => j > step && stepDisplay[j])
        if (nextIndex > -1) {
          goToTop()
          setStep(nextIndex)
        } else if (!submitting) {
          try {
            const submitQuestion = lender.getPreSubmitQuestions(application)
            if (submitQuestion) {
              goToTop()
              return setSubmitQuestions(submitQuestion)
            }
            setSubmitting(true)
            const hiddenValues = {}
            for (const input of hiddenInputs) {
              hiddenValues[input.id] = document.querySelector(`#${input.id}`).value
            }
            await application.submit(application.suggestedLender, merchantSlug, hiddenValues)
          } catch (error) {
            rootStore.openDialog('lenderError', error)
            // setError(error)
            setSubmitting(false)
          }
        }
      }}>
      {hiddenInputs.map((input, i) => (
        <input key={input.id} type='hidden' name={input.name} id={input.id} />
      ))}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 w-full flex-1 flex flex-col">
        <PanelsSteps
          stepDisplay={stepDisplay}
          setStepDisplay={setStepDisplay}
          application={application}
          steps={lender.questionSections.map((section, i) => ({
            ...section,
            status: step > i ? 'complete' : step === i ? 'current' : 'incomplete'
          }))}
        />
        {lender.questionSections[step].isRemovable(application) &&
          <div className="mb-4 mt-4 flex justify-end">
            <PrimaryButton
              danger
              cy='remove-section'
              label='Remove'
              onClick={() => {
                setStepDisplay(stepDisplay.map((display, i) => i === step ? false : display))
                if (step.onClickRemoveButton) {
                  step.onClickRemoveButton(application)
                }
                let prevStep = step - 1
                while (prevStep >= 0 && !stepDisplay[prevStep]) prevStep--
                setStep(prevStep)
              }}
            />
          </div>
        }
        <div className="max-w-3xl mx-auto mt-10 flex-1 w-full">
          <Step
            application={application}
            subsections={lender.questionSections[step].subsections}
          />
        </div>
      </div>
      <div className="mt-8 py-6 shrink-0 border-t border-gray-200 bg-white">
        <div className="flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <PrimaryButton
            label="Back"
            cy='back'
            disabled={step === 0}
            onClick={async () => {
              if (step <= (lender.questionSections.length - 1)) {
                goToTop()
                setStep(step - 1)
              }
            }}
          />
          <PrimaryButton
            cy='next'
            type='submit'
            label={!lender.questionSections.find((section, j) => j > step && stepDisplay[j]) ? submitting ? 'Submitting...' : 'Submit' : 'Next'}
            disabled={!isStepComplete || !isStepValid || submitting} // Validate Fields
          />
        </div>
      </div>

      <ModalWrapper isOpen={!!submitQuestions} onClose={() => setSubmitQuestions(null)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {submitQuestions?.label}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {submitQuestions?.description}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 block sm:flex justify-end sm:space-x-4">
          {submitQuestions && submitQuestions.buttons.map((button, i) => (
            <PrimaryButton
              key={i}
              label={typeof button.label === 'function' ? button.label(submitting) : button.label}
              disabled={typeof button.disabled === 'function' ? button.disabled(submitting) : button.disabled}
              secondary={button.secondary}
              className="w-full sm:w-auto mb-2 sm:mb-0"
              cy={button.cy}
              onClick={() => {
                button.onClick(
                  async () => {
                    setSubmitting(true)
                    const hiddenValues = {}
                    for (const input of hiddenInputs) {
                      hiddenValues[input.id] = document.querySelector(`#${input.id}`).value
                    }
                    await application.submit(application.suggestedLender, merchantSlug, hiddenValues)
                  },
                  () => setSubmitQuestions(null),
                  setStep
                )
              }}
            />
          ))}
        </div>
      </ModalWrapper>
    </form>
  )
}

LenderApplication.propTypes = {}

export default observer(LenderApplication)
