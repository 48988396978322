import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { MagnifyingGlassIcon, HomeIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { Toaster } from 'react-hot-toast'

import useRootStore from '../../../hooks/useRootStore'
import InviteUserToMerchantModal from '../../modals/InviteUserToMerchantModal'
import LenderActionModal from '../../modals/LenderActionModal'
import MainWrapper from '../MainWrapper'

function MerchantWrapper () {
  const rootStore = useRootStore()
  const location = useLocation()

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: location.pathname === '/' },
    { name: 'Applications', href: '/applications', icon: MagnifyingGlassIcon, current: location.pathname === '/applications' },
    { name: 'Team', href: '/team', icon: UserGroupIcon, current: location.pathname === '/team' }
  ]

  useEffect(() => rootStore.initSocketIO(), [rootStore])

  return (
    <>
      <MainWrapper hideSidebarFooter navigation={navigation} />

      {/* Modals */}
      <LenderActionModal
        isOpen={rootStore.currentDialog.includes('actionMerchant')}
        applicationID={rootStore.currentDialog.split('.')[1]}
        actionID={rootStore.currentDialog.split('.')[2]}
        onClose={rootStore.closeDialog}
      />

      <InviteUserToMerchantModal
        isOpen={rootStore.currentDialog.includes('inviteUserToMerchant')}
        merchantID={rootStore.currentDialog.split('.')[1]}
        onClose={rootStore.closeDialog}
      />

      {/* Toaster Slot */}
      <Toaster position='top-right' />
    </>
  )
}

export default observer(MerchantWrapper)
