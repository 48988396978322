import applicationDetails from './applicationDetails'
import primaryApplicant from './primaryApplicant'
import secondaryApplicant from './secondaryApplicant'
import disclosure from './disclosure'

export default [
  applicationDetails,
  primaryApplicant,
  // secondaryApplicant,
  disclosure
]
