import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'
import { useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table'
import { Helmet } from 'react-helmet'

import useRootStore from '../../../hooks/useRootStore'
import Header from '../../headings/pageHeadings/Standard'
import Table from '../../elements/lists/Table'
import FilterBar from './FilterBar'
import LoadingState from './LoadingState'
import EmptyState from './EmptyState'
import getColumns from './getColumns'
import toast from 'react-hot-toast'

function ApplicationList () {
  const [isLoading, setIsLoading] = React.useState(true)
  const [loadingMore, setLoadingMore] = React.useState(false)
  const [sectionsLoaded, setSectionsLoaded] = React.useState(0)

  const [applications, setApplications] = React.useState([])
  const [metadata, setMetadata] = React.useState({})

  const [searchParams, setSearchParams] = useSearchParams()

  const rootStore = useRootStore()

  const fetchApplications = useCallback(async function () {
    let limit = (sectionsLoaded + 1) * 20
    if (metadata?.total && limit > metadata.total) {
      limit = metadata.total
    }
    const params = { limit }
    if (searchParams.get('search')) params.search = searchParams.get('search')
    if (searchParams.get('sort')) params.sort = searchParams.get('sort')
    if (searchParams.get('stage')) params.stage = searchParams.get('stage')
    // if (sorting[0]) params.search = sorting[0].id
    try {
      const { applications, metadata } = await rootStore.fetch(`/data/pages/applications?${new URLSearchParams(params).toString()}`)
      setApplications(applications.map(rootStore.applicationStore.addApplicationFromJSON))
      setIsLoading(false)
      setMetadata(metadata)
      setSectionsLoaded(sectionsLoaded + 1)
    } catch (error) {
      const err = await error
      if (err.error) {
        toast.error(err.error)
      }
    }
  }, [rootStore, searchParams, sectionsLoaded, metadata])

  useEffect(() => {
    if (isLoading) {
      fetchApplications()
    }
  }, [fetchApplications, isLoading])

  useEffect(() => {
    setSectionsLoaded(0)
    setIsLoading(true)
  }, [searchParams])

  const isFiltered = !!searchParams.get('search')
  const isEmpty = !applications.length && !isLoading

  const table = useReactTable({
    data: applications,
    columns: getColumns(rootStore.currentProperty, !!rootStore.currentMerchantID),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: () => {},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <>
      <Helmet>
        <title>Applications</title>
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <Header
          floatAtTop
          pages={[{ label: 'Applications', current: true, to: '/applications' }]}
          right={<FilterBar />}
        />

        {isEmpty &&
        <EmptyState isFiltered={isFiltered} onClickClearFilters={() => setSearchParams({})} />
      }
        {isLoading &&
        <div className='max-w-6xl mx-auto w-full xl:py-10 2xl:py-14  xl:px-8'>
          <LoadingState />
        </div>
      }

        {!!applications.length && !isLoading &&
        <div className='max-w-6xl mx-auto w-full xl:py-10 2xl:py-14 xl:px-8 flex flex-col items-center'>
          <Table
            className='ring-black ring-1 ring-opacity-5 xl:rounded-lg w-full'
            tableInstance={table}
          />
          <div
            className='mt-4 text-sm cursor-pointer flex flex-col items-center'
            onClick={async () => {
              setLoadingMore(true)
              await fetchApplications()
              setLoadingMore(false)
            }}>
            <div className='text-xs mb-1 text-gray-800'>Showing {metadata.limit} of {metadata.total}</div>
            <div className='text-blue-500 hover:text-blue-700 font-medium'>{loadingMore ? 'Loading...' : 'Load More'}</div>
          </div>
        </div>
      }
      </div>
    </>
  )
}

export default observer(ApplicationList)
