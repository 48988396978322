import React from 'react'

const disclosureMessage = (
  <div id="gs-installment" className='text-sm'>
    <p className='font-bold mb-6' tabIndex='0'>
      <span>Please read the following <i>important</i> agreements and disclosures. This is a credit application, and submitting it means that you have read and agree to all the steps described below. These agreements and disclosures can be found online anytime at</span>
      <a href="https://www.greensky.com/disclosures/" target="_blank" rel="noreferrer" className='ml-1 underline text-blue-700 font-normal'>
        www.greensky.com/disclosures
      </a>
      <span>. Please retain a copy of them for your records.</span>
    </p>

    <div tabIndex='1' className='mb-10 rounded'>
      <p className="text-md mb-2 text-center font-bold text-gray-700">Definitions: For the purposes of these disclosures, the following definitions apply:</p>

      <p className='mb-1 text-gray-800'><b>&ldquo;Lender,&rdquo; &ldquo;us,&rdquo; &ldquo;we&rdquo; or &ldquo;our&rdquo;</b> shall include any financial institution, and their agents, service providers and representatives, participating in the GreenSky<sup>&reg;</sup> Loan Program (the &ldquo;GreenSky<sup>&reg;</sup> Program&rdquo;).</p>

      <p className='text-gray-800'><b>&ldquo;You&rdquo;</b> or <b>&ldquo;your&rdquo;</b> shall include any applicant and any co-applicant collectively, submitting an application for a loan with the GreenSky<sup>&reg;</sup> Program.</p>
    </div>

    <div tabIndex='2' className='mb-4 py-8 px-8 bg-white border rounded-lg'>
      <h3 className='text-center font-bold text-md mb-4'>APPLICATION AGREEMENTS AND DISCLOSURES FOR GREENSKY<sup>&reg;</sup> CONSUMER LOAN PROGRAM</h3>

      <p className='text-lg text-center mb-6'>We are providing the following Disclosures to you in connection with your application for a loan from one of the financial institutions offering the GreenSky<sup>&reg;</sup> Program.</p>
      <h4 className='text-lg font-bold mb-4'>Important Disclosures:</h4>
      <p className='mb-2'>By submitting this application for credit, <b>you agree that you have read and understand the following:</b></p>
      <ol className='list-decimal pl-5 space-y-2 mb-10'>
        <li>THE FOLLOWING IS IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a new account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.</li>
        <li>In providing your income as part of this application, or in providing any subsequent update to your income, alimony, child support, or separate maintenance payments need not be revealed if you do not wish to have them considered in evaluating your creditworthiness.</li>
        <li>If you request, you will be informed whether a credit report was requested and, if so, the name and address of the consumer reporting agency that furnished the report.</li>
        <li>If approved for a loan, you will be required to activate your loan account, and we will ask you to accept your Installment Loan Agreement (&ldquo;Agreement&rdquo;) by signing it electronically. The dated electronic record of your acceptance of the Agreement will evidence your signature of the Agreement and have the same legal effect as a physical signature.</li>
        <li><b>Notice to All Applicants, Including New York, Rhode Island and Vermont Residents:</b> A consumer credit report may be requested in connection with this application or in connection with updates, renewals or extensions of credit granted as a result of this application. Upon your request, you will be informed whether or not such a report was requested and, if so, the name and address of the agency that furnished the report.</li>
        <li>
          <div className='mb-2'>The following <b>state-specific notices</b> apply, as indicated, to California, Ohio, and Wisconsin applicants:</div>
          <div className='mb-1'><b>California Residents:</b> If you are married, you may apply for a separate account.</div>
          <div className='mb-1'><b>Ohio Residents:</b> The Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio civil rights commission administers compliance with this law.</div>
          <div className='mb-1'><b>Vermont Residents:</b> We are engaged in loan production.</div>
          <div className='mb-1'><b>Married Wisconsin Residents:</b> If you are applying for individual credit or joint credit with someone other than your spouse, and your spouse also lives in Wisconsin, combine your financial information with your spouse&rsquo;s financial information. No provision of any marital property agreement, unilateral statement under Section 766.59 of the Wisconsin statutes or court order under section 766.70 adversely affects the interest of the lender, unless the lender, prior to the time credit is granted, is furnished a copy of the agreement, statement or decree or has actual knowledge of the adverse provision when the obligation to the lender is incurred. If married, you understand that your lender must inform your spouse if a credit account is opened for you.</div>
        </li>
      </ol>

      <p className='text-lg font-bold mb-4'>Important Applicant Agreements:</p>

      <div className='mb-4'>
        <p className='mb-2'>By submitting this application for credit, <b>you agree to the following:</b></p>
        <ol className='list-decimal pl-5 space-y-2'>
          <li>You are applying for any product that we may offer as part of the GreenSky<sup>&reg;</sup> Program regardless of any product your merchant may have specifically discussed with you. </li>
          <li>All of the statements (whether verbally or written) communicated to us and to your merchant related to this application are true and correct and are made for the purposes of obtaining credit.</li>
          <li>If more than one applicant is applying, each applicant intends to apply for joint credit and none is a co-signer (a &ldquo;co-signer&rdquo; is a person who agrees to be liable for the debt of another person without receiving the benefit of the loan; a &ldquo;joint applicant&rdquo; is a person who intends to apply for joint credit and will receive the benefit of the loan). Although we allow joint applicants to apply for joint credit, we do not allow borrowers to use &ldquo;co-signers&rdquo;. </li>
          <li>You will provide additional financial information upon request. </li>
        </ol>
      </div>
      <div>
        <p className='mb-2'>By submitting this loan application, <b>you expressly authorize us to:</b></p>

        <ol className='list-decimal pl-5 space-y-2'>
          <li>Obtain a credit report on you for this application and for any other legal purpose, including any account update, extension of credit, or review or collection of your loan. </li>
          <li>Share with your merchant our credit decision, including your credit score, and if approved, the loan information, including, but not limited to, your approved credit limit, the repayment period for your loan, your approved APR, and your account number. </li>
          <li>Share with any co-applicant loan related information, including but not limited to, your credit decision, proposed loan terms, and personal credit scores, which will have the same effect as personal delivery to you. </li>
          <li>Contact you about our products, services, special offers and other promotions at any email address provided to us now or in the future. Contact you at any mobile number and any future mobile number that you provide by phone or text message using an automated telephone dialing system or artificial or prerecorded voices, for any legal purpose, including but not limited to, servicing and collecting any loan. You may revoke your consent at any time by contacting us at 1-866-936-0602 or P.O. Box 2730, Alpharetta, GA 30023. </li>
          <li>Share with your merchant your contact information including address, email address, and telephone number.</li>
          <li>
            <span>Contact your wireless operator (AT&amp;T, Sprint, T-Mobile, US Cellular, Verizon, or any other branded wireless operator) to disclose your mobile number, name, address, email, network status, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber status and device details, if available, to our third party service provider, solely to verify your identity and prevent fraud for the duration of the business relationship. See the</span>
            <a href="https://www.greensky.com/privacy-policy/" target="_blank" id="gs_privacy_policy_disclosure_link" rel="noreferrer" className='ml-0.5'>
              GreenSky<sup>&reg;</sup> Program Privacy Policy
            </a>
            <span>for how we treat data collected online through the GreenSky<sup>&reg;</sup> Program websites, online through the GreenSky<sup>&reg;</sup> Program mobile applications that link to this Privacy Policy, and offline via mail, telephone, and in person. If you are approved for a loan through the GreenSky<sup>&reg;</sup> Program, your information may also be subject to a privacy policy specific to your lender under the GreenSky<sup>&reg;</sup>Program.</span>
          </li>
        </ol>
      </div>
    </div>

    <div tabIndex='3' className='mb-4 py-8 px-8 bg-white border rounded-lg'>
      <h3 className='text-center font-bold text-md mb-4'>MARKETING DISCLOSURES AND CONSENT FOR COLLECTION/RECEIPT OF INFORMATION FOR MARKETING PURPOSES</h3>
      <p>
        <span>In addition to providing personal information in connection with a GreenSky<sup>&reg;</sup> Program Loan application, you agree that you are providing a copy of any information, including personally-identifiable information (&ldquo;PII&rdquo;), to GreenSky Marketing, LLC (&ldquo;GS Marketing&rdquo;) for its collection, use, and disclosure consistent with the GS Marketing Privacy Policy</span>
        <a href="https://www.gskymarketing.com/privacy-policy" target="_blank" rel="noreferrer" className='ml-0.5'>
          gskymarketing.com/privacy-policy
        </a>
        <span>, including, but not limited to, using and sharing such information with unaffiliated third-parties for their marketing purposes. You also authorize your GreenSky<sup>&reg;</sup> Program lender to provide GS Marketing with all information about you and your GreenSky<sup>&reg;</sup> Program loan, including, but not limited to, details about your loan and loan performance so that GS Marketing may use and/or disclose such information for GS Marketing (and/or its affiliates and third-parties) to provide you with various marketing offers. You understand and agree that your written instruction and authorizations are voluntary and shall remain in effect until you revoke them by emailing an opt-out request to marketing</span>
        <a href="mailto:privacy@gskymarketing.com" className='ml-0.5'>
          privacy@gskymarketing.com
        </a>
        <span>.</span>
      </p>
      <p tabIndex='0' id="program-info">GreenSky <sup className="reg_small">®</sup> Program NMLS #1416362 </p>
    </div>
  </div>
)

const disclosureAcceptApp = (
  <div>
    You, the GreenSky<sup>&reg;</sup> Program loan Applicant, have read and accepted the important Account Opening Disclosures provided to you above. You also agree to the <a href="https://www.greensky.com/privacy-policy/" target="_blank" id="privacy_policy_disclosure_link" rel="noreferrer"><u>GreenSky</u><sup>&reg;</sup> <u>Program Privacy Policy</u></a> and <a href="https://www.greensky.com/information" target="_blank" rel="noreferrer"><u>Consent to Electronic Records and Communications</u></a> and agree to receive electronic documents in connection with any loan obtained as a result of this application.  You also agree to participate in the GS Marketing program, subject to the <a href="https://www.gskymarketing.com/privacy-policy" target="_blank" id="marketing_privacy_policy_disclosure_link" rel="noreferrer"><u>GS Marketing Privacy Policy</u></a>, independently of your participation in the GreenSky<sup>&reg;</sup> Program and provide your specific consents as described in the scroll box above.
  </div >
)
const disclosureAcceptCoApp = (
  <div>
    You, the GreenSky<sup>&reg;</sup> Program loan Co-Applicant, have read and accepted the important Account Opening Disclosures provided to you above.  You also agree to the <a href="https://www.greensky.com/privacy-policy/" target="_blank" id="privacy_policy_disclosure_link" rel="noreferrer"><u>GreenSky</u><sup>&reg;</sup> <u>Program Privacy Policy</u></a> and <a href="https://www.greensky.com/information" target="_blank" rel="noreferrer"><u>Consent to Electronic Records and Communications</u></a> and agree to receive electronic documents in connection with any loan obtained as a result of this application.  You also agree to participate in the GS Marketing program, subject to the <a href="https://www.gskymarketing.com/privacy-policy" target="_blank" id="marketing_privacy_policy_disclosure_link" rel="noreferrer"><u>GS Marketing Privacy Policy</u></a>, independently of your participation in the GreenSky<sup>&reg;</sup> Program and provide your specific consents as described in the scroll box above.
  </div>
)
const disclosureSubmitApp = (<div>You, the Applicant, understand that by selecting this box, you authorize Lender and its representatives, successors, and assigns to investigate your creditworthiness and obtain a credit report for you for any lawful purpose, including, but not limited to, any extension of credit, renewal, servicing and collections. Upon request, Lender will inform applicant of whether a credit report was obtained and if a report was obtained, the names and addresses of the credit reporting agencies that furnished the report. By clicking &ldquo;Submit&rdquo;, I certify that the Application Information is true and accurate.</div>)
const disclosureSubmitCoApp = (<div>I, the Applicant/Co-Applicant, authorize the Lender and its representatives, successors, and assignees to investigate my creditworthiness and obtain a credit report on me for any lawful purpose, including, but not limited to, any extension of credit, renewal, servicing and collections.  Upon request, the Lender will inform the applicant of whether a credit report was obtained and if a report was obtained, and the names and addresses of the credit reporting agencies that furnished the report. By submitting the application, I certify that all information is true and accurate.</div>)

const disclosure = {
  name: 'Disclosure',
  key: 'app-disclosure',
  number: '04',
  startRemoved: () => false,
  required: () => true,
  isRemovable: () => false,
  subsections: [
    {
      questions: [
        [
          {
            key: 'disclosure',
            type: 'message',
            required: false,
            message: disclosureMessage
          },
          {
            key: 'lenderMetadata.disclosure-acceptapp',
            type: 'multi-checkbox',
            required: true,
            label: (application) => application.secondaryApplicant?.firstName || true ? ['Applicant', 'Co-Applicant'] : ['Applicant'],
            description: (application) => application.secondaryApplicant?.firstName || true ? disclosureAcceptCoApp : disclosureAcceptApp,
            checkboxes: (application) => application.secondaryApplicant?.firstName || true ? 2 : 1
          },
          {
            key: 'lenderMetadata.disclosure-submitapp',
            type: 'multi-checkbox',
            required: true,
            label: (application) => application.secondaryApplicant?.firstName || true ? ['Applicant', 'Co-Applicant'] : ['Applicant'],
            description: (application) => application.secondaryApplicant?.firstName || true ? disclosureSubmitCoApp : disclosureSubmitApp,
            checkboxes: (application) => application.secondaryApplicant?.firstName || true ? 2 : 1
          }
        ]
      ]
    }
  ]
}

export default disclosure
