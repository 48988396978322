import React from 'react'
import { makeObservable, observable, action, computed } from 'mobx'

import integrations from '../constants/integrations'
import Address from './Address'
import Applicant from './Applicant'
import Note from './Note'
import validate from '../utils/validate'
import lenders from '../constants/lenders'

class Application {
  shortID
  lenderID
  stage = undefined

  merchantID

  installationAddress = new Address()
  invoiceID = ''
  metadata = {}
  primaryApplicant = undefined
  progressStatus = undefined
  propertyOwner = undefined
  requestAmount = undefined
  approvedAmount = undefined
  secondaryApplicant = undefined
  lenderMetadata = undefined // used to hold temp info, before a relationship is created
  lenderRelationship = undefined
  lenderActivity = undefined
  lenderStatus = undefined
  createdAt = undefined
  selectedOffer = undefined
  amountNeeded = undefined
  nextSuggestedLender = undefined
  actionData = {}
  serviceType = undefined
  authorizationToken = undefined
  contractLanguage = undefined
  requiredForApproval = []
  lenderLoanCreated = undefined
  notes = []
  constructor (store, json) {
    this.store = store
    this.id = json._id.toString()
    this.lenderNumber = json.lenderNumber
    this.shortID = json.shortID
    this.authorizationToken = json.authorizationToken
    makeObservable(this, {
      acceptLender: action,
      actionData: observable,
      addSecondaryApplicant: action,
      amountNeeded: observable,
      approvedAmount: observable,
      authorizationToken: observable,
      contractLanguage: observable,
      getStatusDisplay: action,
      getStatusDisplayDetails: action,
      invoiceID: observable,
      isOnHold: action,
      lenderActivity: observable,
      lenderID: observable,
      lenderLoanCreated: observable,
      lenderMetadata: observable,
      lenderRelationship: observable,
      lenderStatus: observable,
      merchant: computed,
      metadata: observable,
      nextSuggestedLender: observable,
      pendingActions: computed,
      primaryApplicant: observable,
      propertyOwner: observable,
      removeSecondaryApplicant: action,
      requestAmount: observable,
      requiredForApproval: observable,
      secondaryApplicant: observable,
      selectedOffer: observable,
      serviceType: observable,
      setActionAnswer: action,
      setAnswer: action,
      setNextSuggestedLender: action,
      setSameAddress: action,
      stage: observable,
      updateFromJSON: action
    })
    this.updateFromJSON(json)
  }

  updateFromJSON (json) {
    // this.lenderID = json.lenderID
    this.installationAddress.updateFromJSON(json.installationAddress)
    this.invoiceID = json.invoiceID || ''
    if (json.merchant && typeof json.merchant === 'object') {
      this.store.root.merchantStore.addMerchantFromJSON(json.merchant)
      this.merchantID = (json?.merchant?._id || json?.merchant?.id)?.toString()
    } else {
      this.merchantID = json.merchant
    }
    this.requiredForApproval = json?.lenderRelationship?.requiredForApproval || []
    this.primaryApplicant = new Applicant(this, json.primaryApplicant)

    this.lenderRelationship = json.lenderRelationship
    this.lenderActivity = json.lenderActivity
    this.amountNeeded = json.amountNeeded
    this.selectedOffer = json.selectedOffer
    this.propertyOwner = json.propertyOwner
    this.requestAmount = json.requestAmount
    this.stage = json.stage
    this.nextSuggestedLender = json.nextSuggestedLender
    this.suggestedLender = json.suggestedLender
    this.service = json.service
    this.createdAt = json.createdAt
    if (json.secondaryApplicant) {
      this.secondaryApplicant = new Applicant(this, json.secondaryApplicant)
    }
    return this
  }

  addNotesFromJSON = (notes) => {
    for (const note of notes) {
      if (!this.notes.find(n => n.id === note._id.toString())) {
        this.notes.push(new Note(this, note))
      }
    }
  }

  addSecondaryApplicant () {
    this.secondaryApplicant = new Applicant(this, {})
  }

  removeSecondaryApplicant () {
    this.secondaryApplicant = undefined
  }

  isLenderStepComplete = (step) => {
    return lenders[this.suggestedLender].questionSections[step].subsections.every(subsection => subsection.questions.every(questions => questions.every(question => {
      if (!question.required) return true
      if (question.type === 'multi-checkbox') {
        const [parentKey, childKey] = question.key.split('.')
        if (this[parentKey] && this[parentKey][childKey]) {
          const allFieldsExist = Object.values(this[parentKey][childKey]).every(Boolean)
          const numberOfFields = Object.keys(this[parentKey][childKey]).length
          if (!(allFieldsExist && numberOfFields === question.checkboxes(this))) {
            console.log('vvvvv', question)
          }
          return allFieldsExist && numberOfFields === question.checkboxes(this)
        }
      }
      if (question.showIfDependencyEquals) {
        let dependency
        if (question.keyDependency.includes('.')) {
          const [parentKey, childKey] = question.keyDependency.split('.')
          dependency = this[parentKey] && this[parentKey][childKey]
        } else {
          dependency = this[question.keyDependency]
        }
        if (dependency !== question.showIfDependencyEquals) {
          return true
        }
      }
      if (question.key.includes('.')) {
        const [parentKey, childKey] = question.key.split('.')
        if (!this[parentKey]) return false
        const answer = this[parentKey][childKey]
        return answer !== undefined && answer !== ''
      }
      if (question.type === 'address') {
        return this.installationAddress.streetAddress && this.installationAddress.city && this.installationAddress.state && this.installationAddress.zipCode
      }
      const answer = this[question.key]
      return answer !== undefined && answer !== ''
    })))
  }

  isLenderStepValidated = (step) => {
    return lenders[this.suggestedLender].questionSections[step].subsections.every(subsection => subsection.questions.every(questions => questions.every(question => {
      if (!question.required) return true
      const dependencyValue = this[question.keyDependency]
      let answer = ''
      if (question.key.includes('.')) {
        const [parentKey, childKey] = question.key.split('.')
        if (!this[parentKey]) return false
        answer = this[parentKey][childKey]
      } else {
        answer = this[question.key]
      }
      // add ssn after launch
      if (['email', 'phone', 'day'].includes(question.type)) {
        if (!(validate[question.type](answer))) {
          console.log('vv', question)
        }
        return validate[question.type](answer)
      }
      if (question.type === 'dollar') {
        if (!(!question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer }))) {
          console.log('vv', question)
        }
        return !question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer })
      }
      if (question.type === 'dropdown') {
        if (!(!question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer }))) {
          console.log('vv', question)
        }
        return !question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer })
      }
      if (question.type === 'address') {
        if (!(!question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer: this.installationAddress }))) {
          console.log('vv', question)
        }
        return validate.streetAddress(answer.streetAddress) && validate.city(answer.city) && validate.zipCode(answer.zipCode)
      }
      if (!(!question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer }))) {
        console.log('vv', question)
      }
      return !question.getErrorMessage || !question.getErrorMessage({ dependencyValue, answer })
    })))
  }

  setAnswer = (key, value, subField) => {
    if (key.includes('.')) {
      const [parentKey, childKey] = key.split('.')
      if (parentKey === 'primaryApplicant' && !this.primaryApplicant) this.primaryApplicant = new Applicant(this)
      if (parentKey === 'secondaryApplicant' && !this.secondaryApplicant) this.secondaryApplicant = new Applicant(this)
      if (parentKey === 'lenderMetadata' && !this.lenderMetadata) this.lenderMetadata = {}
      if (subField) {
        if (!this[parentKey][childKey]) this[parentKey][childKey] = {}
        this[parentKey][childKey][subField] = value
      } else {
        if (!this[parentKey]) this[parentKey] = {}
        this[parentKey][childKey] = value
        console.log(this[parentKey], 'this')
      }
    } else if (subField) {
      this[key][subField] = value
    } else {
      this[key] = value
    }
    return this
  }

  setSameAddress = (keyToPopulate, empty) => {
    const value = empty ? new Address() : this.installationAddress
    if (keyToPopulate.includes('.')) {
      const [parentKey, childKey] = keyToPopulate.split('.')
      this[parentKey][childKey] = value
    } else {
      this[keyToPopulate] = value
    }
    return this
  }

  setActionAnswer = (key, value, subField) => {
    if (key.includes('.')) {
      const [parentKey, childKey] = key.split('.')
      if (subField) {
        this.actionData[parentKey][childKey][subField] = value
      } else {
        this.actionData[parentKey][childKey] = value
      }
    } else if (subField) {
      this.actionData[key][subField] = value
    } else {
      this.actionData[key] = value
    }
    return this
  }

  setActionAnswerStructure = (obj) => {
    this.actionData = obj
    return this
  }

  prequalificationPreCheck = async () => {
    try {
      return await this.store.root.fetch(`/api/applications/${this.id}/lender-authorization`, 'POST', this.asJSON)
    } catch {
      return false
    }
  }

  getApplicationUpdate = async () => {
    try {
      return await this.store.root.fetch(`/api/applications/${this.id}/lender-application-update`, 'POST', this.asJSON)
    } catch {
      return false
    }
  }

  getApplicationOffers = async () => {
    try {
      return await this.store.root.fetch(`/api/applications/${this.id}/lender-application-offers`, 'POST', this.asJSON)
    } catch {
      return false
    }
  }

  getRecalculatedOffers = async () => {
    try {
      return await this.store.root.fetch(`/api/applications/${this.id}/lender-recalculate-offers`, 'POST', this.asJSON)
    } catch {
      return false
    }
  }

  setNextSuggestedLender = async () => {
    return await this.store.root.fetch(`/api/applications/${this.id}/next-suggested-lender`, 'POST', this.asJSON)
  }

  createContract = async (data) => {
    try {
      const { success, error, updatedApplication, lenderRelationShip } = await this.store.root.fetch(`/api/applications/${this.id}/lender-create-contract`, 'POST', data)
      return { success, error, updatedApplication, lenderRelationShip }
    } catch {
      return false
    }
  }

  submit = async (lenderSubdomain, slug, hiddenValues) => {
    try {
      console.log('subbmiting values', hiddenValues)
      const { completeURL } = await this.store.root.fetch(`/api/applications/${this.id}/submit-lender`, 'POST', { ...this.asJSON, hiddenValues })
      if (completeURL) {
        window.location.href = completeURL
      } else {
        window.location.href = `/${slug}/apply/${this.id}/complete`
      }
    } catch (error) {
      const errorResponse = await error
      throw errorResponse.error
    }
  }

  redirect = (lender, slug) => {
    if (lender.redirectLink) {
      window.location.href = lender.redirectLink({ lenderID: this.lenderRelationShip?.id, slug, id: this.id })
    }
  }

  async acceptLender () {
    await this.store.root.fetch(`/api/applications/${this.id}/accept-lender`, 'POST', this.asJSON)
    this.stage = 'lender-application'
  }

  get asJSON () {
    return {
      invoiceID: this.invoiceID,
      requestAmount: this.requestAmount,
      approvedAmount: this.approvedAmount,
      installationAddress: this.installationAddress.asJSON,
      lenderMetadata: this.lenderMetadata,
      lenderRelationShip: this.lenderRelationShip,
      lenderRelationship: this.lenderRelationship,
      lenderActivity: this.lenderActivity,
      lenderLoanCreated: this.lenderLoanCreated,
      lenderStatus: this.lenderStatus,
      metadata: this.metadata,
      amountNeeded: this.amountNeeded,
      selectedOffer: this.selectedOffer,
      lenderID: this.lenderID,
      service: this.service,
      authorizationToken: this.authorizationToken,
      primaryApplicant: this.primaryApplicant ? this.primaryApplicant.asJSON : undefined,
      secondaryApplicant: this.secondaryApplicant ? this.secondaryApplicant.asJSON : undefined
    }
  }

  getStatusDisplay = () => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    switch (lenderStatus && lenderStatus.state) {
      case 'Approved':
        return 'Loan Approved - Activate Account'
      case 'Assigned':
        return `Loan Approved ${this.isLoanCreated() ? '- Activate Account' : ''}`
      case 'Declined':
        return 'Application Declined'
      case 'Pending':
        return lenderStatus.status !== 'Submitted' && 'Application Submitted'
      default:
        return false
    }
  }

  isOnHold = () => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    return !!(lenderStatus && lenderStatus.hold && lenderStatus.status === 'Under Review')
  }

  resendPrequalificationEmail = async () => {
    try {
      return await this.store.root.fetch(`/api/applications/${this.id}/resend-prequalification-email`, 'POST', this.asJSON)
    } catch {
      return false
    }
  }

  isDeclined = () => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    return !!(lenderStatus && lenderStatus.status === 'Declined')
  }

  isReadyToOffer = () => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    return !!(lenderStatus && lenderStatus.offer)
  }

  getLenderApplicationState = () => {}

  isLoanCreated = () => { return this.lenderRelationShip?.metadata?.lenderLoanCreated }

  getStatusDisplayDetails = (amount, lender) => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    const lenderID = this.lenderRelationShip?.id
    const hold = `Your application is pending and is currently under review by ${lender} Loan Services for approval consideration.`
    const holdCS = `${lender} will call you shortly to complete your application.`
    const gsHoldCS = <span>Thank you for applying. {lender} will call you shortly to complete your application.</span>
    const holdFreeze = `${lender} cannot provide a credit decision at this time due to a credit freeze placed on your credit report. In order for them to process your application, you will need to unfreeze your Experian credit report; call 888-397-3742 for assistance. Once you unlock your credit file, please reapply.`

    const gsHoldFreeze = <span>{lender} was unable to access the credit file due to a credit freeze placed on applicant(s) credit report. <br /> <br />In order for them to process your application, you will need to call Experian at <a href='tel:888-397-3742' className='text-primary'>1-888-397-3742</a> or access your credit bureau online at <a _blank href="www.experian.com/freeze/center.html" className='text-primary'>www.experian.com/freeze/center.html</a> and have the freeze temporarily removed. <br /> <br />Once you have taken the above steps, please reapply.</span>

    const holdLock = <span>{lender} cannot provide a credit decision at this time due to a lock placed on your credit report. <br />In order for them to process your application, you will need to unlock your Experian credit report; call 888-397-3742 for assistance. <br />Once you unlock your credit file, please reapply.</span>
    const gsHoldLock = <span>{lender} was unable to access the credit file due to a lock placed on applicant(s) credit report. <br /> <br />In order for them to process your application, you will need to access your mobile Experian app to unlock your credit report. If you need assistance with the mobile app, you can call Experian at <a href='tel:888-397-3742' className='text-primary'>1-888-397-3742</a> and have the lock temporarily removed. <br /> <br />Once you have taken the above steps, please reapply.</span>
    const holdPOI = `Your ${lender} has been approved pending verification of your income. In order for them to process your application, please send a copy of a most recent W-2 or Two most recent Pay Stubs to creditescalations@greensky.com and include the Application ID ${lenderID}.`
    const gsHoldPOI = <span><p>The following application has been approved pending verification of your income.</p> <br /> <p><b>Verify Your Income</b></p> <p>Please send in copies of the following documents to <a href="mailto:creditescalations@greensky.com">creditescalations@greensky.com</a> and include the Application ID <b>{lenderID}</b>. Documents are required for both Applicant and Co-Applicant.</p> <span><br /> <p>Most Recent W-2</p> <p><b>AND</b></p> <p>Two (2) Most Recent Pay Stubs</p> </span> <br /><p>For alternative types of income verification, please visit <a href="https://www.greensky.com/stips" className='text-primary'>www.greensky.com/stips</a>.</p></span>
    const gsApproval = `Congratulations! Your application ${lenderID} has been approved for a Credit Limit of ${amount || 0}. Please print this page record or Application ID. You will need this information to access your account in the future. If you wish to increase your credit limit, please contact your Dealer directly or you can contact GreenSky at 866-936-0602.`
    const gsActivateAccount = `Thank you for choosing ${lender} to finance your project. To complete the last step, Applicant and Co-Applicant (if applicable) must activate their loan by responding to the text or email that has been sent to them.  If an email or text has not been received, they must call GreenSky® at 1-866-936-0602 to activate their account.`
    const activateAccount = `Thank you for choosing ${lender} to finance your project. 
    Next, check your text or email address to view the loan contract and click the "Activate Account" button.
    If you have any questions, call ${lender} at 866-936-0602.`
    switch (lenderStatus && lenderStatus.state) {
      case 'Approved':
        return lender ? lender === 'GreenSky' ? gsActivateAccount : activateAccount : gsActivateAccount
      case 'Assigned':
        if (this.isLoanCreated()) return lender ? lender === 'GreenSky' ? gsActivateAccount : activateAccount : gsActivateAccount
        return lender === 'GreenSky' ? gsApproval : `Congratulations! Your application has been approved for up to ${amount || 0}.`
      case 'Declined':
        return `Thank you for applying for a loan with ${lender}. At this time your application has been declined, and they are unable to approve your application. You will receive a letter within 30 days with the results of the credit review.`
      case 'Pending':
        if (lenderStatus.pendingReason === 'Locked') return lender ? lender === 'GreenSky' ? gsHoldLock : holdLock : gsHoldLock
        if (lenderStatus.pendingReason === 'Frozen') return lender ? lender === 'GreenSky' ? gsHoldFreeze : holdFreeze : gsHoldFreeze
        if (lenderStatus.pendingReason === 'CS') return lender ? lender === 'GreenSky' ? gsHoldCS : holdCS : gsHoldCS
        if (lenderStatus.pendingReason === 'POI') return lender ? lender === 'GreenSky' ? gsHoldPOI : holdPOI : gsHoldPOI
        return hold
      default:
        return false
    }
  }

  displayAmount = () => {
    const lenderStatus = this.lenderRelationShip?.metadata?.lenderStatus
    switch (lenderStatus && lenderStatus.state) {
      case 'Assigned':
        if (this.isLoanCreated()) return false
        return 'Loan Approved'
      case 'Declined':
        return false
      default:
        return false
    }
  }

  get stageNiceLabel() {
    console.log(this, 'THIS')
    const lender = integrations[this.suggestedLender]
    switch (this.stage) {
      case 'prequalification-invite':
        return 'Invite Pending'
      case 'prequalification':
        return `${lender ? lender.name : ''} is Offered`
      case 'lender-application':
        return `Prequalified for ${lender ? lender.name : ''}`
      case 'lender-submitted':
        if (this.requiredForApproval.length) {
          return `Conditionally approved by ${lender ? lender.name : ''}`
        }
        return `Submitted to ${lender ? lender.name : ''}`
      case 'lender-approved':
        if (this.requiredForApproval.length) {
          return `Conditionally approved by ${lender ? lender.name : ''}`
        }
        return `Approved by ${lender ? lender.name : ''}`
      case 'lender-declined':
        return `Declined by ${lender ? lender.name : ''}`
      case 'lender-funded':
        return `Funded by ${lender ? lender.name : ''}`
    }
  }

  removeSection = (object, section, key) => {
    const find = object[section].findIndex(individual => individual.key === key)
    object.archived = { ho2: object[section].find(individual => individual.key === key) }
    object[section].splice(find, 1)
    this.secondaryApplicant = undefined
    return object
  }

  restoreSection = (object, section, restore) => {
    object[section].push(restore)
    this.secondaryApplicant = new Applicant(this)
    return object
  }

  getApplicantEndpoint = (slug) => {
    if (lenders[this.suggestedLender].getApplicantEndpoint) return lenders[this.suggestedLender].getApplicantEndpoint(this, slug)
    else return false
  }

  get logs () {
    return this.store.root.logStore.logs.filter(log => log.applicationID === this.id)
  }

  get to () {
    return `/applications/${this.id}`
  }

  get merchant () {
    return this.store.root.merchantStore.findById(this.merchantID)
  }

  get pendingActions () {
    const actions = []
    // not iterable, lenders is an object @TODO: review
    for (const lender of lenders) {
      if (lender.subdomain === this.suggestedLender) {
        for (const action of lender.actionSections) {
          if (action.shouldShow(this)) {
            actions.push(action)
          }
        }
      }
    }
    return actions
  }
}

export default Application
