import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import useRootStore from '../../../hooks/useRootStore'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import PrimaryButton from '../../elements/buttons/Primary'
import Alert from '../../feedback/alerts/Simple'

function General () {
  const rootStore = useRootStore()
  const { id } = useParams()
  const [name, setName] = useState('')
  const [slug, setSlug] = useState('')
  const merchant = rootStore.merchantStore.findById(id)

  useEffect(() => {
    if (merchant) {
      setName(merchant.name || '')
      setSlug(merchant.slug || '')
    }
  }, [merchant])

  if (!merchant) return <div>Loading...</div>
  return (
    <>
      <div className="text-lg leading-6 font-medium text-gray-900 mb-4">General</div>
      <div className='flex space-x-5 items-end'>
        <InputWithLabel
          name='name'
          label='Merchant Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputWithLabel
          name='slug'
          label='Slug'
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
        {(merchant.name !== name || merchant.slug !== slug) &&
        <PrimaryButton
          label='Save'
          onClick={async () => {
            await merchant.updateGeneralInfo(name, slug)
          }}
        />
        }
      </div>
      {!merchant.slug &&
      <Alert className='mt-8 max-w-lg' label='A slug is required to create applications for this merchant' type='warning' />
      }
    </>
  )
}

General.propTypes = {}

export default observer(General)
