
class User {
  email = ''
  firstName = ''
  lastName = ''
  profilePicture = ''
  constructor (store, json) {
    this.store = store
    this.id = json._id.toString()
    this.updateFromJSON(json)
  }

  updateFromJSON (json) {
    this.email = json.email
    this.isDeveloper = json.isDeveloper
    this.status = 'Member'
    this.createdAt = json.createdAt
    this.firstName = json.firstName
    this.lastName = json.lastName
    this.merchants = json.merchants || []
    this.properties = json.properties || []
    this.profilePicture = json.profilePicture
  }

  get fullName () {
    if (this.firstName || this.lastName) {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`
      }
      return this.firstName || this.lastName
    }
  }

  get displayName () {
    return this.fullName || this.email
  }
}

export default User
