import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'

import useRootStore from '../../../hooks/useRootStore'
import lenders from '../../../constants/lenders'
import QuestionList from '../../forms/QuestionList'
import Button from '../../elements/buttons/Primary'
import ModalWrapper from '../ModalWrapper'

function LenderActionModal ({ isOpen, applicationID, actionID, onClose }) {
  const rootStore = useRootStore()
  const [answers, setAnswers] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const application = applicationID && rootStore.applicationStore.findById(applicationID)
  if (!application) return

  const lenderConfig = application && lenders[application.suggestedLender]
  const action = lenderConfig && lenderConfig.actionSections.find(action => action.id.toString() === actionID)

  function handleClickCancel () {
    setAnswers({})
    onClose(false)
  }
  async function handleClickSubmit () {
    if (submitting) return
    setSubmitting(true)
    const json = await rootStore.fetch(`/api/applications/${applicationID}/submit-action`, 'POST', { actionID, answers })
    application.updateFromJSON({ ...json.application, lenderRelationship: json.lenderRelationship })
    // await action.submit(answers, application, { apiKey: 'a4746e62-b3d6-4413-a48b-4221b23ebb05' }) // todo get the property's integration config for this lender
    setSubmitting(false)
    onClose(false)
  }

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {action.modalTitle || action.title}
        </h3>
        <QuestionList
          answers={answers}
          setAnswers={setAnswers}
          questions={action.questions}
          application={application}
        />
        <div className="mt-4 flex justify-end space-x-2">
          <Button secondary label="Cancel" onClick={handleClickCancel} />
          <Button
            label="Submit"
            disabled={!!action.questions.find(question => !answers[question.key])}
            onClick={handleClickSubmit}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
LenderActionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  applicationID: PropTypes.string,
  actionID: PropTypes.string
}

export default observer(LenderActionModal)
