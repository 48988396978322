import convertApplicationData from './convertApplicationData'
const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/asynchronoussubmit' : 'https://api1.uat.greensky.com/asynchronoussubmit'
}

export default async function submitPrequalification (application, config, data, lenderID) {
  if (data.secondaryApplicant && !data.secondaryApplicant.firstName) data.secondaryApplicant = null
  const response = await fetch(apiURL(process.env.NODE_ENV), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey,
      token: data.authorizationToken
    },
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`),
    body: JSON.stringify(convertApplicationData(data, lenderID))
  })
  if (response.status !== 200) {
    const errorResponse = await response.json()
    console.log(errorResponse)
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()
  console.log(json, 'gs json')
  return {
    success: true,
    id: json.applicationId,
    error: json.error && json.error.message,
    metadata: { ...data.metadata, token: json.token }
  }
}
