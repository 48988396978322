import { makeObservable, observable, action } from 'mobx'

class Invite {
  isAdmin = false
  constructor (store, json) {
    this.id = json._id.toString()
    this.store = store
    this.updateFromJSON(json)
    makeObservable(this, {
      merchantID: observable,
      email: observable,
      isAdmin: observable,
      updateFromJSON: action
    })
  }

  updateFromJSON (json) {
    this.merchantID = json.merchant
    this.propertyID = json.property
    this.email = json.email
    this.isAdmin = json.isAdmin

    this.userInfo = json.userInfo
    this.createdAt = json.createdAt
    this.type = json.type
  }
}

export default Invite
