import React from 'react'
import SimpleEmptyState from '../../feedback/emptyStates/Simple'

import useRootStore from '../../../hooks/useRootStore'

function GuestUserLanding () {
  const rootStore = useRootStore()
  const userTeamRelationships = rootStore.userTeamRelationships
  const properties = []
  for (const relationship of userTeamRelationships) {
    if (relationship.property) properties.push({ name: relationship.property.name, id: relationship.property._id })
  }
  return (
    <div data-cy='no-properties' className='h-screen flex items-center justify-center'>
      <SimpleEmptyState
        label='No Properties'
        subLabel='Create your first property to begin'
        buttonCy='create-property'
        buttonLabel='Create Property'
        buttonOnClick={async () => {
          const property = await rootStore.fetch('/api/properties/create', 'POST', { setCurrent: true })
          rootStore.currentUser.currentProperty = property._id
          rootStore.propertyStore.addPropertyFromJSON(property)
          window.location.href = '/'
        }}
      />
      {(userTeamRelationships.length >= 2 || !rootStore.currentPropertyID) &&
      <div className='px-4 mt-5'>
        <select onChange={async (e) => {
          if (e.target.value) {
            if (properties.find(p => p.id === e.target.value)) {
              await rootStore.fetch('/api/users/set-property', 'POST', { id: e.target.value })
            } else {
              await rootStore.fetch('/api/users/set-merchant', 'POST', { id: e.target.value })
            }
            window.location.reload()
          }
        }}>
          <option value=''>Switch to a property</option>
          {properties.map(property => (
            <option key={property.id} value={property.id}>{property.name}</option>
          ))}
        </select>
      </div>
      }
    </div>
  )
}

GuestUserLanding.propTypes = {}

export default GuestUserLanding
