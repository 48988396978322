import fetch from '../../../utils/fetch'
const apiURL = (env) => {
  return (env || process.env.NODE_ENV) === 'production' ? 'https://beta.apptracker.ftlfinance.com' : 'https://staging-apptracker.herokuapp.com'
}

const completeProjectEquipment = {
  id: 1,
  title: 'Completion Certificate',
  modalTitle: 'Project Details',
  description: 'The loan documents for this application have been signed. Once completed, please enter the project details including equipment information and install date to generate the completion certificate for funding.',
  label: 'Complete Project',

  shouldShow: (application) => {
    const lenderMetadata = application.lenderRelationship?.metadata || {}

    const isEquipment = lenderMetadata.serviceType === 'equipment'
    const isComplete = lenderMetadata.completeProject === true
    const isEquipmentNotEntered = !lenderMetadata.equipmentEntered

    return isEquipment && isComplete && isEquipmentNotEntered
  },
  shouldShowQuery: { 'metadata.serviceType': 'equipment', 'metadata.completeProject': true, 'metadata.equipmentEntered': { $exists: false }, 'application.suggestedLender': 'ftl' },
  submit: async (data, application, relationship, integrationConfig) => {
    if (!data || !data.equipment) {
      return false
    }
    relationship.metadata = {
      ...relationship.metadata,
      equipmentEntered: true,
      equipment: data.equipment,
      installationDate: data.installationDate
    }
    relationship.reason = 'action-ftl-1'
    await relationship.save()

    const response = await fetch(`${apiURL()}/api/v1/applications/${relationship.id}/submit-completion-certificate?dealer_id=OPTIMUS&api_key=${integrationConfig.apiKey}`, 'POST', {
      distributor: data.equipment[0].distributor,
      brand: data.equipment[0].brand,
      serviceDescription: data.equipment[0].description,
      installationDate: data.installationDate,
      receiver: data.receiver,
      equipment: data.equipment.map(equipment => ({ ...equipment, brand: equipment.brandCustom || equipment.brand }))
    })
    if (response.status === 200) {
      return true
    }
  },
  questions: [
    {
      key: 'equipment',
      label: 'What equipment did you purchase?',
      type: 'array',
      addLabel: 'Add Equipment',
      emptyMessage: 'Click "+ Add Equipment" above to add the equipment you purchased.',
      questions: [
        {
          label: 'Purchase location',
          shortLabel: 'Distributor',
          key: 'distributor',
          hideInPreview: true,
          type: 'option',
          options: async (application, integrationConfig, depend, env) => {
            const response = await fetch(`${apiURL(env)}/api/v1/applications/${application.lenderRelationship?.id}/distributors?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
            const distributors = await response.json()
            return [
              { label: 'Select the distributor', value: '' },
              ...distributors.map(distributor => ({ label: distributor.name, value: distributor.id }))
            ]
          },
          span: 'sm:col-span-3',
          required: true
        },
        {
          label: 'Equipment brand',
          shortLabel: 'Brand',
          key: 'brand',
          type: 'option',
          keyDependency: 'distributor',
          options: async (application, integrationConfig, dependency, env) => {
            const response = await fetch(`${apiURL(env)}/api/v1/applications/${application.lenderRelationship?.id}/distributors?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
            const distributors = await response.json()
            const distributor = distributors.find(distributor => distributor.id === dependency)
            return [
              { label: 'Select the brand', value: '' },
              ...distributor.brands.map(brand => ({ label: brand, value: brand }))
            ]
          },
          span: 'sm:col-span-3',
          required: true
        },
        {
          label: 'Other brand name',
          shortLabel: 'Brand',
          key: 'brandCustom',
          hideInPreview: true,
          type: 'text',
          shouldShow: (application, answers) => {
            return answers.brand === 'Other'
          }
        },
        {
          key: 'model',
          shortLabel: 'Model #',
          label: 'Model number',
          type: 'text',
          required: true
        },
        {
          key: 'serialNumber',
          shortLabel: 'Serial #',
          label: 'Serial number',
          type: 'text',
          required: true
        },
        {
          key: 'description',
          shortLabel: 'Description',
          label: 'Equipment Description',
          type: 'text',
          required: true
        }
      ],
      required: true
    },
    {
      label: 'Project completion date:',
      key: 'installationDate',
      type: 'date',
      required: true
    },
    {
      label: 'Send certificate to:',
      key: 'receiver',
      type: 'option',
      options: async (application, integrationConfig, dependency, env) => {
        const response = await fetch(`${apiURL(env)}/api/v1/applications/${application.lenderRelationship?.id}/receivers?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
        const receivers = await response.json()

        return [
          { label: 'Select the Homeowner', value: '' },
          ...receivers
        ]
      },
      required: true
    }
  ]
}

const completeProjectService = {
  id: 2,
  shouldShow: (application) => {
    const lenderMetadata = application.lenderRelationship?.metadata || {}

    const isService = lenderMetadata.serviceType === 'service'
    const isComplete = lenderMetadata.completeProject === true
    const isEquipmentNotEntered = !lenderMetadata.equipmentEntered

    return isService && isComplete && isEquipmentNotEntered
  },
  shouldShowQuery: { 'metadata.serviceType': 'service', 'metadata.completeProject': true, 'metadata.equipmentEntered': { $exists: false }, 'application.suggestedLender': 'ftl' },
  status: 'approved',
  label: 'Complete Project',
  title: 'Complete Project',
  description: 'The loan documents for this application have been signed. Once completed, please enter the project details including the installation date to generate the completion certificate for funding.',

  modalTitle: 'Project Details',
  submit: async (data, application, relationship, integrationConfig) => {
    const response = await fetch(`${apiURL()}/api/v1/applications/${application.lenderRelationship?.id}/submit-completion-certificate?dealer_id=OPTIMUS&api_key=${integrationConfig.apiKey}`, 'POST', {
      serviceDescription: data.serviceDescription,
      installationDate: data.installationDate,
      receiver: data.receiver
    })
    if (response.status === 200) {
      relationship.metadata.equipmentEntered = true
      relationship.reason = 'action-ftl-2'
      await relationship.save()
      return true
    }
  },
  questions: [
    {
      label: 'What date will this project be completed?',
      key: 'installationDate',
      type: 'date',
      required: true
    },
    {
      label: 'Please describe the service being performed',
      key: 'serviceDescription',
      type: 'text',
      required: true,
      placeholder: 'Description...'
    },
    {
      label: 'Send certificate to:',
      key: 'receiver',
      type: 'option',
      options: async (application, integrationConfig, dependency, env) => {
        const response = await fetch(`${apiURL(env)}/api/v1/applications/${application.lenderRelationship?.id}/receivers?dealer_id=NEW&api_key=${integrationConfig.apiKey}`)
        const receivers = await response.json()

        return [
          { label: 'Select the Homeowner', value: '' },
          ...receivers
        ]
      },
      required: true
    }
  ]
}

const actions = [
  completeProjectEquipment,
  completeProjectService
]

export default actions
