import React, { useEffect, useState } from 'react'

import useRootStore from '../../../hooks/useRootStore'

function AdminPostmark () {
  const [servers, setServers] = useState([])
  const rootStore = useRootStore()
  useEffect(() => {
    async function init () {
      const servers = await rootStore.fetch('/api/admin/postmark')
      setServers(servers.Servers)
    }

    init()
  }, [rootStore])
  return (
    <div>
      <div className='font-bold text-lg mb-8'>Postmark Servers</div>

      <div className='space-y-4'>
        {servers.map((server, i) => (
          <div key={server.ID} className='flex space-x-2 items-center'>
            <div style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: server.Color }} />
            <div>{server.Name}</div>
            <div>{server.DeliveryType}</div>
            {server.DeliveryType === 'Sandbox' &&
            <div
              className='cursor-pointer'
              onClick={async () => {
                await rootStore.fetch(`/api/admin/postmark/${server.ID}/${server.ApiTokens[0]}`, 'DELETE')
                setServers(servers.filter((s) => s.ID !== server.ID))
              }}>
              Delete
            </div>
            }
            {!['FTL Finance', '_Templates'].includes(server.Name) &&
            <div
              className='cursor-pointer'
              onClick={() => {
                rootStore.fetch(`/api/admin/postmark/${server.ID}/sync`, 'POST')
              }}>
              Sync templates
            </div>
            }
          </div>
        ))}
      </div>
    </div>
  )
}

AdminPostmark.propTypes = {}

export default AdminPostmark
