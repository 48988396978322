import React from 'react'
import { MagnifyingGlassIcon, InboxIcon } from '@heroicons/react/24/outline'

import MainWrapper from '../MainWrapper'

const navigation = [
  { name: 'Dashboard', href: '/', icon: InboxIcon, current: location.pathname === '/' },
  { name: 'Applications', href: '/applications', icon: MagnifyingGlassIcon, current: location.pathname === '/applications' }
]

function DeveloperWrapper () {
  return (
    <MainWrapper hideSidebarFooter navigation={navigation} />
  )
}

DeveloperWrapper.propTypes = {}

export default DeveloperWrapper
