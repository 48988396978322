import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

function CheckboxWithInlineDescription ({ checked, className, description, descriptionDetail, disabled, field, label, onChange }) {
  return (
    <fieldset>
      {label && <legend className="sr-only">{label}</legend>}
      <div className={classNames('relative flex items-start', className)}>
        <div className="flex h-5 items-center">
          <input
            id={field}
            aria-describedby={field}
            name={field}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primaryLight focus:ring-primaryLighter"
            checked={checked}
            onChange={(e) => {
              onChange(e.target.checked)
            }}
            disabled={disabled}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {description}
          </label>
          {descriptionDetail &&
            <span id="comments-description" className="text-gray-500">
              {descriptionDetail}
            </span>
          }
        </div>
      </div>
    </fieldset>
  )
}

CheckboxWithInlineDescription.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.any,
  description: PropTypes.string,
  descriptionDetail: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default CheckboxWithInlineDescription
