import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'

import Simple from '../../elements/dropdowns/Simple'

function EllipseDropdown ({ links, cy, Icon }) {
  const SelectIcon = Icon || EllipsisHorizontalIcon
  return (
    <Menu as='div' className='relative' data-cy={cy}>
      <Menu.Button data-cy={`${cy}-button`}>
        <SelectIcon className='w-4 h-4 text-gray-500' />
      </Menu.Button>
      <Simple
        className='mt-2 cursor-pointer'
        links={links}
      />
    </Menu>
  )
}

EllipseDropdown.propTypes = {
  links: PropTypes.array.isRequired,
  cy: PropTypes.string,
  Icon: PropTypes.object
}

export default EllipseDropdown
