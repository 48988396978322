import { Dialog } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import useRootStore from '../../../hooks/useRootStore'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import ModalWrapper from '../ModalWrapper'
import Toggle from '../../forms/toggles/Simple'

function InviteUserToMerchantModal ({ isOpen, merchantID, onClose }) {
  const rootStore = useRootStore()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Invite User To Merchant
          </Dialog.Title>
          <div className="mt-2">
            <span className="flex space-x-6 w-full">
              <InputWithLabel
                className="mb-3"
                label="First Name"
                name="firstName"
                value={firstName || ''}
                onChange={e => {
                  setFirstName(e.target.value)
                }}
              />
              <InputWithLabel
                className="mb-3"
                label="Last Name"
                name="lastName"
                value={lastName || ''}
                onChange={e => {
                  setLastName(e.target.value)
                }}
              />
            </span>
            <InputWithLabel
              className="mb-3"
              label="Email"
              name="email"
              value={email || ''}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex items-center justify-between">
        <div className='flex items-center'>
          <div className='mr-2 text-xs'>Admin</div>
          <Toggle label='Admin' enabled={isAdmin} setEnabled={setIsAdmin} />
        </div>
        <div className='sm:flex sm:flex-row-reverse'>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={async () => {
              const json = await rootStore.fetch(`/api/merchants/${merchantID}/invite`, 'POST', { firstName, lastName, email, isAdmin })
              rootStore.inviteStore.addInviteFromJSON(json)
              onClose(false)
            }}>
            Invite
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryLighter sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}
InviteUserToMerchantModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  merchantID: PropTypes.string
}

export default InviteUserToMerchantModal
