import { makeObservable, observable, action } from 'mobx'
import Property from './Property'

class Properties {
  properties = []
  constructor (rootStore, properties = []) {
    this.root = rootStore
    this.properties = properties.map(json => new Property(this, json))
    makeObservable(this, {
      properties: observable,
      addPropertyFromJSON: action
    })
  }

  findById = (id) => this.properties.find(property => property.id === id)

  addPropertyFromJSON (json) {
    if (!this.findById(json._id)) {
      this.properties.push(new Property(this, json))
      return this.properties[this.properties.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }
}

export default Properties
