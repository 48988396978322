import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../navigation/breadcrumbs/SimpleWithChevrons'
import classNames from 'classnames'

function Simple ({ pages, right, className, floatAtTop }) {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div className={classNames('transition-shadow duration-300 bg-white h-12 sm:h-16 w-full flex items-center justify-between px-4 sm:px-8 border-b border-slate-200', className, (scrollPosition > 0 && floatAtTop) ? 'shadow-sm' : 'shadow-none', {
      'relative md:sticky top-0 z-10': floatAtTop
    })}>
      <Breadcrumbs pages={pages} />
      {right}
    </div>
  )
}

Simple.propTypes = {
  pages: PropTypes.array.isRequired,
  right: PropTypes.node,
  className: PropTypes.string,
  floatAtTop: PropTypes.bool
}

export default Simple
