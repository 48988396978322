import React from 'react'
import { MagnifyingGlassIcon, ArrowDownTrayIcon, ArrowsUpDownIcon, FunnelIcon as OutlineFunnel } from '@heroicons/react/24/outline'
import { FunnelIcon } from '@heroicons/react/24/solid'
import { useSearchParams } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'

import useRootStore from '../../../hooks/useRootStore'
import Input from '../../forms/inputGroups/InputWithLabel'
import ButtonDropdown from '../../elements/dropdowns/ButtonDropdown'
import Dropdown from '../../elements/dropdowns/Dropdown'

const sortLabels = {
  newest: 'Newest',
  oldest: 'Oldest'
}
function FilterBar () {
  const rootStore = useRootStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchObj = {}
  searchParams.forEach((value, key) => {
    searchObj[key] = value
  })
  return (
    <div className='flex items-center space-x-4'>
      <Dropdown
        button={(
          <div className='flex py-2 px-2 text-sm border border-gray-300 rounded-md hover:bg-gray-50 shadow-sm'>
            {searchParams.get('stage') ? <FunnelIcon className='w-5 h-5 text-gray-400' /> : <OutlineFunnel className='w-5 h-5 text-gray-400' />}
          </div>
        )}
        content={(
          <Menu.Items className={classNames('origin-top-right mt-2 absolute right-0 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none')}>
            <div className="p-2">
              <div className='text-sm text-gray-700 mb-1'>Stage</div>
              <select value={searchParams.get('stage')} onChange={e => setSearchParams({ ...searchObj, stage: e.target.value })} className='w-full border border-gray-300 rounded-md px-2 py-1'>
                <option value=''>All</option>
                <option value='prequalification-invite'>Invited</option>
                <option value='prequalification'>Prequalifying</option>
                <option value='lender-application'>Lender&apos;s Application</option>
                <option value='lender-submitted'>Pending Decision</option>
                <option value='lender-approved'>Approved by Lender</option>
                <option value='lender-declined'>Declined by Lender</option>
                <option value='lender-funded'>Funded by Lender</option>
              </select>
            </div>
          </Menu.Items>
        )}
      />
      <ButtonDropdown
        buttonLabel={(
          <span className='flex items-center text-gray-500'>
            <ArrowsUpDownIcon className='w-5 h-5 mr-1 text-gray-400' /> <span className='hidden sm:inline md:hidden lg:inline'>Sort: {sortLabels[searchParams.get('sort') || 'newest']}</span>
          </span>
        )}
        links={[
          { label: 'Newest', onClick: () => setSearchParams({ ...searchObj, sort: 'newest' }) },
          { label: 'Oldest', onClick: () => setSearchParams({ ...searchObj, sort: 'oldest' }) }
        ]}
      />
      <Input
        name="search"
        Icon={MagnifyingGlassIcon}
        placeholder="Search Applications"
        value={searchParams.get('search') || ''}
        onChange={e => setSearchParams({ ...searchObj, search: e.target.value })}
      />
      <div className="flex items-center">
        <ArrowDownTrayIcon
          className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-700"
          onClick={async () => {
            await rootStore.fetch('/data/exports/application')
          }}
        />
      </div>
    </div>
  )
}

FilterBar.propTypes = {}

export default FilterBar
