import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/24/solid'

import useRootStore from '../../../hooks/useRootStore'
import Button from '../../elements/buttons/Primary'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'

function Register () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const rootStore = useRootStore()
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link to="/login" className="font-medium text-primaryLight hover:text-primaryLighter">
              log into an existing account
            </Link>
          </p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
          className="mt-8 space-y-6">
          <InputWithLabel
            label='Email address'
            name='email'
            type='email'
            cy='email'
            errorMessage={emailErrorMessage}
            autoComplete='email'
            placeholder='Email address'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <InputWithLabel
            label='Password'
            name='password'
            type='password'
            cy='password'
            errorMessage={passwordErrorMessage}
            autoComplete='new-password'
            placeholder='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <InputWithLabel
            label='Confirm Password'
            name='passwordC'
            type='password'
            cy='passwordC'
            autoComplete='new-password'
            placeholder='Confirm Password'
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
          />
          <Button
            label='Register'
            cy='submit'
            disabled={!email || !password || !passwordConfirmation}
            Icon={LockClosedIcon}
            onClick={async () => {
              if (password !== passwordConfirmation) {
                return setPasswordErrorMessage('Passwords do not match')
              }
              if (password.length < 6) {
                return setPasswordErrorMessage('Password must be at least 6 characters')
              }
              if (email && password.length > 6 && password === passwordConfirmation) {
                const { success } = await rootStore.fetch('/auth/register', 'POST', { email, password, property: rootStore.currentPropertyID })
                if (success) {
                  window.location.href = '/'
                } else {
                  setEmailErrorMessage('Email already in use')
                }
              }
            }}
          />
        </form>
      </div>
    </div>
  )
}

export default Register
