const ssnBlacklist = new Set(['078051120', '219099999', '457555462'])
const ssnRegex = /^(?!000|9\d{2})\d{3}[ -]{0,1}\d{2}[ -]{0,1}(?!0{4})\d{4}$/
const isValidSSN = (value) => ssnRegex.test(value) && !ssnBlacklist.has(value.replace(/\D/g, ''))

const emailRegex = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z]+\.)+[A-Za-z]{2,}))$/
const isValidEmail = (value) => {
  return !!value && emailRegex.test(value)
}

const isValidPassword = (value) => {
  return value && value.length >= 6
}

const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
const isValidPhone = (value) => {
  return phoneRegex.test(value)
}

const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
const isValidDate = (value) => {
  return !dateRegex.test(value) ? 'Please enter a valid date of birth.' : true
}

const installDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/
const isValidInstallationDate = (value) => {
  return !installDateRegex.test(value) ? 'Please enter a valid date.' : true
}

const streetAddressRegex = /^\s*\S+(?:\s+\S+){2}/
const notPoBoxRegex = /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i
const isValidStreetAddress = (value) => {
  return streetAddressRegex.test(value) && notPoBoxRegex.test(value)
}

const cityRegex = /^(?:[A-Za-z]+[',.-]?\s?)+$/ // /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/
const isValidCity = (value) => {
  return cityRegex.test(value)
}

const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
const isValidZip = (value) => {
  return zipRegex.test(value)
}

export default {
  ssn: isValidSSN,
  email: isValidEmail,
  password: isValidPassword,
  phone: isValidPhone,
  day: isValidDate,
  streetAddress: isValidStreetAddress,
  city: isValidCity,
  zipCode: isValidZip,
  date: isValidInstallationDate
}
