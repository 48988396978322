import React from 'react'

import actionSections from './actions'
import collectMerchantDetails from './collectMerchantDetails'
import convertUpdate from './convertUpdate'
import createContract from './createContract'
import fetchUpdate from './fetchUpdate'
import format from '../../../utils/format'
import getApplicationOffers from './getApplicationOffers'
import getApplicationStatusUpdate from './getApplicationStatusUpdate'
import getMerchantPlans from './getMerchantPlans'
import prequalificationPreCheck from './prequalificationPreCheck'
import questionSections from './questions'
import recalculateOffers from './recalculateOffers'
import submitPrequalification from './submitPrequalification'

function loadScript (doc, url) {
  return new Promise((resolve, reject) => {
    const scriptTag = doc.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.src = url
    scriptTag.onload = () => resolve()
    doc.body.append(scriptTag)
  })
}

const greensky = {
  apiKey: 'Basic aE9wdGltdXNQbHVzOjBEMDFUSTZBRmNhVTBrTw==', // TODO use integration setting
  automaticStatusFetch: true,
  submitApiKey: null,
  convertUpdate,
  getMerchantPlans,
  getApplicantEndpoint: null, // (config) => `/${config.slug}/apply/${config.id}/complete`,
  prequalificationPreCheck,
  collectMerchantDetails,
  submitPrequalification,
  getApplicationOffers,
  createContract,
  recalculateOffers,
  questionSections,
  actionSections,
  getApplicationStatusUpdate,
  fetchUpdate,
  prequalificationResult: (application, lender, offerSelected) => {
    return (
      <div className='p-4'>
        <p className='text-base text-black-500 text-center'>
          {application.primaryApplicant.fullName}, you are prequalified for financing with <b>{lender.name}</b>!
        </p>
      </div>
    )
  },
  footer: () => {
    return (
      <div className="text-xs px-3 md:px-6 py-2.5 w-full bg-white border-b border-gray-200 text-gray-500 flex flex-col sm:flex-row items-end sm:items-start">
        <span>&copy; 2006 - 2022 GreenSky, LLC. All Rights Reserved. | GreenSky<sup className="sup">&reg;</sup> and GreenSky Patient Solutions<sup className="sup">&reg;</sup> are loan program names for certain consumer credit plans extended by participating lenders to borrowers for the purchase of goods and/or services from participating merchants/providers. Participating lenders are federally insured, federal and state chartered financial institutions providing credit without regard to age, race, color, religion, national origin, gender or familial status. GreenSky<sup className="sup">&reg;</sup> and GreenSky Patient Solutions<sup className="sup">&reg;</sup> are registered trademarks of GreenSky, LLC. GreenSky Servicing, LLC services the loans on behalf of participating lenders. NMLS #1416362. GreenSky, LLC and GreenSky Servicing, LLC are subsidiaries of Goldman Sachs Bank USA. Loans originated by Goldman Sachs are issued by Goldman Sachs Bank USA, Salt Lake City Branch.</span>
        <img className='h-10 sm:h-12 md:h-14 ml-3' src="https://cms.greensky.com/images/home-lender.svg" tabIndex="0" alt="Equal Opportunity Lender" />
      </div>
    )
  },
  savePlan: async (relationship, body) => {
    relationship.metadata = { ...relationship.metadata, planNumber: body?.lenderRelationship?.planNumber }
    await relationship.save()
    return true
  },
  hiddenInputs: [
    { id: 'fpBlackBox', name: 'fpBlackBox' },
    { id: 'ioBlackBox', name: 'ioBlackBox' }
  ],
  reloadTabs: (application) => { return application?.primaryApplicant?.married },
  getChecklistItems: (application) => {
    const items = [
      { label: 'Approved by Greensky', isComplete: (application) => !!application.lenderRelationship?.metadata?.approvedAmount, isPending: (application) => application.stage === 'lender-submitted' },
      { label: 'Homeowner selected a loan option', isComplete: (application) => !!application.lenderRelationship?.metadata?.actualRequestedAmount, isPending: (application) => !!application.lenderRelationship?.metadata?.lenderStatus?.offer && !application.lenderRelationship?.metadata?.actualRequestedAmount },
      { label: 'Project details entered', isComplete: (application) => application.lenderRelationship?.metadata?.equipment, isPending: (application) => !!application.lenderRelationship?.metadata?.primaryApplicantSigned && !application.lenderRelationship?.metadata?.completionCertificateSent },
      { label: 'Funds authorized for deposit', isComplete: (application) => false, isPending: (application) => false }
    ]
    if (application.lenderRelationship?.metadata?.lenderStatus?.hold && application.lenderRelationship?.metadata?.lenderStatus?.pendingReason === 'POI') {
      items.unshift({ label: 'Proof accepted', isComplete: () => false, isPending: true })
      items.unshift({ label: 'Approved with Proof of Income', isComplete: () => false, isPending: true })
    }

    if (application.stage === 'lender-declined') {
      return [{ label: 'Declined by Greensky', isComplete: () => false, danger: true }]
    }
    return items
  },
  getIDFromUpdate: update => update.applicationId,
  getAppTokenFromUpdate: update => update.token,
  redirectLink: (config) => `/${config.slug}/apply/${config.id}/complete`,
  getPreviewData: (application) => {
    const previewData = {}
    const lenderMetadata = application.lenderRelationship?.metadata || {}

    if (lenderMetadata.installationDate) previewData['Installation Date'] = lenderMetadata.installationDate
    if (lenderMetadata.APR) previewData['Interest Rate'] = lenderMetadata.APR + '%'
    if (lenderMetadata.term) previewData.Term = lenderMetadata.term + ' months'
    if (lenderMetadata.monthlyPayment) previewData['Monthly Payment'] = format.amount(lenderMetadata.monthlyPayment) + '/month'

    if (lenderMetadata.equipmentEntered && lenderMetadata.equipment?.length) {
      previewData.Equipment = lenderMetadata.equipment.map(equipment => ({
        Brand: equipment.brand,
        Model: equipment.model,
        'Serial #': equipment.serialNumber
      }))
    }
    return previewData
  },
  getPreSubmitQuestions: (application) => {
    if (!application.secondaryApplicant) {
      return {
        label: 'Are you sure you want to submit without a co-applicant?',
        description: 'If there are two homeowners for this property, it is best both are added to your application.',
        buttons: [
          {
            disabled: (submitting) => submitting,
            label: 'Add a co-applicant',
            onClick: (submit, closeModal, setStep) => {
              application.setAnswer('secondaryApplicant.firstName', '')
              setStep(2)
              closeModal()
            },
            secondary: true
          },
          {
            disabled: (submitting) => submitting,
            cy: 'secondary-submit',
            label: (submitting) => submitting ? 'Submitting...' : 'Yes, continue without a co-applicant',
            onClick: (submit, closeModal) => {
              return submit()
            }
          }
        ]
      }
    }
  },
  onApplicationLoad: async (window, doc, environment) => {
    window.fp_bbout_element_id = 'fpBlackBox'
    window.io_bbout_element_id = 'ioBlackBox'

    if (environment === 'production') {
      await loadScript(doc, '/js/prod_static_wdp.js')
      await loadScript(doc, 'https://mpsnare.iesnare.com/snare.js')
    } else {
      await loadScript(doc, '/js/test_static_wdp.js')
      await loadScript(doc, 'https://ci-mpsnare.iovation.com/snare.js')
    }
  }
}

export default greensky
