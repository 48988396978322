import React from 'react'

function LoadingState () {
  return (
    <div className="divide-gray-200 divide-y rounded-lg shadow overflow-hidden">
      <div className='bg-gray-50 h-12 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
      <div className='bg-white h-14 animate-pulse' />
    </div>
  )
}

export default LoadingState
