import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const bgColors = {
  info: 'bg-gray-100'
}
const textColors = {
  info: 'text-gray-800'
}
function Simple ({ label, type }) {
  return (
    <span className={classNames('inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium', bgColors[type], textColors[type])}>
      {label}
    </span>
  )
}

Simple.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info'])
}
Simple.defaultProps = {
  type: 'info'
}
export default Simple
