import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function PanelsSteps ({ steps, stepDisplay, setStepDisplay, application }) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="bg-white border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, i) => (
          <li key={step.key} className="relative md:flex-1 md:flex">
            {step.status === 'complete'
              ? (
                <a href={step.href} className="group flex items-center w-full">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primaryLight rounded-full group-hover:bg-primaryDark">
                      <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                  </span>
                </a>
                )
              : step.status === 'current'
                ? (
                  <a href={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primaryLight rounded-full">
                      <span className="text-primaryLight">{step.number}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-primaryLight">{step.name}</span>
                  </a>
                  )
                : (
                  <a href={step.href} className="group flex items-center">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span
                        className={classNames('flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400', {
                          'opacity-50': !stepDisplay[i]
                        })}>
                        <span className="text-gray-500 group-hover:text-gray-900">{step.number}</span>
                      </span>
                      <div className='ml-4 flex flex-col'>
                        <span
                          className={classNames('text-sm font-medium text-gray-500 group-hover:text-gray-900', {
                            'opacity-50': !stepDisplay[i]
                          })}>
                          {step.name}
                        </span>
                        {!stepDisplay[i] &&
                        <span
                          className='text-xs text-blue-400 cursor-pointer'
                          onClick={() => {
                            if (step.onClickAddButton) {
                              step.onClickAddButton(application)
                            }

                            const newStepDisplay = [...stepDisplay]
                            newStepDisplay[i] = true
                            setStepDisplay(newStepDisplay)
                          }}>
                          {step.addButtonLabel || `Add ${step.name}`}
                        </span>
                        }
                      </div>
                    </span>
                  </a>
                  )
            }

            {i !== steps.length - 1 && (
              <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none">
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

PanelsSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  stepDisplay: PropTypes.array.isRequired,
  setStepDisplay: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired
}

export default PanelsSteps
