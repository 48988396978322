import getApplicationStatusUpdate from './getApplicationStatusUpdate'
const apiURL = (env) => {
  return env === 'production' ? 'https://api.greensky.com/transaction/application/search' : 'https://api.uat1.greensky.com/transaction/application/search'
}

export default async function fetchUpdate (application, data, config) {
  if (data?.metadata?.equipmentEntered) return ''
  const updatedMetadata = {}
  const response = await fetch(apiURL(process.env.NODE_ENV), {
    method: 'POST',
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': application?.merchant?.lenderIDs?.gsky_x_api_key
    },
    body: JSON.stringify({
      applicationId: data?.id,
      channel: 'External-API-OptimusPlus'
    })
  })

  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()
  if (json.error) return { success: false, error: json.error.message }
  if (!json.applicationSearchResults) {
    console.log('missing applicationSearchResults', json)
  }
  if (!json.error && json.applicationSearchResults) {
    if (json.applicationSearchResults[0].applicationToken !== data.metadata.token) updatedMetadata.token = json.applicationSearchResults[0].applicationToken
    updatedMetadata.creditCardUID = json.applicationSearchResults[0].creditCardUID
    console.log('UPDATE 2')
    const { error, metadata } = await getApplicationStatusUpdate(application, config, data)
    if (!error && metadata && metadata.lenderStatus && metadata.lenderStatus !== data.metadata.lenderStatus) {
      updatedMetadata.lenderStatus = metadata.lenderStatus
    }
    if (json.applicationSearchResults[0]?.authorizationStatus === 'Open' && json.applicationSearchResults[0]?.activationStatus === 'Activated') {
      updatedMetadata.loanActivated = true
    }
  }
  // return data and save
  data.metadata = { ...data.metadata, ...updatedMetadata }
  await data.save()
  return false
  // return { success: true, error: json.error ? json.error.message : false, metadata: updatedMetadata, applicationDetails: json.applicationSearchResults[0] }
}
