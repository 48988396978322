class Workflow {
  constructor (store, json) {
    this.store = store
    this.id = json._id.toString()
    this.updateFromJSON(json)
  }

  updateFromJSON (json) {}
}

export default Workflow
