import React, { useState, useEffect } from 'react'
import {
  PaintBrushIcon, UsersIcon, KeyIcon, DocumentCheckIcon, ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { observer } from 'mobx-react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import DesktopSidebar from './Sidebar/DesktopSidebar'
import InviteUserToPropertyModal from '../../modals/InviteUserToPropertyModal'
import MobileHeader from './MobileHeader'
import Sidebar from './Sidebar'
import useRootStore from '../../../hooks/useRootStore'

const navigation = [
  [
    {
      name: 'General Settings', href: '/settings/', icon: PaintBrushIcon
    },
    { name: 'Alerts & Banners', href: '/settings/alerts', icon: ExclamationTriangleIcon },
    { name: 'Team', href: '/settings/team', icon: UsersIcon },
    { name: 'API', href: '/settings/api', icon: KeyIcon },
    { name: 'Prequalification', href: '/settings/prequalification', icon: DocumentCheckIcon }
  ]
]

function SettingsWrapper () {
  const rootStore = useRootStore()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    async function init () {
      const { integrationConfigs } = await rootStore.fetch(`/data/wrappers/property-settings/${rootStore.currentProperty.id}`)
      rootStore.currentProperty.addIntegrationsFromJSON(integrationConfigs)
    }
    init()
  }, [rootStore])

  return (
    <div data-cy='settings-wrapper'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} navigation={navigation} />

      <DesktopSidebar navigation={navigation} />

      <div className="md:pl-64 flex flex-col flex-1">
        <MobileHeader setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <Outlet />
        </main>
      </div>
      <InviteUserToPropertyModal
        isOpen={rootStore.currentDialog.includes('inviteUserToProperty')}
        onClose={rootStore.closeDialog}
        sendInvite={async (data) => {
          const json = await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/invite`, 'POST', data)
          if (!json.error) rootStore.inviteStore.addInviteFromJSON(json)
          else console.log('display error', json.error) // add toastr
        }}
      />
      <Toaster position='top-right' />
    </div>
  )
}

export default observer(SettingsWrapper)
