import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'
import { SketchPicker } from 'react-color'

function ColorBox ({ color, label, setColor }) {
  return (
    <Menu as='div' className='relative'>
      <div className='text-xs'>{label}</div>
      <Menu.Button className='w-12 h-8 p-2 bg-white border cursor-pointer'>
        <div className='w-full h-full rounded' style={{ backgroundColor: color }} />
      </Menu.Button>
      <Menu.Items className='absolute z-10 mt-1 overflow-auto rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
        <SketchPicker
          color={color}
          onChangeComplete={e => setColor(e.hex)}
        />
      </Menu.Items>
    </Menu>
  )
}

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired
}
export default ColorBox
