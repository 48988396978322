import React from 'react'

function DeveloperDashboard () {
  return (
    <div>
      dashboard
    </div>
  )
}

DeveloperDashboard.propTypes = {}

export default DeveloperDashboard
