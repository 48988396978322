import React from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

function Dropzone ({ children, onDrop }) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

Dropzone.propTypes = {
  children: PropTypes.node.isRequired,
  onDrop: PropTypes.func.isRequired
}
export default Dropzone
