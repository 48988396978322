const applicationDetails = {
  name: 'Application Details',
  key: 'app-details',
  number: '01',
  required: () => true,
  startRemoved: () => false,
  isRemovable: () => false,
  subsections: [
    {
      questions: [
        [
          {
            label: 'What are you financing?',
            key: 'lenderMetadata.service',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Service, Repair, or Materials', value: 'service' },
              { label: 'Equipment Install', value: 'equipment' }
            ],
            getErrorMessage: () => false
          },
          {
            label: 'How much do you want to borrow?',
            key: 'requestAmount',
            type: 'dollar',
            inputWidth: 160,
            required: true,
            keyDependency: 'serviceType',
            getErrorMessage: ({ dependencyValue, answer }) => {
              const min = 1000

              if (Number.isNaN(answer)) return 'Invalid value.'
              if (min && answer < min) return `Minimum amount is $${min.toLocaleString('en-US')}. Please select a higher amount.`
            }
          },
          {
            label: 'What is your preferred language?',
            key: 'contractLanguage',
            type: 'dropdown',
            options: [
              { label: 'English', value: 'English' },
              { label: 'Spanish', value: 'Spanish' }
            ],
            required: true
          },
          {
            label: 'Where will this project be completed?',
            key: 'installationAddress',
            type: 'address',
            required: true,
            note: 'You must be the homeowner of this address.',
            disclosure: 'Physical address required. No P.O. Boxes. If you are a participant in a state-sponsored Address Confidentiality Program, please call us at 1-833-215-6869 to apply.'
          },
          {
            label: 'Do you own this property?',
            key: 'propertyOwner',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Yes', value: 'y' },
              { label: 'No', value: 'n' }
            ],
            getErrorMessage: ({ answer }) => {
              if (answer === 'n') return 'You must own the property to create an application'
              return false
            }
          }
        ]
      ]
    }
  ]
}

export default applicationDetails
