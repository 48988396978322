async function requestSignedS3Url (file, bucket) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File) || typeof bucket !== 'string') {
      return reject(new Error('Please provide a valid file and S3 bucket'))
    }
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) {
          return reject(new Error('Could not get signed URL.'))
        }
        /* Upload File to Amazon */
        resolve(JSON.parse(xhr.responseText))
      }
    }
    xhr.open('GET', `/s3/sign-request?file-name=${Date.now() + (Math.floor(Math.random() * 100) + 1)}&file-type=${file.type}&bucket=${bucket}`)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.send(file)
  })
}

async function uploadFileToS3 (file, signedRequest) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File) || typeof signedRequest !== 'string') {
      return reject(new Error('Please provide a file and a signedRequest url.'))
    }

    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) {
          return reject(new Error('Could not upload file.'))
        }
        resolve()
      }
    }
    xhr.open('PUT', signedRequest)
    xhr.send(file)
  })
}

export default {
  requestSignedS3Url,
  uploadFileToS3
}
