import PropTypes from 'prop-types'
import React from 'react'

function LeftAligned ({ list }) {
  return (
    <dl className="py-2 sm:py-0 sm:divide-y sm:divide-gray-200">
      {list.map((data, i) => (
        <div key={i} className="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6">
          <dt className="text-sm font-medium text-gray-500">{data.label}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.value}</dd>
        </div>
      ))}
    </dl>
  )
}

LeftAligned.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any
  })).isRequired
}

export default LeftAligned
