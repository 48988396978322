import Workflow from './Workflow'

class Workflows {
  workflows = []
  constructor (rootStore, workflows = []) {
    this.root = rootStore
    this.workflows = workflows.map(json => new Workflow(this, json))
  }

  findById = (id) => this.workflows.find(workflow => workflow.id === id)

  addWorkflowFromJSON (json) {
    if (!this.findById(json._id)) {
      this.workflows.push(new Workflow(this, json))
      return this.workflows[this.workflows.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }
}

export default Workflows
