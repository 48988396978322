import React from 'react'
import PropTypes from 'prop-types'

import InputWithLabel from '../inputGroups/InputWithLabel'

function DateQuestion ({ question, answer, onChange }) {
  return (
    <div>
      <InputWithLabel type='day' name={question.key} label={question.label} value={answer || ''} onChange={e => onChange(e.target.value)} />
    </div>
  )
}

DateQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default DateQuestion
