import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

function MultiCheckboxWithInlineDescription ({ checked, className, description, descriptionDetail, field, label, onChange }) {
  return (
    <fieldset>
      <div className='flex'>
        <div className='min-w-[150px]'>
          {label.map((l, index) => (
            <div key={index} className='flex w-full'>
              <legend className="sr-only">{l}</legend>
              <div className="flex h-5 items-center justify-between w-full">
                <label htmlFor="label" className="font-medium text-gray-700 pr-4">{l}</label>
                <input
                  id={field + '.' + index}
                  aria-describedby={field + '.' + index}
                  name={field + '.' + index}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primaryLight focus:ring-primaryLighter"
                  checked={checked && checked['i' + index]}
                  onChange={(e) => {
                    onChange(e.target.checked, 'i' + index)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={classNames('ml-6 relative flex items-start', className)}>
          <div className="text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              {description}
            </label>
            {descriptionDetail &&
            <span id="comments-description" className="text-gray-500">
              {descriptionDetail}
            </span>
            }
          </div>
        </div>
      </div>
    </fieldset>
  )
}

MultiCheckboxWithInlineDescription.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.any,
  description: PropTypes.string,
  descriptionDetail: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default MultiCheckboxWithInlineDescription
