import React from 'react'

import useRootStore from '../../../hooks/useRootStore'
import EmptyStateSimple from '../../feedback/emptyStates/Simple'

function EmptyState () {
  const rootStore = useRootStore()

  return (
    <div className="flex-1 flex items-center flex-col justify-center h-full">
      <EmptyStateSimple
        label='No Merchants'
        subLabel='Add a merchant to begin inviting their team'
        buttonLabel='Add Merchant'
        buttonOnClick={() => rootStore.openDialog('addMerchant')}
      />
    </div>
  )
}

EmptyState.propTypes = {}

export default EmptyState
