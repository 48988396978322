import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'

function SelectWithCheckMark ({ label, cy, options, disabled, value, onChange, isValid, errorMessage }) {
  const selected = value
  const [alert, setAlert] = useState(false)
  return (
    <Listbox
      value={selected}
      disabled={disabled}
      onChange={(e) => {
        onChange(e)
        if (isValid) setAlert(isValid())
      }}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
          <div className={classNames('relative mt-1', { 'opacity-50': disabled })}>
            <Listbox.Button data-cy={cy} className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primaryLighter focus:outline-none focus:ring-1 focus:ring-primaryLighter sm:text-sm">
              <span className="block truncate">{selected.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options
                className={classNames('absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm', {})}>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) => classNames(
                      active ? 'text-white bg-primaryLight' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )}
                    value={option}>
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                        </span>

                        {selected
                          ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-primaryLight',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            )
                          : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {alert &&
              <p data-cy='error-message' className="mt-2 text-sm text-red-600">
                {alert}
              </p>
            }
            {errorMessage && blur &&
            <p data-cy='error-message' className="mt-2 text-sm text-red-600">
              {errorMessage}
            </p>
            }
          </div>
        </>
      )}
    </Listbox>
  )
}

SelectWithCheckMark.propTypes = {
  label: PropTypes.string,
  cy: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isValid: PropTypes.func,
  errorMessage: PropTypes.string
}

export default SelectWithCheckMark
