import React from 'react'
import PropTypes from 'prop-types'
import {
  flexRender
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

function Table ({ tableInstance, total, page, limit, goToPage, className }) {
  return (
    <div className={classNames('overflow-hidden shadow', className)}>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          {tableInstance.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      className={classNames('px-3 py-3.5 xl:py-4 text-left text-sm font-semibold text-gray-900 first:pl-8 last:pr-8', {
                        'cursor-pointer': false
                      }, header.column.columnDef.className)}>
                      {header.isPlaceholder
                        ? null
                        : (
                          <div onClick={header.column.getToggleSortingHandler()} className='group inline-flex'>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() &&
                            <span className={classNames('ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 transition', {
                              'rotate-180': header.column.getIsSorted() === 'desc'
                            })}>
                              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            }
                          </div>
                          )
                        }
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {tableInstance.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className={classNames('whitespace-nowrap text-sm text-gray-500 hover:bg-slate-50')}>
                {row.getVisibleCells().map(cell => {
                  if (row.original.to) {
                    return (
                      <td key={cell.id} className={classNames('px-3 first:pl-8 last:pr-8', cell.column.columnDef.className)}>
                        <Link to={row.original.to} className='py-4 block'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Link>
                      </td>
                    )
                  }
                  return (
                    <td key={cell.id} className={classNames('whitespace-nowrap px-3 py-4 text-sm text-gray-500 first:pl-8 last:pr-8', cell.column.columnDef.className)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  tableInstance: PropTypes.object.isRequired,
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  goToPage: PropTypes.func,
  className: PropTypes.string
}

export default Table
