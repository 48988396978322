import { makeObservable, observable, action } from 'mobx'
import User from './User'

class Users {
  users = []
  constructor (rootStore, users = []) {
    this.root = rootStore
    this.users = users.map(json => new User(this, json))
    makeObservable(this, {
      users: observable,
      addUserFromJSON: action
    })
  }

  findById = (id) => this.users.find(user => user.id === id)

  addUserFromJSON = (json) => {
    if (Array.isArray(json)) {
      json.forEach(user => {
        if (!this.users.find(u => u.id === user._id)) this.users.push(new User(this, user))
      })
    } else {
      const existingUser = this.users.find(user => user.id === json._id)
      if (!existingUser) {
        this.users.push(new User(this, json))
      }
      return this.findById(json._id)
    }
    return this.users
  }
}

export default Users
