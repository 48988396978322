import { makeObservable, observable } from 'mobx'

import Address from './Address'

class Applicant {
  firstName = ''
  lastName = ''
  middleName = ''
  email = ''
  mobile = ''
  ssn = ''
  dob = ''
  smsOptIn = ''
  married = ''
  annualIncome = ''
  address = new Address()
  billingAddress = new Address()
  employmentType = ''
  employerName = ''
  employerPhone = ''
  employerAddress = new Address()
  spouseFirstName = ''
  spouseLastName = ''
  spouseAddress = new Address()
  constructor (application, json = {}) {
    // this.id = json._id.toString()
    this.application = application
    this.updateFromJSON(json)
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      middleName: observable,
      email: observable,
      mobile: observable,
      ssn: observable,
      dob: observable,
      smsOptIn: observable,
      married: observable,
      billingAddress: observable,
      employmentType: observable,
      employerName: observable,
      employerPhone: observable,
      employerAddress: observable,
      annualIncome: observable,
      address: observable,
      spouseFirstName: observable,
      spouseLastName: observable,
      spouseAddress: observable
    })
  }

  updateFromJSON (json) {
    this.firstName = json.firstName || ''
    this.lastName = json.lastName || ''
    this.middleName = json.middleName || ''
    this.email = json.email || ''
    this.mobile = json.mobile || ''
    this.ssn = json.ssn || ''
    this.dob = json.dob || ''
    this.married = json.married || false
    this.spouseFirstName = json.spouseFirstName || ''
    this.spouseLastName = json.spouseLastName || ''
    this.spouseAddress.updateFromJSON(json.spouseAddress)
    this.smsOptIn = json.smsOptIn === undefined ? true : json.smsOptIn
    this.employmentType = json.employmentType || ''
    this.employerName = json.employerName || ''
    this.employerPhone = json.employerPhone || ''
    this.employerAddress.updateFromJSON(json.employerAddress)
    this.billingAddress.updateFromJSON(json.billingAddress)
    this.address.updateFromJSON(json.address)
    return this
  }

  get fullName () {
    return `${this.firstName} ${this.lastName}`
  }

  get asJSON () {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      middleName: this.middleName,
      email: this.email,
      ssn: this.ssn,
      mobile: this.mobile,
      dob: this.dob,
      married: this.married,
      smsOptIn: this.smsOptIn,
      employerName: this.employerName,
      billingAddress: this.address.asJSON,
      spouseFirstName: this.spouseFirstName,
      spouseLastName: this.spouseLastName,
      spouseAddress: this.spouseAddress,
      address: this.address.asJSON,
      employmentType: this.employmentType,
      annualIncome: this.annualIncome
    }
  }
}

export default Applicant
