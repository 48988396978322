import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function Item ({ label, count, Icon, isActive, hasSubItems }) {
  return (
    <>
      {Icon &&
      <Icon className={classNames('mr-4 md:mr-3 flex-shrink-0 h-6 w-6 text-primaryLightest', { 'text-primaryLight': isActive })} aria-hidden="true" />
      }
      <span className="truncate">{label}</span>

      {count !== undefined &&
      <span className={classNames('bg-gray-100 ml-auto inline-block py-0.5 px-3 text-s md:text-xs rounded-full', { 'bg-white': isActive })}>
        {count}
      </span>
      }
    </>
  )
}

Item.propTypes = {
  Icon: PropTypes.object,
  isActive: PropTypes.bool,
  hasSubItems: PropTypes.bool,
  label: PropTypes.string.isRequired,
  count: PropTypes.number
}
export default Item
