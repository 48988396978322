import React from 'react'
import PropTypes from 'prop-types'

import User from '../../../../stores/User'
import AdvancedDropdown from '../../../elements/dropdowns/Advanced'

function UserDropdown ({ user, linkSections }) {
  return (
    <AdvancedDropdown
      title='Signed in as'
      className='right-4 top-11 mt-2'
      subtitle={user.email}
      sections={linkSections}
    />
  )
}

UserDropdown.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  linkSections: PropTypes.array.isRequired
}

export default UserDropdown
