import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'

import Simple from '../../elements/dropdowns/Simple'

function ButtonDropdown ({ links, cy, buttonLabel }) {
  return (
    <Menu as='div' className='relative' data-cy={cy}>
      <Menu.Button data-cy={`${cy}-button`} className='py-2 px-4 text-sm border border-gray-300 rounded-md hover:bg-gray-50 shadow-sm'>
        {buttonLabel}
      </Menu.Button>
      <Simple
        className='mt-2 cursor-pointer'
        links={links}
      />
    </Menu>
  )
}

ButtonDropdown.propTypes = {
  links: PropTypes.array.isRequired,
  cy: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired
}

export default ButtonDropdown
