import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'

import integrations from '../../../constants/integrations'

const columnHelper = createColumnHelper()

export default function getColumns (property, isMerchantMember) {
  const columns = [
    // Applicants
    columnHelper.accessor('primaryApplicant.fullName', {
      id: 'primaryApplicant.lastName',
      getCanSort: () => false,
      header: () => <span className=''>{property.labels.applicant}</span>,
      cell: (data) => data.row.original.stage === 'prequalification-invite'
        ? <i className='text-gray-900'>{data.row.original.primaryApplicant.email || data.row.original.primaryApplicant.mobile}</i>
        : (
          <span className='flex flex-col'>
            <span className='text-gray-900 font-medium'>{data.getValue()}</span>
            {data.row.original.secondaryApplicant &&
            <span className='text-gray-900 font-medium'>{data.row.original.secondaryApplicant.fullName}</span>
            }
          </span>
          )
    }),

    // Short ID
    columnHelper.accessor('shortID', {
      header: () => <div>ID</div>,
      cell: (data) => <div>{data.getValue()}</div>,
      getCanSort: () => false,
      className: 'hidden xl:table-cell'
    }),

    // Lender
    columnHelper.accessor('suggestedLender', {
      header: () => <span>Lender</span>,
      cell: (data) => data.getValue()
        ? (
          <div className="items-center flex">
            <img src={integrations[data.getValue()].logo} className="w-6 h-6 mr-2 round" />
            <span>{integrations[data.getValue()].name}</span>
          </div>
          )
        : '',
      getCanSort: () => false,
      className: 'hidden lg:table-cell'
    }),

    // Stage
    columnHelper.accessor('stageNiceLabel', {
      header: 'Stage',
      getCanSort: () => false,
      cell: (data) => data.row.original.stage === 'prequalification-invite' ? <i>{data.getValue()}</i> : data.getValue()
    })
  ]
  if (!isMerchantMember) {
    // push at the 3rd position
    columns.splice(2, 0, columnHelper.accessor('merchant.name', {
      header: 'Merchant',
      getCanSort: () => false,
      cell: (data) => data.getValue()
    }))
  }
  return columns
}
