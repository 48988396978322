import React from 'react'
import PropTypes from 'prop-types'

import InputWithLabel from '../inputGroups/InputWithLabel'

function DollarQuestion ({ question, answer, onChange }) {
  return (
    <InputWithLabel name={question.key} label={question.label} value={answer || ''} type='dollar' onChange={e => onChange(e.target.value)} />
  )
}

DollarQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default DollarQuestion
