import React from 'react'
import { Menu } from '@headlessui/react'

import useRootStore from '../../../../hooks/useRootStore'
import UserDropdown from './UserDropdown'

function Header () {
  const rootStore = useRootStore()
  const user = rootStore.currentUser
  return (
    <Menu as='div' className="flex-shrink-0 flex items-center px-4 justify-between">
      <img className="h-8 w-auto" src={rootStore.currentProperty?.logo || '/logo-square.svg'} alt="Workflow" />
      <Menu.Button className='relative'>
        <img className="h-5 w-auto cursor-pointer" src={user.profilePicture || '/img/avatar.svg'} alt={user.displayName} />
      </Menu.Button>
      <UserDropdown
        user={user}
        linkSections={[
          [
            { label: 'Roadmap', href: 'https://feedback.apptracker.dev/' },
            { label: 'Feature Requests', href: 'https://feedback.apptracker.dev/optimus' },
            { label: 'API Documentation', href: 'https://api.apptracker.dev' },
            { label: 'Platform Status', href: 'https://status.apptracker.dev' }
          ],
          [
            { label: 'Logout', href: '/auth/logout' }
          ]
        ]}
      />
    </Menu>
  )
}

Header.propTypes = {}

export default Header
