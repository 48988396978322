import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import LenderApplication from '../LenderApplication'
import LenderApplicationComplete from '../LenderApplicationComplete'
import LenderSelect from './LenderSelect'
import useRootStore from '../../../hooks/useRootStore'

function SelectLender () {
  const { complete, id, merchantSlug } = useParams()
  const rootStore = useRootStore()
  const [error, setError] = useState()
  const [lenderPlans, setLenderPlans] = useState()

  useEffect(() => {
    async function init () {
      try {
        const { application, lenderRelationship, plans } = await rootStore.fetch(`/data/pages/prequalification/${id}`)
        application.lenderRelationship = lenderRelationship
        if (plans) setLenderPlans(plans)
        rootStore.applicationStore.addApplicationFromJSON(application)
        if (['prequalification', 'lender-application'].includes(application.stage)) {
          const app = rootStore.applicationStore.findById(id)
          const { token } = await app.prequalificationPreCheck()
          if (token) {
            app.setAnswer('authorizationToken', token)
          } // @TO-DO add a blocker here
        }
      } catch (error) {
        const errorMessage = await error
        setError(errorMessage.error)
      }
    }
    init()
  }, [rootStore, id])

  if (error) {
    return <div>{error}</div>
  }

  const application = rootStore.applicationStore.findById(id)
  if (!application) return <div>Loading...</div>
  if (application.stage === 'prequalification') {
    return (<LenderSelect plans={lenderPlans} />)
  }
  if (complete || application.stage === 'lender-declined') {
    return <LenderApplicationComplete application={application} />
  }
  if (['lender-application'].includes(application.stage)) {
    return <LenderApplication />
  }
  if (['lender-submitted', 'lender-approved'].includes(application.stage)) {
    if (application.getApplicantEndpoint(merchantSlug)) {
      window.location.href = application.getApplicantEndpoint(merchantSlug)
    } else {
      window.location.href = `/${merchantSlug}/apply/${id}/complete`
    }
    // return <Link to={application.getApplicantEndpoint(merchantSlug)}/>
    return <span>hi</span>
    // todo do this the react-router way
  }
  return (
    <div>?</div>
  )
}

SelectLender.propTypes = {}

export default observer(SelectLender)
