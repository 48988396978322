const apiURL = (env) => {
  return env === 'production' ? 'https://api.greensky.com/partners/merchants/plans' : 'https://api.uat1.greensky.com/partners/merchants/plans'
}

export default async function getMerchantPlans (merchant, integration) {
  // if merchant does not have plans return standard EGIA plans
  const response = await fetch(`${apiURL(process.env.NODE_ENV)}?activationStatus=active`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': merchant?.lenderIDs?.gsky_x_api_key
    }
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
  })
  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()
  if (json?.plans?.length) {
    return json.plans.map(plan => (
      {
        label: plan.planId + ' - ' + plan.description,
        value: plan.planId,
        description: plan.description,
        details: plan.productDescription,
        term: plan.term,
        minInterestRate: plan.minInterestRate,
        maxInterestRate: plan.maxInterestRate,
        maximumLoanAmount: plan.maximumLoanAmount
      }))
  } else {
    const response = await fetch(`${apiURL(process.env.NODE_ENV)}?activationStatus=all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': merchant?.lenderIDs?.gsky_x_api_key
      }
      // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
    })
    if (response.status !== 200) {
      const errorResponse = await response.json()
      return { success: false, error: errorResponse.error }
    }
    const json = await response.json()
    return json.plans.filter(plan => integration?.data?.plans.includes(plan.planId)).map(plan => ({
      label: plan.planId + ' - ' + plan.description,
      value: plan.planId,
      description: plan.description,
      details: plan.productDescription,
      term: plan.term,
      minInterestRate: plan.minInterestRate,
      maxInterestRate: plan.maxInterestRate,
      maximumLoanAmount: plan.maximumLoanAmount
    }))
  }
}
