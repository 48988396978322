import { makeObservable, observable, action } from 'mobx'
import Invite from './Invite'

class Invites {
  invites = []
  constructor (rootStore, invites = []) {
    this.root = rootStore
    this.invites = invites.map(json => new Invite(this, json))
    makeObservable(this, {
      invites: observable,
      addInviteFromJSON: action
    })
  }

  updateFromJSON (json) {
    this.type = json.type || ''
    this.merchant = json.merchant || ''
    this.property = json.property || ''
    this.data = json.data || {}
  }

  addInviteFromJSON = (json) => {
    if (!this.findById(json._id)) {
      this.invites.push(new Invite(this, json))
      return this.invites[this.invites.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }

  removeInviteFromJSON = (id) => {
    const index = this.invites.findIndex(this.invites.find(invite => invite.id === id))
    if (index) {
      this.invites.splice(index, 1)
      return this.invites[this.invites.length - 1]
    }
  }

  emptyStore = () => {
    this.invites = []
    return this.invites
  }

  findById = (id) => this.invites.find(invite => invite.id === id)
  findByMerchantID = id => this.invites.filter(invite => invite.merchant === id)
}

export default Invites
