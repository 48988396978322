import React from 'react'

import General from './General'

function MerchantDetailsSettings () {
  return (
    <div className='py-8'>
      <General />
    </div>
  )
}

MerchantDetailsSettings.propTypes = {}

export default MerchantDetailsSettings
