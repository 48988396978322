import React, { useEffect, useState } from 'react'
import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { UserPlusIcon, TrashIcon } from '@heroicons/react/24/solid'

import useRootStore from '../../../hooks/useRootStore'
import Table from '../../elements/lists/Table'
import Invite from '../../../stores/Invite'
import Badge from '../../elements/badges/Simple'
import Button from '../../elements/buttons/Primary'

const columnHelper = createColumnHelper()
const getColumns = (rootStore) => [
  columnHelper.accessor('id', {
    header: 'Members',
    cell: data => data.row.original instanceof Invite
      ? <span><i>{data.row.original.userInfo ? `${data.row.original.userInfo.firstName} ${data.row.original.userInfo.lastName}` : '-'}</i> <Badge label="Pending Invite" /></span>
      : `${data.row.original.firstName} ${data.row.original.lastName}`
  }),
  columnHelper.accessor('email', {
    header: 'Email'
  }),
  columnHelper.accessor('isAdmin', {
    header: 'Role',
    cell: data => (
      <div
        onClick={() => {
          rootStore.fetch(`/api/properties/${rootStore.currentPropertyID}/set-member-role`, 'POST', { email: data.row.original.email, isAdmin: !data.row.original.isAdmin })
        }}>
        {data.getValue() ? 'Admin' : 'User'}
      </div>
    )
  }),
  columnHelper.accessor('createdAt', {
    header: '',
    cell: data => data.row.original instanceof Invite
      ? <TrashIcon
          className='text-red-700 w-5 h-5 cursor-pointer'
          onClick={() => {
            rootStore.fetch(`/api/properties/${rootStore.currentPropertyID}/delete-invite`, 'POST', { inviteID: data.row.original.id })
            rootStore.inviteStore.invites = rootStore.inviteStore.invites.filter(invite => invite.id !== data.row.original.id)
          }}
        />
      : <TrashIcon
          className='text-red-700 w-5 h-5 cursor-pointer'
          onClick={() => console.log('remove user')}
        />
  })
]

function PropertyMembers () {
  const rootStore = useRootStore()
  // const [data, setData] = useState([])
  const [data, setData] = useState([])
  const table = useReactTable({
    data,
    columns: getColumns(rootStore),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    async function init () {
      const { invites, relationships } = await rootStore.fetch('/data/pages/settings/team')
      setData([
        ...invites.map(rootStore.inviteStore.addInviteFromJSON),
        ...relationships.map(relationship => {
          const user = rootStore.userStore.addUserFromJSON(relationship.user)
          return { firstName: user.firstName, lastName: user.lastName, email: user.email, isAdmin: relationship.isAdmin }
        })
      ])
    }
    init()
  }, [rootStore])

  return (
    <div className='py-6 max-w-7xl mx-auto'>
      <div className='flex justify-between items-center px-4 sm:px-6 md:px-8 mb-8'>
        <div>
          <h1 id="message-heading" className="text-lg font-medium text-gray-900">
            Team Members
          </h1>
          <p className="mt-1 text-sm text-gray-500 truncate">Manage who can access this property, set permissions, and more.</p>
        </div>
        <Button
          className="mb-2 ml-2"
          label='Invite User'
          onClick={() => rootStore.openDialog('inviteUserToProperty')}
          Icon={UserPlusIcon}
        />
      </div>
      <Table tableInstance={table} />
    </div>
  )
}

PropertyMembers.propTypes = {}

export default observer(PropertyMembers)
