const statusMap = {
  reviewing: 'lender-submitted',
  approved: 'lender-approved',
  'approved-with-info': 'lender-approved',
  declined: 'lender-declined',
  funded: 'lender-funded'
}

export default async function convertUpdate (application, relationship, update) {
  console.log(update)
  const prevStage = application.stage
  // Update the Application
  if (statusMap[update.status]) {
    application.stage = statusMap[update.status]
  }

  // update Primary Applicant
  if (update.HO1FirstName) {
    if (!application.primaryApplicant) application.primaryApplicant = {}
    application.primaryApplicant.firstName = update.HO1FirstName
    application.primaryApplicant.lastName = update.HO1LastName
  }
  // update Secondary Applicant
  if (update.HO2FirstName) {
    if (!application.secondaryApplicant) application.secondaryApplicant = {}
    application.secondaryApplicant.firstName = update.HO2FirstName
    application.secondaryApplicant.lastName = update.HO2LastName
  }

  // set approved with statuses
  if (update.approvedWith) {
    relationship.requiredForApproval = update.approvedWith.filter(field => field.decision !== 'approved').map(field => field.needed)
  }

  // Update the lender relationship
  const previousMetadata = { ...relationship.metadata }
  relationship.metadata = {
    ...relationship.metadata,
    serviceType: update.serviceType,
    completeProject: update.completeProject,
    applicantSigned: !!update.applicantSignedOn,
    secondaryApplicantSigned: !!update.secondarySignedOn,
    completionCertificateSent: !!update.completionCertificateSent,
    completedCertificateBy: update.completedCertificateBy,
    approvedWith: update.approvedWith,
    APR: update.APR,
    term: update.term,
    monthlyPayment: update.monthlyPayment,
    expiresOn: update.expiresOn,
    primaryApplicantSigned: !!update.applicantSignedOn
    // equipmentEntered: !!update.equipmentEntered
  }

  if (prevStage === 'lender-submitted' && application.stage === 'lender-approved') {
    relationship.metadata = { ...relationship.metadata, approvedOn: new Date() }
  }
  if (!previousMetadata.documentsCreatedOn && update.docusignEnvelopeId) {
    relationship.metadata = { ...relationship.metadata, documentsCreatedOn: new Date() }
  }
  if (!previousMetadata.primaryApplicantSignedOn && update.applicantSignedOn) {
    relationship.metadata = { ...relationship.metadata, primaryApplicantSignedOn: new Date() }
  }
  if (!previousMetadata.secondaryApplicantSignedOn && update.secondarySignedOn) {
    relationship.metadata = { ...relationship.metadata, secondaryApplicantSignedOn: new Date() }
  }
  await relationship.save()
  // Return the lender update to create a Log
  if (!previousMetadata.applicantSigned && relationship.metadata.applicantSigned) {
    return 'Homeowner 1 signed'
  }
  if (!previousMetadata.secondaryApplicantSigned && relationship.metadata.secondaryApplicantSigned) {
    return 'Homeowner 2 signed'
  }
  if (!previousMetadata.completeProject && relationship.metadata.completeProject) {
    return 'Project completed'
  }
  if (!previousMetadata.completionCertificateSent && relationship.metadata.completionCertificateSent) {
    return 'Completion Certificate Sent'
  }
  if (!previousMetadata.complete && relationship.metadata.completedCertificateBy) {
    return `Completion Certificate Signed by ${relationship.metadata.completedCertificateBy}`
  }
}
