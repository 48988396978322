import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function Simple ({ className, links }) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items className={classNames(className, 'origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none')}>
        <div data-cy='simple-menu' className="py-1">
          {links.map((link, i) => (
            <Menu.Item key={i} data-cy='simple-menu-item'>
              {({ active }) => link.onClick
                ? (
                  <div
                    onClick={link.onClick}
                    className={classNames(
                      (active && !link.disabled) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      link.disabled ? 'text-gray-300' : 'cursor-pointer',
                      'block px-4 py-2 text-sm'
                    )}>
                    {link.label}
                  </div>
                  )
                : (
                  <Link
                    to={link.to}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      link.disabled ? 'text-gray-300' : 'cursor-pointer',
                      'block px-4 py-2 text-sm'
                    )}>
                    {link.label}
                  </Link>
                  )
              }
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  )
}

Simple.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  }))
}
export default Simple
