import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  CogIcon
} from '@heroicons/react/24/outline'

function Footer ({ openHelpSidebar }) {
  return (
    <Link to='/settings/' data-cy='sidebar-footer' className="flex-shrink-0 flex border-t border-primaryDarker p-4 cursor-pointer">
      <div className="flex">
        <CogIcon className='text-primaryLightest group-hover:text-primaryLighter mr-4 md:mr-3 flex-shrink-0 h-6 w-6' />
        <span className='text-white'>Settings</span>
      </div>
    </Link>
  )
}

Footer.propTypes = {
  openHelpSidebar: PropTypes.func.isRequired
}

export default Footer
