import gskyData from '../../gskyData'

export default function convertApplicationData ({ requestAmount, primaryApplicant, secondaryApplicant, ...application }, lenderID) {
  let applicant = {
    applicantFirstName: primaryApplicant.firstName,
    applicantMiddleName: primaryApplicant.middleName,
    applicantLastName: primaryApplicant.lastName,
    applicantDateOfBirth: primaryApplicant.dob,
    applicantMarried: primaryApplicant.married,
    applicantSSN: primaryApplicant.ssn,
    applicantEmailAddress: primaryApplicant.email,
    applicantPhoneNumber: primaryApplicant.mobile,
    applicantCellPhoneNumber: (primaryApplicant.mobile).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    applicantMobileOptIn: primaryApplicant.smsOptIn,
    applicantStreetAddress: primaryApplicant.address && primaryApplicant.address.streetAddress,
    applicantCity: primaryApplicant.address && primaryApplicant.address.city,
    applicantState: primaryApplicant.address && primaryApplicant.address.state,
    applicantZipCode: primaryApplicant.address && primaryApplicant.address.zipCode,
    applicantEmployerName: primaryApplicant.employerName,
    applicantGrossIncome: primaryApplicant.annualIncome
  }
  const hiddenValues = application.hiddenValues || {}
  console.log('DDDD', hiddenValues)
  const fingerPrint = `${hiddenValues.fpBlackBox};${hiddenValues.ioBlackBox}`
  if (primaryApplicant.firstName === 'approve1') applicant = gskyData.approve1
  if (primaryApplicant.firstName === 'approve2') applicant = gskyData.approve2
  if (primaryApplicant.firstName === 'approve3') applicant = gskyData.approve3
  if (primaryApplicant.firstName === 'approve4') applicant = gskyData.approve4
  if (primaryApplicant.firstName === 'approve5') applicant = gskyData.approve5
  if (primaryApplicant.firstName === 'approve6') applicant = gskyData.approve6
  if (primaryApplicant.firstName === 'conditionalApproval1') applicant = gskyData.conditionalApproval1
  if (primaryApplicant.firstName === 'conditionalApproval2') applicant = gskyData.conditionalApproval2
  if (primaryApplicant.firstName === 'conditionalApproval3') applicant = gskyData.conditionalApproval3
  if (primaryApplicant.firstName === 'frozen1') applicant = gskyData.frozen1
  if (primaryApplicant.firstName === 'frozen2') applicant = gskyData.frozen2
  if (primaryApplicant.firstName === 'frozen3') applicant = gskyData.frozen3
  if (primaryApplicant.firstName === 'locked1') applicant = gskyData.locked1
  if (primaryApplicant.firstName === 'locked2') applicant = gskyData.locked2
  if (primaryApplicant.firstName === 'locked3') applicant = gskyData.locked3
  if (primaryApplicant.firstName === 'decline1') applicant = gskyData.decline1
  if (primaryApplicant.firstName === 'decline2') applicant = gskyData.decline2
  if (primaryApplicant.firstName === 'decline3') applicant = gskyData.decline3
  let applicants = { ...applicant }
  if (primaryApplicant.spouseFirstName) {
    const applicantSpouse = {
      applicantSpouseFirstName: primaryApplicant.spouseFirstName,
      applicantSpouseLastName: primaryApplicant.spouseLastName,
      applicantSpouseAddress: primaryApplicant.spouseAddress
    }
    applicants = { ...applicants, ...applicantSpouse }
  }
  if (secondaryApplicant) {
    const secondary = {
      coApplicantFirstName: secondaryApplicant.firstName,
      coApplicantMiddleName: secondaryApplicant.middleName,
      coApplicantLastName: secondaryApplicant.lastName,
      coApplicantDateOfBirth: secondaryApplicant.dob,
      coApplicantMarried: secondaryApplicant.married,
      coApplicantSSN: secondaryApplicant.ssn,
      coApplicantEmailAddress: secondaryApplicant.email,
      coApplicantPhoneNumber: (secondaryApplicant.mobile).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      coApplicantCellPhoneNumber: (secondaryApplicant.mobile).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      coApplicantMobileOptIn: secondaryApplicant.smsOptIn,
      coApplicantStreetAddress: secondaryApplicant.address && secondaryApplicant.address.streetAddress,
      coApplicantCity: secondaryApplicant.address && secondaryApplicant.address.city,
      coApplicantState: secondaryApplicant.address && secondaryApplicant.address.state,
      coApplicantZipCode: secondaryApplicant.address && secondaryApplicant.address.zipCode,
      coApplicantEmployerName: secondaryApplicant.employerName,
      coApplicantGrossIncome: secondaryApplicant.annualIncome && Number(secondaryApplicant.annualIncome).toFixed(2)
    }
    applicants = { ...secondary, ...applicants }

    if (secondaryApplicant.spouseFirstName) {
      const coApplicantSpouse = {
        coApplicantSpouseFirstName: secondaryApplicant.spouseFirstName,
        coApplicantSpouseLastName: secondaryApplicant.spouseLastName,
        coApplicantSpouseAddress: secondaryApplicant.spouseAddress
      }
      applicants = { ...applicants, ...coApplicantSpouse }
    }
  }

  console.log('FINGERPRINT')
  console.log(fingerPrint)
  console.log('FINGERPRINT')
  return ({
    // in the works
    ...applicants,
    requestedLoanAmount: requestAmount,
    program: 'GreenSky Consumer Projects',
    role: 'Merchant',
    channel: 'External-API-OptimusPlus',
    merchantNumber: lenderID,
    planNumber: application?.lenderRelationship?.metadata?.planNumber,
    referral: lenderID + '-' + application?.lenderRelationship?.metadata?.planNumber,
    sc_last_name: 'User TF',
    sc_email: 'optimus2@microf.com',
    sc_phone: '415-555-1212',
    coApplicantIndicator: !!secondaryApplicant,
    fingerPrint
  })
}
