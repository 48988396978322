import { observer } from 'mobx-react-lite'
import { PropTypes } from 'mobx-react'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'

import format from '../../../utils/format'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import integrations from '../../../constants/integrations'
import LenderApplicationOffers from '../LenderApplicationOffers'
import PrimaryButton from '../../elements/buttons/Primary'
import useRootStore from '../../../hooks/useRootStore'

function LenderApplicationStatus ({ offers, setOffers }) {
  const { id } = useParams()
  const rootStore = useRootStore()
  const [recalculating, setRecalculating] = useState()
  const [applying, setApplying] = useState()
  const application = rootStore.applicationStore.findById(id)
  const lender = integrations[application.suggestedLender]
  if (!application) return <div>Loading...</div>
  return (
    <div className="space-y-6 xl:col-start-1 xl:col-span-5">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">{application.getStatusDisplay()}</h2>
          <p className="mt-5 text-xl text-gray-500">
            {application.getStatusDisplayDetails(format.amount(application?.lenderRelationShip?.metadata?.approvedAmount), lender.name)}
          </p>
        </div>
        {application.displayAmount() &&
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-4">
                <dt className="text-lg font-medium text-gray-900 sm:flex justify-around">
                  <span className="sm:leading-10 text-center sm:pr-4">Please enter the exact amount of your project</span>
                  <InputWithLabel
                    name='amount-needed'
                    type='dollar'
                    value={application.amountNeeded}
                    onChange={e => application.setAnswer('amountNeeded', e.target.value)}
                  />
                  <PrimaryButton
                    label={recalculating ? 'Recalculating...' : 'Recalculate'}
                    disabled={recalculating || !application.amountNeeded}
                    onClick={async () => {
                      setRecalculating(true)
                      const { lenderOffers, error } = await application.getRecalculatedOffers()
                      if (error) rootStore.openDialog('lenderError', error)
                      if (lenderOffers) setOffers(lenderOffers)
                      setRecalculating(false)
                    }}
                  />
                </dt>
              </div>
              <div className='sm:col-span-4'>
                {offers && <LenderApplicationOffers offers={offers} />}
              </div>
            </dl>
          </div>
        }
        {application.nextSuggestedLender &&
          <div className="sm:col-span-4 text-center pb-5">
            <p className="mt-5 mb-2 text-xl text-gray-500">Click the button below to apply with another lender.</p>
            <PrimaryButton
              className="m-auto"
              label={applying ? 'Applying...' : 'Apply Now'}
              onClick={async () => {
                setApplying(true)
                const { url } = await application.setNextSuggestedLender()
                setApplying(false)
                if (url) window.location = url
              }}
            />
          </div>
        }
      </div>
    </div>
  )
}

LenderApplicationStatus.propTypes = {
  offers: PropTypes.array,
  setOffers: PropTypes.func
}

export default observer(LenderApplicationStatus)
