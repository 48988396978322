import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function BasicCard ({ children, addPadding, className }) {
  return (
    <div className={classNames('overflow-hidden rounded-lg bg-white shadow print:shadow-none print:border', className, { 'px-4 py-5 sm:p-6': addPadding })}>
      {children}
    </div>
  )
}

BasicCard.propTypes = {
  children: PropTypes.node,
  addPadding: PropTypes.bool,
  className: PropTypes.string
}

export default BasicCard
