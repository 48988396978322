import React from 'react'
import PropTypes from 'prop-types'

import Select from './Select'

// todo label should not be optional, create Select component
function SelectWithLabel ({ label, name, cy, options, disabled, value, onChange, className }) {
  return (
    <div className={className}>
      {label &&
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      }
      <Select cy={cy} name={name} disabled={disabled} value={value} onChange={onChange} options={options} />
    </div>
  )
}

SelectWithLabel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  cy: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}

export default SelectWithLabel
