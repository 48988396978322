import { observer } from 'mobx-react-lite'
import React from 'react'

import ApplicationDetails from '../LenderApplicationComplete/ApplicationDetails'

function LenderApplicationSubmitted () {
  return (
    <div className="space-y-6 xl:col-start-1 xl:col-span-5">
      <div className="px-4 py-5 sm:px-6 w-full">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">Application Submitted!</h2>
        <p className="mt-5 text-xl text-gray-500">
          Your application was successfully submitted. We will contact you after it has been reviewed.
        </p>
      </div>
      <ApplicationDetails />
    </div>
  )
}

LenderApplicationSubmitted.propTypes = {}

export default observer(LenderApplicationSubmitted)
