import React, { useEffect, useState } from 'react'
import { DocumentIcon, HandThumbUpIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import { Helmet } from 'react-helmet'

import useRootStore from '../../../hooks/useRootStore'
import InviteCard from './InviteCard'
import PendingApplications from './PendingApplications'
import StatCard from '../../dataDisplay/stats/StatCard'

function MerchantInbox () {
  const rootStore = useRootStore()
  const [applications, setApplications] = useState([])
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    async function init () {
      const { applications, charts } = await rootStore.fetch('/data/pages/merchant-inbox')
      setApplications(applications.map(rootStore.applicationStore.addApplicationFromJSON))
      setChartData(charts)
    }
    init()
  }, [rootStore])

  return (
    <>
      <Helmet>
        <title>Dashboard {applications.length > 0 ? `(${applications.length})` : ''}</title>
      </Helmet>
      <div className="px-4 sm:px-6 md:px-8 py-6 sm:py-8 flex flex-col">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 mb-8">
          <StatCard
            Icon={DocumentIcon}
            label='Total Applications'
            stat={chartData.currentTotalApps}
            previousStat={chartData.prevTotalApps}
            to='/applications'
          />

          <StatCard
            percentage
            Icon={HandThumbUpIcon}
            label='Approval Rate'
            stat={chartData.currentTotalApps === 0 ? chartData.currentTotalApps : Math.round(chartData.currentTotalApprovedApps / (chartData.currentTotalApprovedApps + chartData.currentTotalDeclinedApps) * 100 * 100) / 100}
            previousStat={chartData.prevTotalApps === 0 ? chartData.prevTotalApps : Math.round(chartData.prevTotalApprovedApps / (chartData.prevTotalApprovedApps + chartData.prevTotalDeclinedApps) * 100 * 100) / 100}
            to='/applications?stage=lender-approved|lender-funded'
          />
          <StatCard
            dollar
            Icon={BanknotesIcon}
            label='Total Funded'
            stat={0}
            previousStat={0}
            to='/applications?stage=lender-funded'
          />
          <InviteCard />
        </dl>
        <PendingApplications applications={applications} />
      </div>
    </>
  )
}

MerchantInbox.propTypes = {}

export default MerchantInbox
