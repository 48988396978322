import classNames from 'classnames'
import RawInformation from '../InformationBubble/RawInformation'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function InputWithLabel ({ className, cy, disabled, errorMessage, helpText, Icon, inputWidth, disclosure, label, name, getErrorMessage, onChange, placeholder, type, value }) {
  const [blur, setBlur] = useState(false)
  return (
    <div className={className}>
      <div>
        {label &&
          <label htmlFor={name} className='text-sm font-medium text-gray-700 mb-1'>
            <span className='w-full'>{label}</span>
            {disclosure &&
              <RawInformation message={disclosure} />
            }
          </label>
        }
        <div className="relative">
          {type === 'dollar' &&
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          }
          {type === 'dollar'
            ? (
              <NumberFormat
                thousandSeparator
                id={name}
                name={name}
                data-cy={cy}
                placeholder={placeholder}
                className={classNames('shadow-sm focus:ring-primaryLighter focus:border-primaryLighter block sm:text-sm border-gray-300 rounded-md pl-7')}
                style={{ maxWidth: inputWidth || '100%' }}
                decimalScale={2}
                value={value}
                onValueChange={({ value }) => onChange({ target: { value } })}
                onFocus={() => setBlur(false)}
                onBlur={() => {
                  setBlur(true)
                }}
              />
              )
            : ['ssn', 'phone', 'day'].includes(type)
                ? (
                  <InputMask
                    id={name}
                    name={name}
                    type='text'
                    data-cy={cy}
                    mask={type === 'ssn' ? '999-99-9999' : type === 'phone' ? '+1 999-999-9999' : '99/99/9999'}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setBlur(false)}
                    onBlur={() => {
                      setBlur(true)
                    }}
                    className={classNames(`shadow-sm focus:ring-primaryLighter focus:border-primaryLighter block sm:text-sm border-gray-300 rounded-md w-full max-w-${inputWidth ? `[${inputWidth}px]` : 'full'}`, { 'pl-10': !!Icon })}
                  />
                  )
                : (
                  <input
                    id={name}
                    type={type}
                    name={name}
                    data-cy={cy}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    className={classNames(`shadow-sm focus:ring-primaryLighter focus:border-primaryLighter block sm:text-sm border-gray-300 rounded-md w-full max-w-${inputWidth ? `[${inputWidth}px]` : 'full'}`, { 'pl-10': !!Icon })}
                    placeholder={placeholder}
                    onFocus={() => {
                      setBlur(false)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Tab') {
                        setBlur(true)
                      }
                    }}
                    onBlur={() => {
                      setBlur(true)
                    }}
                  />
                  )
          }

          {(Icon && type !== 'dollar') &&
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        }
        </div>
      </div>
      {errorMessage && blur &&
      <p data-cy='error-message' className="mt-2 text-sm text-red-600">
        {errorMessage}
      </p>
      }
      {helpText &&
      <p className="mt-2 text-sm text-gray-500" id="email-description">
        {helpText}
      </p>
      }
    </div>
  )
}

InputWithLabel.propTypes = {
  className: PropTypes.string,
  cy: PropTypes.string,
  disabled: PropTypes.bool,
  disclosure: PropTypes.string,
  errorMessage: PropTypes.string,
  getErrorMessage: PropTypes.func,
  helpText: PropTypes.string,
  Icon: PropTypes.object,
  inputWidth: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
}
InputWithLabel.defaultProps = {
  type: 'text'
}

export default InputWithLabel
