import { observer } from 'mobx-react'
import React from 'react'

import useRootStore from '../../../hooks/useRootStore'
import Toggle from '../../forms/toggles/Simple'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import SelectWithLabel from '../../forms/SelectMenus/SelectWithLabel'
import Alert from '../../feedback/alerts/Simple'

function PropertyAlerts () {
  const rootStore = useRootStore()
  return (
    <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8'>
      {/* Header */}
      <div className='flex justify-between items-center'>
        <div>
          <h1 id="message-heading" className="text-lg font-medium text-gray-900">
            Alerts & Banners
          </h1>
          <p className="mt-1 text-sm text-gray-500 truncate">
            Set alerts for your property and customize banners.
          </p>
        </div>
      </div>

      <div className='flex items-center mt-8'>
        <h3 className="text-md font-medium text-gray-900 mr-4">
          Alert
        </h3>
        <div className='text-xs text-gray-600 mr-1'>Enabled: </div>
        <Toggle
          label='Enabled'
          enabled={!!rootStore.currentProperty.alert.enabled}
          setEnabled={enabled => {
            rootStore.currentProperty.alert.enabled = enabled
            rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-alert`, 'POST', { ...rootStore.currentProperty.alert, enabled })
          }}
        />
      </div>
      <div className='flex space-x-4 mt-4 mb-2'>
        <SelectWithLabel
          label='Style'
          value={rootStore.currentProperty.alert.type || 'info'}
          onChange={e => {
            rootStore.currentProperty.alert.type = e.target.value
            rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-alert`, 'POST', { ...rootStore.currentProperty.alert, type: e.target.value })
          }}
          options={[
            { label: 'Info', value: 'info' },
            { label: 'Warning', value: 'warning' }
          ]}
        />
        <InputGroup
          className='flex-1'
          label='Alert Title'
          value={rootStore.currentProperty.alert.label || ''}
          onChange={e => {
            rootStore.currentProperty.alert.label = e.target.value
            rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-alert`, 'POST', { ...rootStore.currentProperty.alert, label: e.target.value })
          }}
        />
      </div>
      <InputGroup
        label='Alert Message'
        value={rootStore.currentProperty.alert.description || ''}
        onChange={e => {
          rootStore.currentProperty.alert.description = e.target.value
          rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-alert`, 'POST', { ...rootStore.currentProperty.alert, description: e.target.value })
        }}
      />
      <div className='text-gray-800 mt-4'>Preview</div>
      <Alert
        type={rootStore.currentProperty.alert.type || 'info'}
        label={rootStore.currentProperty.alert.label || ''}
        description={rootStore.currentProperty.alert.description || ''}
      />
    </div>
  )
}

PropertyAlerts.propTypes = {}

export default observer(PropertyAlerts)
