import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { ClipboardIcon } from '@heroicons/react/24/outline'

import useRootStore from '../../../hooks/useRootStore'
import Card from '../../layout/panels/BasicCard'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import Button from '../../elements/buttons/Primary'
import validate from '../../../utils/validate'

function InviteCard () {
  const rootStore = useRootStore()
  const [contact, setContact] = useState('')
  const [inviting, setInviting] = useState(false)
  return (
    <Card addPadding className='items-center flex flex-col sm:col-span-2 md:col-span-1 lg:col-span-3 2xl:col-span-1'>
      <h3 className="text-lg font-medium text-gray-900 mb-2 text-center">Send your company&apos;s {rootStore.currentProperty.name} link</h3>

      <div className="flex space-x-4 w-full max-w-xl">
        <InputGroup
          className='w-full'
          placeholder='example@gmail.com'
          name='contact'
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />

        <Button
          label='Send'
          disabled={!validate.email(contact) && !validate.phone(contact)}
          onClick={async () => {
            if (inviting) return
            setInviting(true)
            await rootStore.fetch('/api/applications/prequalification-invite', 'POST', { contact })
            setInviting(false)
            setContact('')
            toast.success('Your link was sent!')
          }}
        />
      </div>
      <span
        className='flex flex-col items-center text-xs cursor-pointer mt-3 text-gray-500 select-none'
        onClick={() => {
          navigator.clipboard.writeText(rootStore.currentMerchant.applicationLink)
          toast.success('The application link has been copied to your clipboard!')
        }}>
        <span>or, share your direct link:</span>
        <div className='flex items-center sm:ml-2'>
          <span className='text-blue-700'>{rootStore.currentMerchant.applicationLink}</span>
          <ClipboardIcon className='h-4 w-4 ml-1 text-blue-700' />
        </div>
      </span>
    </Card>
  )
}

InviteCard.propTypes = {}

export default InviteCard
