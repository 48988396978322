import React from 'react'
import PropTypes from 'prop-types'
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import ArrayQuestionModal from './ArrayQuestionModal'
import Button from '../../elements/buttons/Primary'
function ArrayQuestion ({ question, answer = [], onChange, application }) {
  const [adding, setAdding] = React.useState(false)
  return (
    <>
      <div>
        <div className='flex items-end justify-between'>
          <div className='block text-sm font-medium text-gray-700'>{question.label}</div>
          <Button
            secondary={!!answer.length}
            size='sm'
            className=''
            Icon={PlusIcon}
            label={question.addLabel || 'Add item'}
            onClick={() => setAdding(true)}
          />
        </div>

        <div className='-mx-4 mt-2 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg overflow-hidden flex flex-col items-center'>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {question.questions.filter(q => !q.hideInPreview).map((question) => (
                  <th key={question.key} scope="col" className={`py-2 pl-3 pr-3 text-left text-sm font-semibold text-gray-${answer.length ? '900' : '500'}`}>
                    {question.shortLabel || question.key}
                  </th>
                ))}
                <th />
              </tr>
            </thead>
            <tbody>
              {answer.map((item, i) => {
                return (
                  <tr key={i}>
                    {question.questions.filter(q => !q.hideInPreview).map((question) => (
                      <td
                        key={question.key}
                        className={classNames(
                          !i ? '' : 'border-t border-transparent',
                          'relative py-4 pl-3 sm:pl-3 pr-3 text-sm'
                        )}>
                        {`${(item[question.key] || '').substring(0, 20)}${(item[question.key] || '').length > 20 ? '...' : ''}`}
                      </td>
                    ))}
                    <td>
                      <XMarkIcon
                        className='w-4 h-4 text-red-600 cursor-pointer'
                        onClick={() => {
                          onChange(answer.filter((_, j) => j !== i))
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {!answer.length &&
          <div className='text-sm text-gray-400 italic py-16 bg-gray-100 w-full text-center border-t px-8'>{question.emptyMessage || 'No items added'}</div>
          }
        </div>
      </div>
      <ArrayQuestionModal
        isOpen={adding}
        application={application}
        question={question}
        onClose={() => setAdding(false)}
        onAdd={item => {
          onChange([...answer, item])
          setAdding(false)
        }}
      />
    </>
  )
}

ArrayQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired
}

export default ArrayQuestion
