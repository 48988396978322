import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import PlacesAutocomplete from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import RawInformation from '../InformationBubble/RawInformation'
import Select from '../SelectMenus/Select'

function InputsWithSharedBorders ({ label, name, inputs, type, disclosure, canPopulate, populateAddress, canBeSameAsLabel, sameAsValue }) {
  const [blur, setBlur] = useState(!!inputs.find(input => !!input.value))

  return (
    <fieldset>
      {label &&
        <span className="flex justify-between w-full">
          <span className="w-full">
            <span className="flex justify-between">
              <legend className="block text-sm font-medium text-gray-700 w-full">{label}</legend>
              {canBeSameAsLabel &&
                <div className="flex h-5 items-center w-full justify-end">
                  <input
                    id={`populate-${name}`}
                    type="checkbox"
                    data-cy={`${name}-same-as`}
                    className="h-4 w-4 rounded border-gray-300 text-primaryLight focus:ring-primaryLighter"
                    checked={inputs.every(question => question.value === sameAsValue[question.name])}
                    onChange={(e) => {
                      for (const input of inputs) {
                        input.onChange({ target: { value: sameAsValue[input.name] } })
                      }
                    }}
                  />
                  <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">
                      {canBeSameAsLabel}
                    </label>
                  </div>
                </div>
              }
            </span>
            {disclosure &&
              <RawInformation message={disclosure} />
            }
          </span>
        </span>
      }
      <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
        {inputs.map((input, i) => (
          <div key={input.name} className='relative'>
            <label htmlFor={input.name} className="sr-only">{input.label}</label>
            {!['option', 'places'].includes(input.type) &&
              <input
                type={input.type || 'text'}
                name={input.name}
                data-cy={input.cy}
                disabled={!!input.disabled}
                id={input.name}
                autoComplete={input.autoComplete}
                className={classNames('focus:ring-primaryLighter focus:border-primaryLighter relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300', {
                  'rounded-t-md': i === 0,
                  'rounded-b-md': i === inputs.length - 1,
                  'pl-10': !!input.Icon
                })}
                placeholder={input.placeholder}
                value={input.value}
                onChange={input.onChange}
                onFocus={() => setBlur(false)}
                onBlur={() => {
                  setBlur(true)
                }}
              />
            }
            {input.type === 'option' &&
              <Select
                type={input.type || 'text'}
                options={input.options}
                cy={input.cy}
                className={classNames('mt-0 focus:ring-primaryLighter focus:border-primaryLighter relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300', {
                  'rounded-t-md': i === 0,
                  'rounded-b-md': i === inputs.length - 1,
                  'pl-10': !!input.Icon
                })}
                name={input.name}
                value={input.value}
                onChange={input.onChange}
              />
            }

            {input.type === 'places' &&
            <PlacesAutocomplete
              value={input.value}
              googleCallbackName='initAutocomplete'
              onChange={v => input.onChange({ target: { value: v } })}
              onSelect={input.onSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="relative">
                  <input
                    {...getInputProps({
                      name: input.name,
                      'data-cy': input.cy,
                      placeholder: 'Street Address',
                      className: classNames('focus:ring-primaryLighter focus:border-primaryLighter relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300', {
                        'rounded-t-md': i === 0,
                        'rounded-b-md': i === inputs.length - 1,
                        'pl-10': !!input.Icon
                      })
                    })}
                  />
                  {suggestions.length > 0 && (
                  <div className="bg-white absolute z-10 w-full shadow">
                    {suggestions.map((suggestion, i) => {
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' }
                      return (
                        <div
                          key={i}
                          {...getSuggestionItemProps(suggestion, {
                            className: 'py-2 px-4',
                            style
                          })}>
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            }

            {input.Icon &&
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
              <input.Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            }
          </div>
        ))}
      </div>
      {blur && !!inputs.find(i => i.isValid && !i.isValid(i.value)) && (
        <div className='mt-2 text-sm text-red-600'>{inputs.find(i => i.isValid && !i.isValid(i.value)).errorMessage || 'Please fill out this form correctly'}</div>
      )}
    </fieldset>
  )
}

InputsWithSharedBorders.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  canPopulate: PropTypes.bool,
  populateAddress: PropTypes.func,
  disclosure: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    Icon: PropTypes.object,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isValid: PropTypes.func
  })),
  canBeSameAsLabel: PropTypes.string,
  sameAsValue: PropTypes.string
}

export default observer(InputsWithSharedBorders)
