import { makeObservable, observable } from 'mobx'

class Address {
  streetAddress = ''
  streetAddress2 = ''
  city = ''
  state = ''
  zipCode = ''
  formattedAddress = ''
  url = ''

  constructor () {
    makeObservable(this, {
      streetAddress: observable,
      streetAddress2: observable,
      city: observable,
      state: observable,
      zipCode: observable,
      formattedAddress: observable,
      url: observable
    })
  }

  updateFromJSON (json = {}) {
    this.streetAddress = json.streetAddress || ''
    this.streetAddress2 = json.streetAddress2 || ''
    this.city = json.city || ''
    this.state = json.state || ''
    this.zipCode = json.zipCode || ''
    this.formattedAddress = json.formattedAddress
    this.url = json.url
    return this
  }

  get asJSON () {
    return {
      streetAddress: this.streetAddress,
      streetAddress2: this.streetAddress2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      zip: this.zipCode // FTL takes zip
    }
  }
}

export default Address
