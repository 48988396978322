import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useRootStore from '../../../hooks/useRootStore'
import ItemPlaceholder from '../LoadingForm/ItemPlaceholder'
import SelectWithCheckMark from '../SelectMenus/SelectWithCheckMark'

function DropdownQuestion ({ application, question, dependency, answer, onChange }) {
  const [loading, setLoading] = useState(typeof question.options === 'function')
  const [options, setOptions] = useState(typeof question.options === 'function' ? [] : question.options)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const rootStore = useRootStore()
  useEffect(() => {
    if (typeof question.options === 'function' && (!question.keyDependency || !!dependency)) {
      // todo - get lender config object for this project and use that apiKey
      question.options(application, { apiKey: 'a4746e62-b3d6-4413-a48b-4221b23ebb05' }, dependency, rootStore.environment).then(options => {
        setOptions(options)
        setSelectedOption(options[0])
        setLoading(false)
      })
    }
  }, [application, question, dependency, rootStore])

  if (loading) {
    if (question.keyDependency && !dependency) {
      return (
        <SelectWithCheckMark
          disabled
          label={question?.label}
          options={[
            { label: 'Select a distributor first', value: '' }
          ]}
          value={{ label: 'Select a distributor first', value: '' }}
          onChange={a => {}}
        />
      )
    }
    return <ItemPlaceholder />
  }
  return (
    <SelectWithCheckMark
      disabled={question.keyDependency && !dependency}
      label={question?.label}
      options={options}
      value={selectedOption}
      onChange={a => {
        setSelectedOption(a)
        onChange(a.value)
      }}
    />
  )
}

DropdownQuestion.propTypes = {
  application: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  dependency: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default DropdownQuestion
