const statusMap = {
  Pending: 'lender-submitted',
  Approved: 'lender-approved',
  Assigned: 'lender-approved',
  Declined: 'lender-declined'
}

export default async function convertUpdate (application, relationship, update) {
  const prevStage = application.stage
  // Update the Application
  const newStatus = relationship?.metadata?.lenderStatus?.state
  if (statusMap[newStatus] && prevStage !== statusMap[newStatus]) {
    application.stage = statusMap[newStatus]
  }
}
