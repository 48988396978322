export default {
  PREQUALIFICATION_INVITE: 'prequalification-invite',
  INTEGRATION_FAILED: 'integration-failed',
  LENDER_OFFERED: 'lender-offered',
  LENDER_SUBMITTED: 'lender-submitted',
  LENDER_APPROVED: 'lender-approved',
  LENDER_DECLINED: 'lender-declined',
  LENDER_ACTION_SUBMITTED: 'lender-action-submitted',
  LENDER_ACTIVITY: 'lender-activity'
}
