import React from 'react'
import PropTypes from 'prop-types'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { Menu } from '@headlessui/react'

import useRootStore from '../../../hooks/useRootStore'
import UserDropdown from '../MainWrapper/Sidebar/UserDropdown'

function MobileHeader ({ setSidebarOpen }) {
  const rootStore = useRootStore()
  return (
    <div className="sticky top-0 z-10 md:hidden pl-1 pr-4 py-1 sm:pl-3 sm:py-2 bg-white flex items-center justify-between print:hidden">
      <button
        type="button"
        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryLight"
        onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Menu>
        <Menu.Button className='relative rounded-full overflow-hidden'>
          <img className="h-6 w-auto cursor-pointer" src={rootStore.currentUser.profilePicture || '/img/avatar-dark.svg'} alt={rootStore.currentUser.displayName} />
        </Menu.Button>
        <UserDropdown
          user={rootStore.currentUser}
          linkSections={[
            [
              { label: 'Roadmap', href: 'https://feedback.apptracker.dev/' },
              { label: 'Feature Requests', href: 'https://feedback.apptracker.dev/optimus' }
            ],
            [
              { label: 'Logout', href: '/auth/logout' }
            ]
          ]}
        />
      </Menu>
    </div>
  )
}

MobileHeader.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired
}

export default MobileHeader
