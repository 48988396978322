const applicationDetails = {
  name: 'Application Details',
  key: 'app-details',
  number: '01',
  required: () => true,
  isRemovable: () => false,
  startRemoved: () => false,
  subsections: [
    {
      questions: [
        [
          {
            label: 'What are you financing?',
            key: 'lenderMetadata.service',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Service, Repair, or Materials', value: 'service' },
              { label: 'Equipment Install', value: 'equipment' }
            ],
            getErrorMessage: () => false
          },
          {
            keyDependency: 'lenderMetadata.service',
            showIfDependencyEquals: 'equipment',
            key: 'lenderMetadata.subEquipment',
            label: 'What type of equipment is being financed?',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'HVAC - New Air Conditioner', value: 'HVAC - New Air Conditioner' },
              { label: 'HVAC - New Heat Pump', value: 'HVAC - New Heat Pump' },
              { label: 'HVAC - New Furnace', value: 'HVAC - New Furnace' },
              { label: 'HVAC - New Mini-Split', value: 'HVAC - New Mini-Split' },
              { label: 'Electric Work with a Panel', value: 'Electric Work with a Panel' },
              { label: 'Well Pump/Pressure Tank', value: 'Well Pump/Pressure Tank' },
              { label: 'Water Heater', value: 'Water Heater' },
              { label: 'Water Filtration System', value: 'Water Filtration System' },
              { label: 'Garage Door with Motor', value: 'Garage Door with Motor' },
              { label: 'Appliance', value: 'Appliance' },
              { label: 'Solar Panels', value: 'Solar Panels' },
              { label: 'Geothermal', value: 'Geothermal' },
              { label: 'Other', value: 'Other' }
            ]
          },
          {
            keyDependency: 'lenderMetadata.subEquipment',
            showIfDependencyEquals: 'Other',
            key: 'lenderMetadata.subEquipmentOther',
            label: 'Please describe the equipment being financed',
            type: 'text'
          },
          {
            keyDependency: 'lenderMetadata.service',
            showIfDependencyEquals: 'service',
            key: 'lenderMetadata.subService',
            label: 'What is being serviced?',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'A/C Repair', value: 'A/C Repair' },
              { label: 'Garage Door Only', value: 'Garage Door Only' },
              { label: 'Fence', value: 'Fence' },
              { label: 'Roof', value: 'Roof' },
              { label: 'Deck', value: 'Deck' },
              { label: 'Electric No Panel', value: 'Electric No Panel' },
              { label: 'Plumbing', value: 'Plumbing' },
              { label: 'Other', value: 'Other' }
            ]
          },
          {
            keyDependency: 'lenderMetadata.subService',
            showIfDependencyEquals: 'Other',
            key: 'lenderMetadata.subServiceOther',
            label: 'Please describe the service being performed',
            type: 'text'
          },
          {
            label: 'How much do you want to borrow?',
            key: 'requestAmount',
            type: 'dollar',
            inputWidth: 160,
            required: true,
            keyDependency: 'lenderMetadata.service',
            getErrorMessage: ({ dependencyValue, answer }) => {
              const min = 300
              const max = dependencyValue === 'service' ? 15_000 : 35_000
              if (Number.isNaN(answer)) return 'Invalid value.'
              if (answer < min) return `Minimum amount is $${min.toLocaleString('en-US')}. Please select a higher amount.`
              if (answer > max) return `Maximum amount is $${max.toLocaleString('en-US')}. Please select a lower amount.`
            }
          },
          {
            label: 'Where will this project be completed?',
            key: 'installationAddress',
            type: 'address',
            required: true,
            note: 'You must be the homeowner of this address.'
          },
          {
            label: 'Do you own this property?',
            key: 'propertyOwner',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Yes', value: 'y' },
              { label: 'No', value: 'n' }
            ],
            getErrorMessage: ({ answer }) => {
              if (answer === 'n') return 'You must own the property to create an application'
              return false
            }
          }
        ]
      ]
    }
  ]
}

export default applicationDetails
