import React from 'react'
import PropTypes from 'prop-types'

function Header ({ logo, merchant }) {
  return (
    <div className='w-full bg-white border-b border-gray-200 h-16 flex items-center px-3 md:px-6 shrink-0'>
      <img className="h-6 w-auto" src={logo || '/logo-square.svg'} />
      {merchant && (
        <span className='font-medium ml-4'>{merchant.name}</span>
      )}
    </div>
  )
}

Header.propTypes = {
  logo: PropTypes.string,
  merchant: PropTypes.object
}

export default Header
