import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'

import RootStore from './stores/RootStore'
import App from './components/wrappers/App'

const mountNode = document.querySelector('#app')
const root = createRoot(mountNode)

const initialState = JSON.parse(document.querySelector('#is').innerHTML.replace(/&quot;/g, '"').replace(/&#x27;/g, '\''))

Sentry.init({
  dsn: initialState.sentryDSN,
  environment: initialState.sentryEnvironment,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    )
  })],
  tracesSampleRate: 1
})

const rootStore = new RootStore(initialState)
root.render((
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <App rootStore={rootStore} />
  </Sentry.ErrorBoundary>
))
