import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function StatCard ({ Icon, label, stat, previousStat, to, percentage, dollar }) {
  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
      <dt>
        <div className="absolute rounded-md bg-primary p-3">
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">{label}</p>
      </dt>

      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{dollar ? '$' : ''}{stat}{percentage ? '%' : ''}</p>
        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm">
            <Link to={to} className="font-medium text-primaryDark hover:text-primary">
              {' '}
              View all
            </Link>
          </div>
        </div>
      </dd>
    </div>
  )
}

StatCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  stat: PropTypes.number.isRequired,
  previousStat: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  percentage: PropTypes.bool,
  dollar: PropTypes.bool
}

export default StatCard
