import { observer } from 'mobx-react-lite'
import { PropTypes } from 'mobx-react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import React, { useState } from 'react'

import format from '../../../utils/format'
import useRootStore from '../../../hooks/useRootStore'
import PrimaryButton from '../../elements/buttons/Primary'

function LenderApplicationOffers ({ offers }) {
  const { id } = useParams()
  const rootStore = useRootStore()
  const application = rootStore.applicationStore.findById(id)
  const [offerDetails, setOfferDetails] = useState()
  if (!application) return <div>Loading...</div>
  return (
    <>
      {offers.map((offer, i) => (
        <div key={i} className={classNames('divide-y divide-gray-200 rounded-3xl shadow-sm', (offerDetails && offerDetails.id === offer.id) ? 'border-4 border-primaryLighter' : 'border border-gray-200')}>
          <div className="sm:flex rounded-">
            <div className="sm:w-3/4 m-1.5 pl-7">
              <div className="sm:flex sm:justify-around ">
                <p className="mt-4 sm:w-1/2">
                  <span className="text-lg font-bold tracking-tight text-gray-900 pr-6">Loan Amount</span>
                  <span className="text-lg font-medium text-gray-500">{format.amount(offer.loanAmount)}</span>
                </p>
                <p className="mt-4 sm:w-1/2">
                  <span className="font-bold tracking-tight text-lg text-gray-900 pr-6">Monthly Payment</span>
                  <span className="text-lg font-medium text-gray-500">{format.amount(offer.monthlyPayment)}</span>
                </p>
              </div>
              <div className="sm:flex sm:justify-around">
                <p className="mt-4 sm:w-1/2">
                  <span className="text-lg font-bold tracking-tight text-gray-900 pr-6">Interest Rate</span>
                  <span className="text-lg font-medium text-gray-500">{offer.apr}%</span>
                </p>
                <p className="mt-4 sm:w-1/2">
                  <span className="text-lg font-bold tracking-tight text-gray-900 pr-6">Term</span>
                  <span className="text-lg font-medium text-gray-500">{offer.term} Months</span>
                </p>
              </div>
            </div>
            <div className="sm:w-1/5 p-1">
              <PrimaryButton
                className="mt-8 block w-full rounded-lg border border-transparent py-2 text-center text-sm font-semibold"
                label='Select'
                onClick={() => {
                  // application.setAnswer('selectedOffer', offer.id)
                  // setSelectedOffer(offer.id)
                  setOfferDetails(offer)
                }
                }
              />
            </div>
          </div>
          <div className="px-6 pt-6 pb-8">
            <span className="text-xs text-gray-500" style={{ fontSize: '0.6rem', fontWeight: 500 }}>{offer.promotionDetails}</span>
          </div>
        </div>))}
      <div className="flex">
        <PrimaryButton
          className={classNames(!offerDetails ? 'text-gray-500 bg-gray-300' : '', 'mt-8 block w-full sm:w-auto rounded-lg border border-transparent py-2 text-center text-sm font-semibold')}
          label='Accept Offer'
          disabled={!offerDetails}
          onClick={async () => {
            const { updatedApplication, error, lenderRelationShip } = await application.createContract({ selectedOffer: offerDetails.id, amountNeeded: application.amountNeeded, offer: offerDetails })
            if (error) {
              console.log(error, 'ERROR!')
              rootStore.openDialog('lenderError', error)
            }
            if (lenderRelationShip) {
              application.lenderRelationShip = lenderRelationShip
              application.updateFromJSON(application)
            }
            if (updatedApplication) application.updateFromJSON(updatedApplication)
          }}
        />
      </div>
    </>
  )
}

LenderApplicationOffers.propTypes = {
  offers: PropTypes.array
}

export default observer(LenderApplicationOffers)
