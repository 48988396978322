const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/create' : 'https://api1.uat.greensky.com/create'
}

export default async function createContract (application, config, data, body) {
  const offerDetails = body.offer
  const response = await fetch(apiURL(process.env.NODE_ENV), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey,
      token: data.metadata.token
    },
    body: JSON.stringify({
      role: 'Merchant',
      program: 'GreenSky Consumer Projects',
      product: 'Installment',
      offerId: body.selectedOffer,
      applicationId: data.id,
      authorizedLoanAmount: body.amountNeeded,
      activationChannel: 'External-API-OptimusPlus'
    })
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
  })

  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()
  if (json.error) return { error: typeof json.error === 'object' ? json.error.message : json.error }
  data.metadata = { ...data.metadata, lenderLoanCreated: true, actualRequestedAmount: body.amountNeeded, merchantFee: offerDetails.merchantFee, term: offerDetails.term, monthlyPayment: offerDetails.monthlyPayment, APR: offerDetails.apr }
  await data.save()
  return { success: true, error: json.error ? json.error.message : false }
}
