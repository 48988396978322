import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, Outlet } from 'react-router-dom'

import useRootStore from '../../../hooks/useRootStore'
import Header from '../../headings/pageHeadings/Standard'
import TabsWithUnderline from '../../navigation/tabs/TabsWithUnderline'

function MerchantDetails () {
  const rootStore = useRootStore()
  const { id } = useParams()

  useEffect(() => {
    async function init () {
      const { merchant } = await rootStore.fetch(`/data/pages/merchant/${id}`)
      rootStore.merchantStore.addMerchantFromJSON(merchant)
    }
    init()
  }, [rootStore, id])

  const merchant = rootStore.merchantStore.findById(id)
  if (!merchant) {
    return <div>Loading...</div>
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header
        pages={[
          { label: 'Merchants', current: false, to: '/merchants' },
          { label: merchant.name, current: true, to: `/merchants/${merchant.id}` }
        ]}
      />

      <div className="bg-white px-8 flex-1">
        <TabsWithUnderline
          tabs={[
            { name: 'Overview', to: `/merchants/${merchant.id}` },
            { name: 'Members', to: 'members' },
            { name: 'Settings', to: 'settings' }
          ]}
        />
        <Outlet />
      </div>
    </div>
  )
}
export default observer(MerchantDetails)
