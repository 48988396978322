import convertApplicationData from './convertApplicationData'
const apiURL = () => {
  return process.env.NODE_ENV === 'production' ? 'https://beta.apptracker.ftlfinance.com' : 'https://staging-apptracker.herokuapp.com'
}

export default async function submitPrequalification (application, config, data, lenderID) {
  if (data.secondaryApplicant && !data.secondaryApplicant.firstName) data.secondaryApplicant = null
  const response = await fetch(`${apiURL()}/api/v1/applications?dealer_id=${lenderID}&api_key=${config.submitApiKey}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`),
    body: JSON.stringify(convertApplicationData(data))
  })
  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()

  return {
    success: true,
    id: json.id,
    metadata: {},
    completeURL: `${apiURL()}/complete/${json.inviteID}`
  }
}
