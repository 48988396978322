import convertUpdate from './convertUpdate'

const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/state' : 'https://api1.uat.greensky.com/state'
}

export default async function getApplicationStatusUpdate (application, config, data) {
  const response = await fetch(`${apiURL(process.env.NODE_ENV)}?program=GreenSky+Consumer+Projects&channel=External-API-OptimusPlus&role=Merchant&applicationId=${data.id}`, {
    method: 'GET',
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`),
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey,
      token: data?.metadata?.token
    }
  })
  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }
  const json = await response.json()
  console.log(json, 'json gsky')
  if (json.error) return { success: false, error: json.error.message }
  const lenderStatus = {
    state: json.applicationState,
    status: json.applicationStatus,
    offer: !!(json.nextState === 'Offer'),
    hold: !!(json.nextState === 'Decision'),
    loan: !!(json.nextState === 'Loan Agreement'),
    pendingReason: json.pendType
  }
  convertUpdate(application, data)
  data.metadata = { ...data.metadata, lenderStatus }
  await data.save()
  await application.save()
  return { success: true, error: json.error ? json.error.message : false }
}
