import React from 'react'
import PropTypes from 'prop-types'

import NavItem from './NavItem'

function PrimaryNavigation ({ links }) {
  return (
    <nav className="space-y-1" data-cy='primary-navigation'>
      {links.map((link) => <NavItem key={link.name} to={link.href} label={link.name} Icon={link.icon} subItems={link.subLinks} />)}
    </nav>
  )
}

PrimaryNavigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    current: PropTypes.bool,
    icon: PropTypes.elementType.isRequired,
    subLink: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    }))
  }))
}

export default PrimaryNavigation
