import React from 'react'
import PropTypes from 'prop-types'
import { XMarkIcon } from '@heroicons/react/24/outline'

function CloseButton ({ onClick }) {
  return (
    <div className="absolute top-0 right-0 -mr-12 pt-2">
      <button
        type="button"
        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        onClick={() => onClick(false)}>
        <span className="sr-only">Close sidebar</span>
        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
export default CloseButton
