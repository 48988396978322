import { makeObservable, observable, action } from 'mobx'

class Property {
  integrationConfigs = {}
  constructor (store, json) {
    this.store = store
    this.id = (json?._id || json?.id)?.toString()
    this.updateFromJSON(json)

    makeObservable(this, {
      name: observable,
      slug: observable,
      logo: observable,
      logoutUrl: observable,
      labels: observable,
      services: observable,
      colors: observable,
      alert: observable,
      integrationConfigs: observable,
      prequalification: observable,
      addIntegrationsFromJSON: action,
      togglePrequalification: action,
      updatePrequalificationIntegrations: action
    })
  }

  updateFromJSON (json) {
    this.apiToken = json.apiToken
    this.name = json.name || ''
    this.slug = json.slug || ''
    this.logoutUrl = json.logoutUrl || ''
    this.services = json.services || []
    this.labels = json.labels || {}
    this.colors = json.colors || {}
    this.alert = json.alert || {}
    this.logo = json.logo || ''
    this.prequalification = json.prequalification || {}
    this._owner = json.owner
    return this
  }

  get owner () {
    return this.store.root.userStore.findById(this._owner)
  }

  addIntegrationsFromJSON = (configs) => {
    for (const config of configs) {
      if (!config.data) config.data = {}
      this.integrationConfigs[config.integration] = config
    }
  }

  saveIntegration = async (integration) => {
    await this.store.root.fetch(`/api/properties/${this.id}/update-integration`, 'POST', { integration, enabled: this.integrationConfigs[integration].enabled, data: this.integrationConfigs[integration].data })
  }

  togglePrequalification = async (enabled) => {
    if (!enabled) {
      this.prequalification.enabled = false
      await this.store.root.fetch(`/api/properties/${this.id}/disable-prequalification`, 'POST')
    } else {
      this.prequalification.enabled = true
      await this.store.root.fetch(`/api/properties/${this.id}/enable-prequalification`, 'POST')
    }
  }

  updatePrequalificationIntegrations = async (integrations) => {
    this.prequalification.lenderIntegrations = integrations.lenderIntegrations.map(integration => ({ ...integration, lender: integration.lender || undefined }))
    this.prequalification.creditIntegration = integrations.creditIntegration || undefined
    await this.store.root.fetch(`/api/properties/${this.id}/update-prequalification-integrations`, 'POST', {
      lenderIntegrations: this.prequalification.lenderIntegrations,
      creditIntegration: this.prequalification.creditIntegration
    })
  }
}

export default Property
