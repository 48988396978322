import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  AtSymbolIcon, PhoneIcon
} from '@heroicons/react/24/solid'
import toast from 'react-hot-toast'

import Application from '../../../stores/Application'
import Card from '../../layout/panels/BasicCard'
import CircleLoader from '../../elements/spinners/Circle'
import DataDisplay from '../../dataDisplay/descriptionList/LeftAligned'
import format from '../../../utils/format'
import integrations from '../../../constants/integrations'
import lenders from '../../../constants/lenders'
import PrimaryButton from '../../elements/buttons/Primary'
import useRootStore from '../../../hooks/useRootStore'

function OverviewCard ({ application, lenderData, loading }) {
  const rootStore = useRootStore()

  const lender = lenders[application.suggestedLender]
  const lenderIntegration = lender && integrations[lender.subdomain]

  const dataList = [
    { label: 'Stage', value: application.stageNiceLabel },
    { label: 'Created', value: dayjs(application.createdAt).format('MMM Do') },
    {
      label: rootStore.currentProperty.labels.applicant,
      value: (
        <>
          <div className="flex justify-between">
            <div>{application.primaryApplicant ? application.primaryApplicant.fullName : 'No Primary Applicant'}</div>
            {application.primaryApplicant.email && ['prequalification', 'lender-application'].includes(application.stage) &&
              <PrimaryButton
                label={application.stage === 'prequalification' ? 'Resend Invite' : 'Resend App Link'}
                onClick={async () => {
                  const { success } = await application.resendPrequalificationEmail()
                  if (success) toast.success('Email sent!')
                }
              }
              />
            }
          </div>
          {application.secondaryApplicant && <div>{application.secondaryApplicant.fullName}</div>}
        </>
      )
    },
    {
      label: 'Address',
      value: application.installationAddress.city + ', ' + application.installationAddress.state
    }
  ]
  if (lenderIntegration) {
    dataList.push({
      label: 'Lender',
      value: (
        <>
          <span className='mb-2'>{lenderIntegration.name}</span>
          <a href={`mailto:${lenderIntegration.propertySupportEmail}`} className='flex items-center mb-1 text-blue-500 text-xs'>
            <AtSymbolIcon className='h-3 w-3 mr-1' />
            {lenderIntegration.propertySupportEmail}
          </a>
          <a href={`tel:${lenderIntegration.propertySupportPhone}`} className='flex items-center text-blue-500 text-xs'>
            <PhoneIcon className='h-3 w-3 mr-1' />
            {format.phone(lenderIntegration.propertySupportPhone)}
          </a>
        </>
      )
    })
  }
  if (!rootStore.currentMerchant) {
    dataList.push({
      label: 'Merchant',
      value: application.merchant && application.merchant.name
    })
  }
  return (
    <Card>
      <div className="px-4 py-2 sm:py-5 sm:px-6 flex items-center justify-between">
        <h2 id="notes-title" className="md:text-lg font-medium text-gray-900">
          Overview
        </h2>
        {loading &&
        <div className='flex items-center'>
          <CircleLoader className='animate-spin mr-2 w-5 h-5 text-gray-600' />
          <span className='text-sm text-gray-500'>Loading...</span>
        </div>
        }
      </div>

      <div className="border-t border-gray-200">
        <DataDisplay
          list={[...dataList, ...lenderData]}
        />
      </div>
    </Card>
  )
}

OverviewCard.propTypes = {
  application: PropTypes.instanceOf(Application).isRequired,
  lenderData: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element
    ]).isRequired
  })),
  loading: PropTypes.bool
}

export default OverviewCard
