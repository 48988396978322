import React from 'react'
import PropTypes from 'prop-types'

import Question from './Question'

function QuestionList ({ application, questions, answers, setAnswers }) {
  return (
    <div className='space-y-4'>
      {questions.filter(question => !question.shouldShow || question.shouldShow(application, answers)).map((question, j) => {
        return (
          <Question
            key={question.key}
            dependency={question.keyDependency && answers[question.keyDependency]}
            answer={answers[question.key]}
            onChange={answer => setAnswers({ ...answers, [question.key]: answer })}
            application={application}
            question={question}
          />
        )
      })}
    </div>
  )
}

QuestionList.propTypes = {
  application: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired
}

export default QuestionList
