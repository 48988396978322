import { observer } from 'mobx-react-lite'
import { Outlet, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import DisplayErrorModal from '../../modals/DisplayErrorModal'
import Header from './Header'
import integrations from '../../../constants/integrations'
import lenders from '../../../constants/lenders'
import useRootStore from '../../../hooks/useRootStore'

function ApplicantWrapper () {
  const { id, merchantSlug } = useParams()
  const rootStore = useRootStore()

  const application = id && rootStore.applicationStore.findById(id)
  const lender = application && application.stage !== 'prequalification' && integrations[application.suggestedLender]
  const lenderInfo = application && lenders[application.suggestedLender]
  // @TO-DO Merchant is always null here, we need to find out why
  const merchant = merchantSlug ? rootStore.merchantStore.findBySlug(merchantSlug) : null

  useEffect(() => {
    if (!window.initAutocomplete) {
      window.initAutocomplete = () => {}
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>OPTIMUS Prequalification</title>
        <script
          async
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD0vTMwLuY68VRCG3d8RijMc6zy_ZqYy8M&libraries=places&callback=initAutocomplete"
        />
      </Helmet>
      <div data-cy='applicant-wrapper' className='bg-[#F3F3F3] min-h-screen flex flex-col'>
        <Header merchant={merchant} logo={lender ? lender.logoFull : '/img/optimus-main.svg'} />
        <Outlet />
        {lenderInfo?.footer && lenderInfo?.footer()}
      </div>
      <DisplayErrorModal
        errors={rootStore.currentDialogData}
        isOpen={rootStore.currentDialog.includes('lenderError')}
        onClose={rootStore.closeDialog}
      />
    </>
  )
}

ApplicantWrapper.propTypes = {}

export default observer(ApplicantWrapper)
