import React from 'react'
import { PropTypes } from 'prop-types'
import { useReactTable, createColumnHelper, getCoreRowModel } from '@tanstack/react-table'

import Table from '../../elements/lists/Table'

const columnHelper = createColumnHelper()

const getColumns = (property) => [
  columnHelper.accessor('primaryApplicant.fullName', {
    id: 'primaryApplicant.lastName',
    header: () => <span className=''>{property.labels.applicant}</span>,
    cell: (data) => data.row.original.stage === 'prequalification-invite'
      ? <i className='text-gray-900'>{data.row.original.primaryApplicant.email || data.row.original.primaryApplicant.mobile}</i>
      : (
        <span className='flex flex-col'>
          <span className='text-gray-900 font-medium'>{data.getValue()}</span>
          {data.row.original.secondaryApplicant &&
          <span className='text-gray-900 font-medium'>{data.row.original.secondaryApplicant.fullName}</span>
          }
        </span>
        )
  }),
  columnHelper.accessor('id', {
    header: '',
    cell: (data) => <span className='text-primaryLight font-medium'>Complete Project</span>
  })
]

function PendingApplications ({ applications }) {
  const table = useReactTable({
    data: applications,
    columns: getColumns({ labels: { applicant: 'Homeowner' } }),
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div>
      <div className='text-lg font-medium text-gray-900 mb-3'>Open Projects</div>
      {applications.length > 0 &&
      <Table className='rounded-lg' tableInstance={table} />
      }
      {applications.length === 0 &&
      <div className='text-gray-700 text-sm'>No open projects!</div>
      }
    </div>
  )
}

PendingApplications.propTypes = {
  applications: PropTypes.array.isRequired
}

export default PendingApplications
