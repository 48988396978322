import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/solid'

import Button from '../../elements/buttons/Primary'
import InputsWithSharedBorders from '../../forms/inputGroups/InputsWithSharedBorders'
import useRootStore from '../../../hooks/useRootStore'
import isEmail from '../../../utils/isEmail'

function Login () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const rootStore = useRootStore()
  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Log into your account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link to='/register' className="font-medium text-primaryLight hover:text-primaryLighter">
              create a new account
            </Link>
          </p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
          className="mt-8 space-y-6">
          <InputsWithSharedBorders
            inputs={[
              { cy: 'email', label: 'Email address', name: 'email', type: 'email', autoComplete: 'email', placeholder: 'Email address', value: email, onChange: e => setEmail(e.target.value), Icon: EnvelopeIcon },
              { cy: 'password', label: 'Password', name: 'password', type: 'password', autoComplete: 'new-password', placeholder: 'Password', value: password, onChange: e => setPassword(e.target.value), Icon: LockClosedIcon }
            ]}
          />

          <Button
            label='Login'
            cy='submit'
            disabled={!isEmail(email) || !password}
            onClick={async () => {
              if (email && password) {
                const response = await rootStore.fetch('/auth/login', 'POST', { email, password })

                if (response.success) {
                  window.location.href = '/'
                } else {
                  setErrorMessage('Invalid email or password. Please try again.')
                }
              }
            }}
          />
          {errorMessage &&
          <p data-cy='error' className="text-sm text-red-600 text-center">{errorMessage}</p>
          }
        </form>
      </div>
    </div>
  )
}

Login.propTypes = {}

export default Login
