const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/recalculateoffer' : 'https://api1.uat.greensky.com/recalculateoffer'
}
export default async function recalculateOffers (application, config, data) {
  const response = await fetch(apiURL(process.env.NODE_ENV), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey,
      token: data.metadata.token
    },
    body: JSON.stringify({
      program: 'GreenSky Consumer Projects',
      applicationId: data.id,
      loanAmount: data.metadata.amountNeeded,
      role: 'merchant',
      product: 'Installment'
    })
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
  })

  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }

  const json = await response.json()
  await application.save()
  return { success: true, lenderOffers: json.offers, error: json.error ? json.error.message : false }
}
