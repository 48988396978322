import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import PrimaryNavigation from '../../../navigation/vertical/PrimaryNavigation'
import Footer from './Footer'
import useRootStore from '../../../../hooks/useRootStore'

function DesktopSidebar ({ links, openHelpSidebar, hideFooter }) {
  const rootStore = useRootStore()
  const userTeamRelationships = rootStore.userTeamRelationships.filter((relationship) => {
    return relationship.merchant
      ? (!rootStore.currentMerchantID || relationship.merchant._id !== rootStore.currentMerchantID)
      : (rootStore.currentPropertyID !== relationship.property || !!rootStore.currentMerchantID)
  })
  const properties = []
  const merchants = []
  for (const relationship of userTeamRelationships) {
    if (relationship.property) properties.push({ name: relationship.property.name, id: relationship.property._id })
    if (relationship.merchant && relationship.merchant._id !== rootStore.currentMerchantID) merchants.push({ name: relationship.merchant.name, id: relationship.merchant._id })
  }

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div className="flex-1 flex flex-col min-h-0 bg-primaryDark">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <Header />
          <div className='px-4 mt-5 flex-1'>
            <PrimaryNavigation links={links} />
          </div>
          {(properties.length > 0 || merchants.length > 0) &&
          <div className='px-4 mt-5 space-y-4'>
            {properties.length > 0 &&
            <select
              className='mt-2 w-full text-xs bg-transparent text-white border-white'
              onChange={async (e) => {
                if (e.target.value) {
                  await rootStore.fetch('/api/users/set-property', 'POST', { id: e.target.value })
                  window.location.reload()
                }
              }}>
              <option className='text-gray-700' value=''>Switch to a property</option>
              {properties.map(property => (
                <option key={property.id} className='text-gray-700' value={property.id}>{property.name}</option>
              ))}
            </select>
            }
            {merchants.length > 0 &&
            <select
              className='mt-2 w-full text-xs bg-transparent text-white border-white'
              onChange={async (e) => {
                if (e.target.value) {
                  await rootStore.fetch('/api/users/set-merchant', 'POST', { id: e.target.value })
                  window.location.reload()
                }
              }}>
              <option className='text-gray-700' value=''>Switch to a merchant</option>
              {merchants.map(merchant => (
                <option key={merchant.id} className='text-gray-700' value={merchant.id}>{merchant.name}</option>
              ))}
            </select>
            }
          </div>
          }
        </div>
        {!hideFooter &&
        <Footer openHelpSidebar={openHelpSidebar} />
        }
      </div>
    </div>
  )
}

DesktopSidebar.propTypes = {
  links: PropTypes.array.isRequired,
  openHelpSidebar: PropTypes.func.isRequired,
  hideFooter: PropTypes.bool
}

export default DesktopSidebar
