import { makeObservable, observable, action } from 'mobx'
import Merchant from './Merchant'

class Merchants {
  merchants = []
  constructor (rootStore, merchants = []) {
    this.root = rootStore
    this.merchants = merchants.map(json => new Merchant(this, json))
    makeObservable(this, {
      merchants: observable,
      addMerchantFromJSON: action,
      updateMerchantFromJSON: action
    })
  }

  findById = (id) => this.merchants.find(merchant => merchant.id === id)
  findBySlug = (slug) => this.merchants.find(merchant => merchant.slug === slug)

  addMerchantFromJSON = (json) => {
    if (!this.findById(json._id)) {
      this.merchants.push(new Merchant(this, json))
      return this.merchants[this.merchants.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }

  updateMerchantFromJSON = (json) => {
    const merchant = this.findById(json._id)
    if (merchant) return this.findById(json._id).updateFromJSON(json)
  }
}

export default Merchants
