import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const fontSizes = {
  sm: 'text-xs'
}
const paddings = {
  sm: 'py-1 px-2'
}
const iconSizes = {
  sm: 'h-4 w-4'
}
function PrimaryButton ({ disabled, secondary, type, className, id, label, Icon, cy, danger, onClick, size }) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      id={id}
      disabled={disabled}
      onClick={onClick}
      data-cy={cy}
      className={classNames(
        'group relative flex shrink-0 justify-center border border-transparent font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryLight',
        fontSizes[size] || 'text-sm',
        paddings[size] || 'py-2 px-4',
        disabled
          ? 'text-gray-500 bg-gray-300'
          : secondary
            ? 'text-gray-800 font-medium bg-white hover:bg-gray-50 border border-gray-300'
            : 'text-white bg-primary hover:bg-primaryDark',
        danger && 'bg-red-500 text-white focus:ring-red-500 hover:bg-red-700',
        className
      )}>
      {Icon &&
      <span className="flex items-center mr-2">
        <Icon
          className={classNames(
            iconSizes[size] || 'h-5 w-5',
            disabled ? 'text-gray-400' : secondary ? 'text-gray-900 group-hover:text-gray-800' : 'text-white',
            danger && 'text-red-500 group-hover:text-red-400'
          )}
          aria-hidden="true"
        />
      </span>
      }
      <span>{label}</span>
    </button>
  )
}

PrimaryButton.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'reg']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  cy: PropTypes.string,
  onClick: PropTypes.func
}
PrimaryButton.defaultProps = {
  size: 'reg',
  type: 'button',
  disabled: false,
  secondary: false,
  danger: false,
  className: ''
}
export default PrimaryButton
