import React from 'react'
import PropTypes from 'prop-types'

import MobileSidebar from './MobileSidebar'
import DesktopSidebar from './DesktopSidebar'

function Sidebar ({ open, setOpen, navigation, setHelpOpen, hideFooter }) {
  return (
    <>
      <MobileSidebar
        hideFooter={hideFooter}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        links={navigation}
        openHelpSidebar={() => setHelpOpen(true)}
      />
      <DesktopSidebar
        hideFooter={hideFooter}
        links={navigation}
        openHelpSidebar={() => setHelpOpen(true)}
      />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  hideFooter: PropTypes.bool,
  navigation: PropTypes.array.isRequired,
  setHelpOpen: PropTypes.func.isRequired
}

export default Sidebar
