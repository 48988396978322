import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid'

import EllipseDropdown from '../../elements/dropdowns/EllipseDropdown'
import lenders from '../../../constants/lenders'
import useRootStore from '../../../hooks/useRootStore'
const apiURL = () => {
  return process.env.NODE_ENV === 'production' ? 'https://beta.apptracker.ftlfinance.com' : 'https://staging-apptracker.herokuapp.com'
}
function ExtraDropdown () {
  const rootStore = useRootStore()
  const { id } = useParams()
  const application = rootStore.applicationStore.findById(id)
  const links = []
  if (lenders[application.suggestedLender]) {
    links.push({
      label: 'Fetch update from Lender',
      onClick: async () => {
        await rootStore.fetch(`/api/applications/${id}/sync-lender`, 'POST')
      }
    })
  }
  if (application.lenderRelationship) {
    links.push({
      label: 'View Lenders Site',
      onClick: () => {
        window.open(`${apiURL()}/applications/${application.lenderRelationship.id}`, '_blank')
      }
    })
  }
  return (
    <div data-cy='extra-dropdown'>
      <EllipseDropdown
        cy='extra'
        links={links}
        Icon={WrenchScrewdriverIcon}
      />
    </div>
  )
}

ExtraDropdown.propTypes = {}

export default observer(ExtraDropdown)
