import React from 'react'
import PropTypes from 'prop-types'

import DropdownQuestion from './DropdownQuestion'
import ArrayQuestion from './ArrayQuestion'
import TextQuestion from './TextQuestion'
import DateQuestion from './DateQuestion'
import DollarQuestion from './DollarQuestion'

function Question (props) {
  return (
    <div>
      {props.question.type === 'option' && <DropdownQuestion {...props} />}
      {props.question.type === 'array' && <ArrayQuestion {...props} />}
      {props.question.type === 'text' && <TextQuestion {...props} />}
      {props.question.type === 'date' && <DateQuestion {...props} />}
      {props.question.type === 'dollar' && <DollarQuestion {...props} />}
    </div>
  )
}

Question.propTypes = {
  application: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  dependency: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default Question
