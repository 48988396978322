import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'

function Advanced ({ title, subtitle, sections, className }) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <div className={classNames(className, 'origin-top-right absolute z-20 w-56')}>
        <Menu.Items className="w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {/* Header */}
          {(title || subtitle) && (
            <div className="px-4 py-3">
              {title && <p className="text-sm">{title}</p>}
              {subtitle && <p className="text-sm font-medium text-gray-900 truncate">{subtitle}</p>}
            </div>
          )}

          {/* Sections */}
          {sections && sections.map((section, i) => (
            <div key={i} className="py-1">
              {/* Section */}
              {section.map((item, i) => (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm'
                      )}>
                      {item.Icon && <item.Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
                      {item.label}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </div>
    </Transition>
  )
}

Advanced.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    Icon: PropTypes.elementType,
    label: PropTypes.string.isRequired
  }))).isRequired,

  className: PropTypes.string
}

export default Advanced
