import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import PrimaryNavigation from '../../../navigation/vertical/PrimaryNavigation'

function DesktopSidebar ({ navigation }) {
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-primaryDark">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <Header />
          {navigation.map((links, i) => (
            <div key={i} className='px-4 mt-5'>
              {Array.isArray(links)
                ? (
                  <PrimaryNavigation links={links} />
                  )
                : (
                  <div />
                  )
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

DesktopSidebar.propTypes = {
  navigation: PropTypes.array.isRequired
}

export default DesktopSidebar
