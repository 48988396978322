import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { observer } from 'mobx-react-lite'

import s3 from '../../../utils/s3'
import useRootStore from '../../../hooks/useRootStore'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import Button from '../../elements/buttons/Primary'
import Dropzone from '../../forms/upload/Dropzone'
import ColorBox from './ColorBox'

function PropertySettings () {
  const rootStore = useRootStore()
  const [isUpdating, setIsUpdating] = useState(false)

  const [name, setName] = useState(rootStore.currentProperty.name)
  const [applicantLabel, setApplicantLabel] = useState(rootStore.currentProperty.labels.applicant || '')
  const [slug, setSlug] = useState(rootStore.currentProperty.slug)
  const [logoutUrl, setLogoutUrl] = useState(rootStore.currentProperty.logoutUrl)

  return (
    <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8'>
      <div className='flex justify-between items-center mb-12'>
        <div>
          <h1 id="message-heading" className="text-lg font-medium text-gray-900">
            Property
          </h1>
          <p className="mt-1 text-sm text-gray-500 truncate">Manage your property settings</p>
        </div>
      </div>

      <div className='space-y-16'>
        <div>
          <h3 className="text-md font-medium text-gray-900 mb-4">
            Logo
          </h3>
          <Dropzone
            onDrop={async (files) => {
              const { signedRequest, url } = await s3.requestSignedS3Url(files[0], 'v4-images-docs')
              await s3.uploadFileToS3(files[0], signedRequest)
              await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-logo`, 'POST', { url })
              toast.success('Property logo updated')
              rootStore.currentProperty.logo = url
            }}>
            <div className='flex flex-col items-start'>
              <div className='w-auto h-16 mt-1 cursor-pointer border'>
                <img src={rootStore.currentProperty.logo || '/logo-square.svg'} className='w-full h-full' />
              </div>
              <div className='text-sm text-gray-500 mt-1'>Pick a logo for your property</div>
            </div>
          </Dropzone>
        </div>

        <div>
          <h3 className="text-md font-medium text-gray-900 mb-4">
            Colors
          </h3>
          <div className='flex flex-wrap space-x-4'>
            <ColorBox
              label='Primary'
              color={rootStore.currentProperty.colors.primary}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primary = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
            <ColorBox
              label='Primary Light'
              color={rootStore.currentProperty.colors.primaryLight}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primaryLight = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
            <ColorBox
              label='Primary Lighter'
              color={rootStore.currentProperty.colors.primaryLighter}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primaryLighter = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
            <ColorBox
              label='Primary Lightest'
              color={rootStore.currentProperty.colors.primaryLightest}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primaryLightest = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
            <ColorBox
              label='Primary Dark'
              color={rootStore.currentProperty.colors.primaryDark}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primaryDark = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
            <ColorBox
              label='Primary Darker'
              color={rootStore.currentProperty.colors.primaryDarker}
              setColor={async (color) => {
                rootStore.currentProperty.colors.primaryDarker = color

                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-colors`, 'POST', rootStore.currentProperty.colors)
                toast.success('Property colors updated')
              }}
            />
          </div>
        </div>

        <div>
          <h3 className="text-md font-medium text-gray-900 mb-4">
            General
          </h3>

          <div className="">
            <InputWithLabel
              className="mb-3"
              label="Property Name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />

            <InputWithLabel
              className="mb-3"
              label="Identifier"
              name="slug"
              value={slug}
              onChange={e => setSlug(e.target.value)}
            />

            <InputWithLabel
              className="mb-5"
              label="Applicant Label"
              name="applicantLabel"
              value={applicantLabel}
              onChange={e => setApplicantLabel(e.target.value)}
            />

            <InputWithLabel
              className="mb-3"
              label="Logout URL"
              name="logoutUrl"
              value={logoutUrl}
              onChange={e => setLogoutUrl(e.target.value)}
            />
            <Button
              label={isUpdating ? 'Updating....' : 'Update'}
              disabled={isUpdating || (name === rootStore.currentProperty.name && slug === rootStore.currentProperty.slug && applicantLabel === rootStore.currentProperty.labels.applicant && logoutUrl === rootStore.currentProperty.logoutUrl)}
              className='max-w-xs'
              onClick={async () => {
                setIsUpdating(true)
                await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update`, 'POST', { slug, name, logoutUrl, labels: { applicant: applicantLabel } })
                rootStore.currentProperty.slug = slug
                rootStore.currentProperty.name = name
                rootStore.currentProperty.logoutUrl = logoutUrl
                toast.success('Property updated successfully')
                setIsUpdating(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PropertySettings)
