const approve1 = {
  applicantFirstName: 'DORENE',
  applicantMiddleName: 'L',
  applicantLastName: 'PETERSON',
  applicantDateOfBirth: '02/20/1944',
  applicantMarried: false,
  applicantSSN: '666-34-9068',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: true,
  applicantStreetAddress: '1014 EUGENE DR',
  applicantCity: 'SCHENECTADY',
  applicantState: 'NY',
  applicantZipCode: '12303',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const approve2 = {
  applicantFirstName: 'JAMES',
  applicantMiddleName: 'THERESA',
  applicantLastName: 'BREWSTER',
  applicantDateOfBirth: '11/01/1931',
  applicantMarried: false,
  applicantSSN: '666-20-0280',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: true,
  applicantStreetAddress: '148 BLOW RD',
  applicantCity: 'CHATEAUGAY',
  applicantState: 'NY',
  applicantZipCode: '12920',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const approve3 = {
  applicantFirstName: 'PATRICIA',
  applicantMiddleName: 'MAE',
  applicantLastName: 'GRUNDTISCH',
  applicantDateOfBirth: '11/01/1931',
  applicantMarried: false,
  applicantSSN: '666-67-4169',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: true,
  applicantStreetAddress: '3701 DOGWOOD LN',
  applicantCity: 'HAMBURG',
  applicantState: 'NY',
  applicantZipCode: '14075',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const approve4 = {
  applicantFirstName: 'PAULA',
  applicantMiddleName: 'N',
  applicantLastName: 'STINSON',
  applicantDateOfBirth: '11/22/1947',
  applicantMarried: false,
  applicantSSN: '666-47-2116',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: true,
  applicantStreetAddress: '5142 WEST PKWY',
  applicantCity: 'HAMBURG',
  applicantState: 'NY',
  applicantZipCode: '14075',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}

const approve5 = {
  applicantFirstName: 'RODNEY',
  applicantMiddleName: '',
  applicantLastName: 'HAROLDSON',
  applicantDateOfBirth: '12/24/1952',
  applicantMarried: false,
  applicantSSN: '666-44-5695',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: true,
  applicantStreetAddress: '24 UTICA AVE',
  applicantCity: 'HICKSVILLE',
  applicantState: 'NY',
  applicantZipCode: '11801',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const approve6 = {
  applicantFirstName: 'VILMA',
  applicantMiddleName: 'DIANE',
  applicantLastName: 'MERZ',
  applicantDateOfBirth: '11/15/1941',
  applicantMarried: false,
  applicantSSN: '666-61-7544',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '555-123-1234',
  applicantCellPhoneNumber: '555-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '611 COVIE RD',
  applicantCity: 'BOAZ',
  applicantState: 'AL',
  applicantZipCode: '35957',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const conditionalApproval1 = {
  applicantFirstName: 'ADELEINE',
  applicantMiddleName: '',
  applicantLastName: 'BROWN',
  applicantDateOfBirth: '1/1/1944',
  applicantMarried: false,
  applicantSSN: '666-34-7431',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '555-123-4567',
  applicantCellPhoneNumber: '555-123-4567',
  applicantMobileOptIn: false,
  applicantStreetAddress: '1447 BENTON ST',
  applicantCity: 'PITTSBURGH',
  applicantState: 'PA',
  applicantZipCode: '15212',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const conditionalApproval2 = {
  applicantFirstName: 'BENNY',
  applicantMiddleName: '',
  applicantLastName: 'BURKS',
  applicantDateOfBirth: '2/2/1956',
  applicantMarried: false,
  applicantSSN: '666-61-7544',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '501-624-9933',
  applicantCellPhoneNumber: '501-624-9933',
  applicantMobileOptIn: false,
  applicantStreetAddress: '825 QUAPAW AVE',
  applicantCity: 'HOT SPRINGS NATIONAL',
  applicantState: 'AR',
  applicantZipCode: '71901',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const conditionalApproval3 = {
  applicantFirstName: 'JUANTIA',
  applicantMiddleName: '',
  applicantLastName: 'FLETCHER',
  applicantDateOfBirth: '11/20/1971',
  applicantMarried: false,
  applicantSSN: '666-61-7544',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '555-123-4567',
  applicantCellPhoneNumber: '555-123-4567',
  applicantMobileOptIn: false,
  applicantStreetAddress: '149 JESSE ST',
  applicantCity: 'STAR',
  applicantState: 'NC',
  applicantZipCode: '27356',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const frozen1 = {
  applicantFirstName: 'MANUELA',
  applicantMiddleName: '',
  applicantLastName: 'ORTIZ',
  applicantDateOfBirth: '1/1/1946',
  applicantMarried: false,
  applicantSSN: '666-09-5010',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '12242 CINNAMON ST',
  applicantCity: 'WOODBRIDGE',
  applicantState: 'VA',
  applicantZipCode: '22192',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const frozen2 = {
  applicantFirstName: 'MISTY',
  applicantMiddleName: '',
  applicantLastName: 'DAVIDSON',
  applicantDateOfBirth: '1/1/1959',
  applicantMarried: false,
  applicantSSN: '666-15-9441',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '12015 OCONNOR RD',
  applicantCity: 'SAN ANTONIO',
  applicantState: 'TX',
  applicantZipCode: '78233',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const frozen3 = {
  applicantFirstName: 'RANDY',
  applicantMiddleName: '',
  applicantLastName: 'PELTZ',
  applicantDateOfBirth: '1/1/1946',
  applicantMarried: false,
  applicantSSN: '666-09-5010',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '2820 SW 80TH AVE',
  applicantCity: 'MIAMI',
  applicantState: 'FL',
  applicantZipCode: '33155',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const locked1 = {
  applicantFirstName: 'CAROLYN',
  applicantMiddleName: 'H',
  applicantLastName: 'SMITH',
  applicantDateOfBirth: '4/6/1960',
  applicantMarried: false,
  applicantSSN: '666-45-3795',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '1727 CHARLES ST',
  applicantCity: 'PORTSMOUTH',
  applicantState: 'OH',
  applicantZipCode: '45662',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const locked2 = {
  applicantFirstName: 'JEANETTE',
  applicantMiddleName: '',
  applicantLastName: 'SMITH',
  applicantDateOfBirth: '2/23/1946',
  applicantMarried: false,
  applicantSSN: '666-11-4114',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '2593 VONOA DR',
  applicantCity: 'RADCLIFF',
  applicantState: 'KY',
  applicantZipCode: '40160',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const locked3 = {
  applicantFirstName: 'SUSANNE',
  applicantMiddleName: 'E',
  applicantLastName: 'SMITH',
  applicantDateOfBirth: '2/4/1975',
  applicantMarried: false,
  applicantSSN: '666-06-0996',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '540 N NIXON AVE',
  applicantCity: 'LIMA',
  applicantState: 'OH',
  applicantZipCode: '45805',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const decline1 = {
  applicantFirstName: 'VICTOR',
  applicantMiddleName: '',
  applicantLastName: 'BAUN',
  applicantDateOfBirth: '6/20/1952',
  applicantMarried: false,
  applicantSSN: '666-57-1086',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '1127 AURARIA PKWY',
  applicantCity: 'DENVER',
  applicantState: 'CO',
  applicantZipCode: '80204',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const decline2 = {
  applicantFirstName: 'MICHAEL',
  applicantMiddleName: '',
  applicantLastName: 'CURIER',
  applicantDateOfBirth: '1/17/1962',
  applicantMarried: false,
  applicantSSN: '666-25-6464',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '1403 SAINT JOHN ST',
  applicantCity: 'LAFAYETTE',
  applicantState: 'LA',
  applicantZipCode: '70506',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}
const decline3 = {
  applicantFirstName: 'COLLEEN',
  applicantMiddleName: '',
  applicantLastName: 'STEWART',
  applicantDateOfBirth: '6/3/1969',
  applicantMarried: false,
  applicantSSN: '666-13-3404',
  applicantEmailAddress: 'samuel.eger@greensky.com',
  applicantPhoneNumber: '123-123-1234',
  applicantCellPhoneNumber: '123-123-1234',
  applicantMobileOptIn: false,
  applicantStreetAddress: '83 WAGON BRIDGE LN',
  applicantCity: 'SAGLE',
  applicantState: 'ID',
  applicantZipCode: '83860',
  applicantEmployerName: 'Global Employer',
  applicantGrossIncome: 100000
}

export default {
  approve1,
  approve2,
  approve3,
  approve4,
  approve5,
  approve6,
  conditionalApproval1,
  conditionalApproval2,
  conditionalApproval3,
  frozen1,
  frozen2,
  frozen3,
  locked1,
  locked2,
  locked3,
  decline1,
  decline2,
  decline3
}
