import React from 'react'

import Header from '../../headings/pageHeadings/Standard'
import CircleLoader from '../../elements/spinners/Circle'
import BasicCard from '../../layout/panels/BasicCard'

function LoadingState () {
  return (
    <div>
      <Header
        pages={[
          { label: 'Applications', current: false, to: '/applications' }
        ]}
        right={(
          <div className='flex items-center'>
            <CircleLoader className='animate-spin mr-2 w-5 h-5 text-gray-600' />
            <span className='text-sm text-gray-500'>Loading...</span>
          </div>
        )}
      />
      <div className="space-y-3 sm:space-y-6 px-4 sm:px-8 py-6 sm:py-8 xl:py-10 2xl:py-14 max-w-6xl mx-auto">
        <BasicCard>
          <div className="px-4 py-2 sm:py-5 sm:px-6 flex items-center justify-between">
            <h2 id="notes-title" className="md:text-lg font-medium text-gray-900">
              Overview
            </h2>
          </div>
          <div className='border-t flex animate-pulse py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
            <div className="h-5 w-14 mr-6 bg-slate-200 rounded-xl col-span-1" />
            <div className="h-5 w-20 mr-6 bg-slate-300 rounded-xl col-span-1 mt-1 sm:mt-0 sm:col-span-2" />
          </div>
          <div className='border-t flex animate-pulse py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
            <div className="h-5 w-14 mr-6 bg-slate-200 rounded-xl col-span-1" />
            <div className="h-5 w-24 mr-6 bg-slate-300 rounded-xl col-span-1 mt-1 sm:mt-0 sm:col-span-2" />
          </div>
          <div className='border-t flex animate-pulse py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
            <div className="h-5 w-14 mr-6 bg-slate-200 rounded-xl col-span-1" />
            <div className="h-5 w-20 mr-6 bg-slate-300 rounded-xl col-span-1 mt-1 sm:mt-0 sm:col-span-2" />
          </div>
          <div className='border-t flex animate-pulse py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
            <div className="h-5 w-14 mr-6 bg-slate-200 rounded-xl col-span-1" />
            <div className="h-5 w-28 mr-6 bg-slate-300 rounded-xl col-span-1 mt-1 sm:mt-0 sm:col-span-2" />
          </div>
          <div className='border-t flex animate-pulse py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6'>
            <div className="h-5 w-14 mr-6 bg-slate-200 rounded-xl col-span-1" />
            <div className="h-5 w-20 mr-6 bg-slate-300 rounded-xl col-span-1 mt-1 sm:mt-0 sm:col-span-2" />
          </div>
        </BasicCard>
      </div>
    </div>
  )
}

LoadingState.propTypes = {}

export default LoadingState
