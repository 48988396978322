import React from 'react'
import { Link } from 'react-router-dom'
import {
  ChevronLeftIcon
} from '@heroicons/react/24/outline'

function Header () {
  return (
    <div className="flex-shrink-0 flex items-center px-4">
      <Link to='/'>
        <ChevronLeftIcon className="h-6 w-6 text-white" />
      </Link>
      <span className="ml-2 text-lg font-medium text-white">
        Settings
      </span>
    </div>
  )
}

Header.propTypes = {}

export default Header
