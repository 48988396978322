import getApprovedAmount from './getApprovedAmount'
const apiURL = (env) => {
  return env === 'production' ? 'https://api1.greensky.com/offer' : 'https://api1.uat.greensky.com/offer'
}

export default async function getApplicationOffers (application, config, data) {
  const response = await fetch(`${apiURL(process.env.NODE_ENV)}?program=GreenSky+Consumer+Projects&applicationId=${data.id}&activationChannel=External-API-OptimusPlus`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.apiKey,
      token: data.metadata.token
    }
    // agent: new ProxyAgent(`${process.env.FIXIE_URL}:80`)
  })

  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.error }
  }

  const json = await response.json()
  await application.save()
  return { success: true, approvedAmount: getApprovedAmount(json), lenderOffers: json.offers, error: json.error ? json.error.message : false }
}
