import React, { useEffect, useState } from 'react'
import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import useRootStore from '../../../hooks/useRootStore'
import Table from '../../elements/lists/Table'
import Invite from '../../../stores/Invite'
import Badge from '../../elements/badges/Simple'
import Button from '../../elements/buttons/Primary'

const columnHelper = createColumnHelper()
const getColumns = (rootStore, id, allData, setData) => [
  columnHelper.accessor('id', {
    header: 'Members',
    cell: data => data.row.original instanceof Invite
      ? <span><i>{data.row.original.userInfo ? `${data.row.original.userInfo.firstName} ${data.row.original.userInfo.lastName}` : '-'}</i> <Badge label="Pending Invite" /></span>
      : `${data.row.original.firstName} ${data.row.original.lastName}`
  }),
  columnHelper.accessor('email', {
    header: 'Email'
  }),
  columnHelper.accessor('isAdmin', {
    header: 'Role',
    cell: data => (
      <div
        className={data.getValue() ? 'text-amber-500 font-bold' : 'text-gray-500'}
        onClick={() => {
          rootStore.fetch(`/api/merchants/${id}/set-member-role`, 'POST', { email: data.row.original.email, isAdmin: !data.row.original.isAdmin })
        }}>
        {data.getValue() ? 'Admin' : 'User'}
      </div>
    )
  }),
  columnHelper.accessor('createdAt', {
    header: '',
    cell: data => data.row.original instanceof Invite
      ? <Button
          label='Delete Invite'
          onClick={() => {
            rootStore.fetch(`/api/merchants/${id}/delete-invite`, 'POST', { inviteID: data.row.original.id })
            setData(allData.filter(d => d.id !== data.row.original.id))
          }}
        />
      : <Button
          label='Remove'
          onClick={() => {
            rootStore.fetch(`/api/merchants/${id}/remove-user-access`, 'POST', { relationshipID: data.row.original.id })
            setData(allData.filter(d => d.id !== data.row.original.id))
          }}
        />
  })
]
function MerchantDetailsMembers () {
  const { id } = useParams()
  const [data, setData] = useState([])
  const rootStore = useRootStore()
  // const [data, setData] = useState([])
  const table = useReactTable({
    data,
    columns: getColumns(rootStore, id, data, setData),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    async function init () {
      const { relationships, invites } = await rootStore.fetch(`/data/pages/merchant/${id}/members`)
      setData([
        ...invites.map(rootStore.inviteStore.addInviteFromJSON),
        ...relationships.sort((a, b) => Number(b.isAdmin) - Number(a.isAdmin)).map(relationship => {
          const user = rootStore.userStore.addUserFromJSON(relationship.user)
          return { firstName: user.firstName, lastName: user.lastName, email: user.email, isAdmin: relationship.isAdmin, id: relationship._id }
        })
      ])
    }
    init()
  }, [rootStore, id])

  return (
    <div className=''>
      <Table tableInstance={table} />
    </div>
  )
}

MerchantDetailsMembers.propTypes = {}

export default observer(MerchantDetailsMembers)
