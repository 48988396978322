import { makeObservable, observable, action } from 'mobx'

class Merchant {
  name
  slug
  owner
  propertyID
  lenderIDs = {}
  constructor (store, json) {
    this.store = store
    this.id = (json?._id || json?.id)?.toString()
    this.updateFromJSON(json)

    makeObservable(this, {
      name: observable,
      slug: observable,
      lenderIDs: observable,
      updateFromJSON: action,
      updateBasicInfo: action
    })
  }

  updateFromJSON (json) {
    this.name = json.name || ''
    this.slug = json.slug || ''
    this.lenderIDs = json.lenderIDs || {}
    this.owner = json.owner
    this.createdAt = json.createdAt
    this.propertyID = typeof json.property === 'string' ? json.property : this.store.root.propertyStore.addPropertyFromJSON(json.property).id
    return this
  }

  get asJSON () {
    return {
      name: this.name,
      slug: this.slug,
      owner: this.owner,
      property: this.propertyID
    }
  }

  get property () {
    return this.store.root.propertyStore.findById(this.propertyID)
  }

  get applicationLink () {
    return `${window.location.protocol}//${this.property.slug}.${window.location.host.split('.').filter(segment => segment !== 'www').join('.')}/${this.slug}/apply`
  }

  get to () {
    return `/merchants/${this.id}`
  }

  updateGeneralInfo = async (name, slug) => {
    await this.store.root.fetch(`/api/merchants/${this.id}/update`, 'POST', { name, slug })
    this.updateFromJSON({ ...this.asJSON, name, slug })
  }

  updateBasicInfo = async (data) => {
    await this.store.root.fetch(`/api/merchants/${this.id}/update`, 'POST', data)
    this.updateFromJSON({ ...this.asJSON, name: data.name })
  }
}

export default Merchant
