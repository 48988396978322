import validate from '../../../../utils/validate'

const dobGetErrorMessage = ({ answer }) => {
  const [m, d, y] = answer.split('/')
  const month = Number(m)
  const day = Number(d)
  const year = Number(y)
  if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    return 'Please enter a valid date.'
  }
  if (month < 1 || month > 12) {
    return 'Please enter a valid month.'
  }
  if (day < 1 || day > 31) {
    return 'Please enter a valid day.'
  }
  if (year < 1900 || year > 2021) {
    return 'Please enter a valid year.'
  }
  const today = new Date()
  const birthDate = new Date(answer)
  let age = today.getFullYear() - birthDate.getFullYear()
  const ageM = today.getMonth() - birthDate.getMonth()
  if (ageM < 0 || (ageM === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  if (age < 18) return 'You must be at least 18 years old to apply.'
}

const primaryApplicant = {
  name: 'Homeowner 1',
  key: 'primary',
  number: '02',
  startRemoved: () => false,
  required: () => true,
  isRemovable: () => false,
  subsections: [
    {
      questions: [
        [
          {
            label: 'First Name',
            key: 'primaryApplicant.firstName',
            type: 'text',
            span: 'sm:col-span-2',
            required: true,
            disabled: true
          },
          {
            label: 'Middle Name',
            placeholder: '(optional)',
            key: 'primaryApplicant.middleName',
            type: 'text',
            span: 'sm:col-span-2',
            required: false
          },
          {
            label: 'Last Name',
            key: 'primaryApplicant.lastName',
            type: 'text',
            span: 'sm:col-span-2',
            required: true,
            disabled: true
          },
          {
            label: 'Email Address',
            key: 'primaryApplicant.email',
            type: 'email',
            span: 'sm:col-span-6',
            required: true,
            getErrorMessage: ({ answer }) => {
              if (!answer) return 'Please enter an email address.'
              if (!validate.email(answer)) return 'Please enter a valid email address.'
            },
            disclosure: 'By providing your email address, you agree that we may use this email address to send you notifications and messages related to your account, respond to email requests we receive from you, and to contact you about our products, services, special offers, and other promotions.  You understand that email is not a secure means of communication and we are not responsible for any misdirection, interception, or other disclosure of the contents of an email properly addressed to you.  If you would like to opt out, contact us at 866-936-0602.'
          },
          {
            label: 'Mobile Number',
            key: 'primaryApplicant.mobile',
            type: 'phone',
            span: 'sm:col-span-6',
            required: false,
            getErrorMessage: ({ answer }) => {
              if (!answer) return 'Please enter a mobile number.'
              if (!validate.phone(answer)) return 'Please enter a valid mobile number.'
            },
            disclosure: 'You understand that if you have provided your mobile telephone number in any application field you authorize any financial institution that makes a GreenSky® loan to you and its agents, contractors, successors, assigns, or service providers to contact you at this mobile number and any future number that you provide for your cellular telephone or other wireless device by phone or text message using automated telephone dialing system or artificial or prerecorded voice messages, for any legal purpose, including, but not limited to, servicing a loan.  You may revoke your consent at any time by contacting us at 1-866-936-0602 or P.O. Box 2730, Alpharetta, GA 30023.'
          },
          {
            label: 'I want to opt-in to receive SMS notifications.',
            key: 'primaryApplicant.smsOptIn',
            type: 'checkbox',
            description: 'I want to opt-in to receive SMS notifications.',
            span: 'sm:col-span-6',
            className: '-mt-5 justify-end',
            required: true
          },
          {
            label: 'Social Security Number',
            key: 'primaryApplicant.ssn',
            type: 'ssn',
            required: true,
            getErrorMessage: ({ answer }) => {
              if (answer && !validate.ssn(answer)) {
                return 'Please provide a valid social security number.'
              }
            }
          },
          {
            label: 'Date of Birth',
            key: 'primaryApplicant.dob',
            type: 'day',
            required: false,
            getErrorMessage: dobGetErrorMessage,
            disclosure: 'Only U.S. Residents 18 years and older (19 in Alabama) may apply.'
          }
        ],
        [
          {
            label: 'Billing Address',
            key: 'primaryApplicant.address',
            type: 'address',
            canBeSameAs: 'installationAddress',
            canBeSameAsLabel: 'Same as Project Address',
            required: true,
            disclosure: 'Physical address required. No P.O. Boxes. If you are a participant in a state-sponsored Address Confidentiality Program, please call us at 1-833-215-6869 to apply.'
          }
        ],
        [
          {
            label: 'Married',
            key: 'primaryApplicant.married',
            type: 'checkbox',
            required: false,
            description: 'Please check the box, only if you are married',
            keyDependency: 'primaryApplicant.address.state',
            dependencyValue: 'WI'
          },
          {
            label: 'Spouse First Name',
            key: 'primaryApplicant.spouseFirstName',
            type: 'text',
            required: false,
            keyDependency: 'primaryApplicant.married',
            dependencyValue: true
          },
          {
            label: 'Spouse Last Name',
            key: 'primaryApplicant.spouseLastName',
            type: 'text',
            required: false,
            keyDependency: 'primaryApplicant.married',
            dependencyValue: true
          },
          {
            label: 'Spouse Address',
            key: 'primaryApplicant.spouseAddress',
            type: 'address',
            required: false,
            keyDependency: 'primaryApplicant.married',
            dependencyValue: true
          },
          {
            label: 'Income Type',
            key: 'primaryApplicant.employmentType',
            type: 'dropdown',
            required: true,
            options: [
              { label: 'Employment Income', value: 'full-time' },
              { label: 'Retirement Income', value: 'retired' },
              { label: 'Disability Income', value: 'disability' }
            ]
          },
          {
            label: 'Employer Name',
            key: 'primaryApplicant.employerName',
            type: 'text',
            keyDependency: 'primaryApplicant.employmentType',
            dependencyValue: 'full-time',
            required: false
          },
          {
            label: 'Annual Income',
            key: 'primaryApplicant.annualIncome',
            type: 'dollar',
            required: true,
            disclosure: 'List your total available wages, retirement benefits and any income from investments or rental properties. Alimony, child support or separate maintenance is optional. If you include non-taxable income/benefits increase them by 25%.'
          }
        ]
      ]
    }
  ]
}

export default primaryApplicant
