import React from 'react'
import { observer } from 'mobx-react-lite'
import { XMarkIcon } from '@heroicons/react/24/solid'
import toast from 'react-hot-toast'

import integrations from '../../../constants/integrations'
import useRootStore from '../../../hooks/useRootStore'
import Toggle from '../../forms/toggles/Simple'
import Select from '../../forms/SelectMenus/SelectWithLabel'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import Alert from '../../feedback/alerts/Simple'

function PrequalificationSettings () {
  const rootStore = useRootStore()

  const configs = rootStore.currentProperty.integrationConfigs
  const prequalSettings = rootStore.currentProperty.prequalification
  return (
    <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8'>
      {/* Header */}
      <div className='flex justify-between items-center'>
        <div>
          <h1 id="message-heading" className="text-lg font-medium text-gray-900">
            Prequalification
          </h1>
          <p className="mt-1 text-sm text-gray-500 truncate">Find the best lender for your applicants</p>
        </div>

        <Toggle
          label='Enabled'
          enabled={rootStore.currentProperty.prequalification.enabled}
          setEnabled={rootStore.currentProperty.togglePrequalification}
        />
      </div>

      {/* Misconfiguration Alert */}
      {prequalSettings.enabled && (!prequalSettings.creditIntegration || !prequalSettings.lenderIntegrations || !prequalSettings.lenderIntegrations.length) && (
        <Alert
          className='mt-8'
          type='warning'
          label='Invalid Configuration'
          description='You must select a credit bureau and at least one lender for the prequalification feature to be enabled.'
        />
      )}

      {/* Prequalification Settings */}
      <div className='mt-8 space-y-4'>
        <Select
          label='Credit Bureau'
          disabled={!rootStore.currentProperty.prequalification.enabled}
          options={[
            { value: '', label: 'Select a credit bureau' },
            ...Object.keys(integrations).filter(key => integrations[key].type === 'credit' && configs[key] && configs[key].enabled).map(key => ({ value: key, label: integrations[key].name }))
          ]}
          onChange={e => {
            rootStore.currentProperty.updatePrequalificationIntegrations({ creditIntegration: e.target.value, lenderIntegrations: rootStore.currentProperty.prequalification.lenderIntegrations })
            toast(e.target.value ? `Credit Bureau has been set to ${integrations[e.target.value].name}` : 'Credit Bureau has been removed')
          }}
          value={rootStore.currentProperty.prequalification.creditIntegration}
        />
        <div>
          <Select
            label='Lenders'
            disabled={!rootStore.currentProperty.prequalification.enabled}
            options={[
              { value: '', label: 'Add a lender' },
              ...Object.keys(integrations).filter(key => integrations[key].type === 'lender' && !(rootStore.currentProperty.prequalification.lenderIntegrations || []).find(i => i.lender === key) && rootStore.currentProperty.integrationConfigs[key] && rootStore.currentProperty.integrationConfigs[key].enabled).map(key => ({ value: key, label: integrations[key].name }))
            ]}
            onChange={e => {
              const lenderIntegrations = [...(rootStore.currentProperty.prequalification.lenderIntegrations || [])]
              if (!lenderIntegrations.find(i => i.lender === e.target.value)) {
                lenderIntegrations.push({
                  order: lenderIntegrations.length,
                  lender: e.target.value,
                  options: {}
                })
              }
              rootStore.currentProperty.updatePrequalificationIntegrations({ creditIntegration: rootStore.currentProperty.prequalification.creditIntegration, lenderIntegrations })
            }}
            value=''
          />
          <div className='bg-white mt-4 shadow rounded-md'>
            {[...(rootStore.currentProperty.prequalification.lenderIntegrations || [])].sort((a, b) => a.order - b.order).map(({ lender, options = {}, order }) => {
              const integration = integrations[lender]
              return (
                <div key={lender} className='flex flex-col sm:flex-row items-start sm:items-center justify-between py-3 px-4'>
                  <div className='flex items-center'>
                    <img src={integration.logo} className='w-12 h-12 mr-2' alt={integration.name} />
                    <div className='font-medium'>{integration.name}</div>
                  </div>

                  <div className='flex items-center'>
                    <div className='flex items-center space-x-2'>
                      <InputGroup
                        label='Order'
                        type='number'
                        className='w-20'
                        name='order'
                        value={order.toString()}
                        onChange={e => {
                          const lenderIntegrations = [...(rootStore.currentProperty.prequalification.lenderIntegrations || [])]
                          lenderIntegrations.find(i => i.lender === lender).order = Number(e.target.value)
                          rootStore.currentProperty.updatePrequalificationIntegrations({ creditIntegration: rootStore.currentProperty.prequalification.creditIntegration, lenderIntegrations })
                        }}
                      />
                    </div>

                    <XMarkIcon
                      className='cursor-pointer w-6 h-6 ml-8 text-gray-400'
                      onClick={() => {
                        const lenderIntegrations = [...rootStore.currentProperty.prequalification.lenderIntegrations]
                        lenderIntegrations.splice(lenderIntegrations.findIndex(i => i.lender === lender), 1)
                        rootStore.currentProperty.updatePrequalificationIntegrations({ creditIntegration: rootStore.currentProperty.prequalification.creditIntegration, lenderIntegrations })
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

PrequalificationSettings.propTypes = {}
export default observer(PrequalificationSettings)
