import { makeObservable, observable, action } from 'mobx'
import Application from './Application'

class Applications {
  applications = []
  constructor (rootStore, applications = []) {
    this.root = rootStore
    this.applications = applications.map(json => new Application(this, json))
    makeObservable(this, {
      applications: observable,
      addApplicationFromJSON: action
    })
  }

  findById = (id) => this.applications.find(application => application.id === id)

  addApplicationFromJSON = (json) => {
    if (!this.findById(json._id)) {
      this.applications.push(new Application(this, json))
      return this.applications[this.applications.length - 1]
    } else {
      return this.findById(json._id).updateFromJSON(json)
    }
  }
}

export default Applications
