import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { observer } from 'mobx-react-lite'

import useRootStore from '../../../hooks/useRootStore'
import Heading from '../../headings/sectionHeadings/WithDescription'
import DescriptionList from '../../dataDisplay/descriptionList/LeftAligned'
import classNames from 'classnames'

function MerchantDetailsOverview () {
  const { id } = useParams()
  const rootStore = useRootStore()

  const merchant = rootStore.merchantStore.findById(id)
  if (!merchant) return <div>Loading...</div>

  return (
    <div className='py-8'>
      <Heading title='General Information' description='Basic information about this merchant' />
      <DescriptionList
        list={[
          { label: 'Name', value: merchant.name },
          {
            label: 'Prequalification Link',
            value: merchant.slug
              ? (
                <span className='flex'>
                  <a href={merchant.applicationLink} target='_blank' rel='noreferrer' className='text-blue-600'>{merchant.applicationLink}</a>
                  <ClipboardIcon className='w-5 h-5 ml-2 cursor-pointer' onClick={() => navigator.clipboard.writeText(merchant.applicationLink)} />
                </span>
                )
              : <span className='text-gray-400'>No slug set. Go to <Link to='settings' className='text-blue-600'>settings</Link> to update</span>
          },
          {
            label: 'GreenSky Merchant ID',
            value: <span className={classNames('', { 'opacity-50': !merchant.lenderIDs.greensky })}>{merchant.lenderIDs.greensky || 'Not set'}</span>
          },
          {
            label: 'FTL Dealer ID',
            value: <span className={classNames('', { 'opacity-50': !merchant.lenderIDs.ftl })}>{merchant.lenderIDs.ftl || 'Not set'}</span>
          }
        ]}
      />
    </div>
  )
}

MerchantDetailsOverview.propTypes = {}
export default observer(MerchantDetailsOverview)
