const apiURL = (env) => {
  return env === 'production' ? 'https://api.greensky.com/cerberuspub/v1/partner/merchant/retrieve' : 'https://api.uat1.greensky.com/cerberuspub/v1/partner/merchant/retrieve'
}

export default async function collectMerchantDetails (merchant, lenderConfig) {
  const response = await fetch(apiURL(process.env.NODE_ENV), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': lenderConfig?.data?.x_api_key
    },
    body: JSON.stringify({ merchant_id: merchant?.lenderIDs?.greensky })
  })
  console.log(response.status, 'RESPONSE STATUS')
  if (response.status !== 200) {
    const errorResponse = await response.json()
    return { success: false, error: errorResponse.message }
  }
  const json = await response.json()
  merchant.lenderIDs.gsky_x_api_key = json.api_key
  await merchant.save()
  return { success: true, error: false }
}
