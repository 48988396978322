import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function WithDescription ({ title, description, right, className }) {
  return (
    <div className={classNames('flex items-center pb-5 justify-between', className)}>
      <div>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>
      </div>

      {right}
    </div>
  )
}

WithDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  right: PropTypes.node,
  className: PropTypes.string
}

export default WithDescription
