class Note {
  constructor (application, json) {
    this.application = application
    this.id = json._id.toString()
    this.message = json.message
    this.createdAt = json.createdAt
    this._author = typeof json.author === 'string' ? json.author : json.author._id.toString()
    if (typeof json.author === 'object') {
      application.store.root.userStore.addUserFromJSON(json.author)
    }
  }

  get author () {
    return this.application.store.root.userStore.findById(this._author)
  }
}

export default Note
