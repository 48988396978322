import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function Select ({ name, cy, disabled, value, onChange, options, className }) {
  return (
    <select
      name={name}
      data-cy={cy}
      disabled={disabled}
      className={classNames('mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primaryLighter focus:border-primaryLighter sm:text-sm rounded-md', className)}
      value={value}
      onChange={onChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  cy: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  className: PropTypes.string
}
export default Select
