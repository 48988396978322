import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

import format from '../../../utils/format'

import useRootStore from '../../../hooks/useRootStore'

function ApplicationDetails () {
  const { id } = useParams()
  const rootStore = useRootStore()
  const [service, setService] = useState()
  const [merchant, setMerchant] = useState()

  useEffect(() => {
    async function init () {
      const { service, merchant } = await rootStore.fetch(`/data/pages/applications/${id}/complete`)
      setService(service)
      setMerchant(merchant)
    }
    init()
  }, [setService, setMerchant, id, rootStore])
  const application = rootStore.applicationStore.findById(id)

  if (!application) return <div>Loading...</div>

  return (
    <div className="divide-y divide-gray-200 rounded-3xl shadow-sm border border-gray-200">
      <div className="sm:flex rounded-">
        <div className="px-4 py-5 sm:px-6 w-full">
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Contractor:</div>
            <div>{merchant}</div>
          </div>
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Project:</div>
            <div>{service}</div>
          </div>
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Application ID:</div>
            <div>{application.lenderRelationship?.id}</div>
          </div>
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Requested Amount:</div>
            <div>{format.amount(application.requestAmount)}</div>
          </div>
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Homeowner 1:</div>
            <div>{application.primaryApplicant.firstName + ' ' + application.primaryApplicant.lastName}</div>
          </div>
          {!!(application.secondaryApplicant && application.secondaryApplicant.firstName) &&
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Homeowner 2:</div>
            <div>{application.secondaryApplicant.firstName + ' ' + application.secondaryApplicant.lastName}</div>
          </div>
      }
          <div className="mt-5 text-sm flex text-gray-900 lg:w-1/2">
            <div className="mr-5">Address:</div>
            <div>{format.address(application.installationAddress)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

ApplicationDetails.propTypes = {}

export default observer(ApplicationDetails)
