import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation } from 'react-router-dom'

import useRootStore from '../../../hooks/useRootStore'
import SlideOver from '../../overlays/slideOvers/Default'
import MobileHeader from '../MobileHeader'
import Sidebar from './Sidebar'
import Alert from '../../feedback/alerts/Simple'

function MainWrapper ({ navigation, hideSidebarFooter }) {
  const rootStore = useRootStore()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setSidebarOpen(false)
  }, [location])
  return (
    <div data-cy='main-wrapper' className='flex h-full'>
      <Sidebar
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        navigation={navigation}
        setHelpOpen={setHelpOpen}
        hideFooter={hideSidebarFooter}
      />

      <div className="md:pl-64 flex flex-col flex-1 h-full">
        {rootStore.currentProperty?.alert.enabled && (
          <Alert type={rootStore.currentProperty.alert.type} label={rootStore.currentProperty.alert.label} description={rootStore.currentProperty.alert.description} />
        )}
        <MobileHeader setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 min-h-screen bg-[#F1F1F3]">
          <Outlet />
        </div>
      </div>
      <SlideOver open={helpOpen} setOpen={setHelpOpen} />
    </div>
  )
}

MainWrapper.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    current: PropTypes.bool.isRequired
  })).isRequired,
  hideSidebarFooter: PropTypes.bool
}

export default MainWrapper
