import React from 'react'

function ItemPlaceholder () {
  return (
    <div style={{ width: 375 }}>
      <div className="flex w-full items-center justify-between space-x-6 p-2 rounded-md bg-gray-300 mb-2" />
      <div className="flex w-full items-center justify-between space-x-6 p-6 rounded-md bg-gray-300" />
    </div>
  )
}

export default ItemPlaceholder
