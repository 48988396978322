import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

import { Link } from 'react-router-dom'
import classNames from 'classnames'

const Icons = {
  info: InformationCircleIcon,
  warning: ExclamationTriangleIcon
}

const bgColors = {
  info: 'bg-blue-50',
  warning: 'bg-yellow-50'
}
const iconColors = {
  info: 'text-blue-400',
  warning: 'text-yellow-400'
}
const labelColors = {
  info: 'text-blue-800',
  warning: 'text-yellow-800'
}
const descriptionColors = {
  info: 'text-blue-700',
  warning: 'text-yellow-700'
}
const labelHoverColors = {
  info: 'hover:text-blue-600',
  warning: 'hover:text-yellow-600'
}

function Simple ({ type, label, description, buttonLabel, buttonTo, className }) {
  const Icon = Icons[type]
  return (
    <div className={classNames('rounded-md p-4', bgColors[type], className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={classNames('h-5 w-5', iconColors[type])} aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div>
            <p className={classNames('text-sm', labelColors[type], { 'font-medium': !!description })}>{label}</p>
            {description &&
            <div className={classNames('mt-2 text-sm', descriptionColors[type])}>
              <p>{description}</p>
            </div>
            }
          </div>
          {buttonLabel &&
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <Link to={buttonTo} className={classNames('whitespace-nowrap font-medium', labelColors[type], labelHoverColors[type])}>
              {buttonLabel} <span aria-hidden="true">&rarr;</span>
            </Link>
          </p>
          }
        </div>
      </div>
    </div>
  )
}

Simple.propTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonTo: PropTypes.string,

  className: PropTypes.string
}
Simple.defaultProps = {
  type: 'info'
}

export default Simple
