import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { PlusIcon, MinusSmallIcon } from '@heroicons/react/24/solid'

import useRootStore from '../../../hooks/useRootStore'
import integrations from '../../../constants/integrations'
import PrimaryButton from '../../elements/buttons/Primary'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import toast from 'react-hot-toast'

function IntegrationSettings () {
  const { integration } = useParams()
  const rootStore = useRootStore()
  const [isSaving, setIsSaving] = useState(false)
  const integrationSettings = integrations[integration]
  const integrationConfig = rootStore.currentProperty.integrationConfigs[integration] || {}

  return (
    <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8'>
      <h3 className='text-3xl font-bold text-gray-600'>{integrationSettings.name}</h3>
      <p>{integrationSettings.description}</p>

      <div className='mt-8 bg-white shadow sm:rounded-lg flex justify-between items-center p-4'>
        <div className='flex items-center'>
          <img src={integrationSettings.logo} alt={integrationSettings.name} className='w-10 h-10 mr-4' />
          <div className='font-medium'>Integrate with {integrationSettings.name}</div>
        </div>

        <PrimaryButton
          label={integrationConfig.enabled ? 'Disable' : 'Enable'}
          danger={integrationConfig.enabled}
          onClick={async () => {
            const config = await rootStore.fetch(`/api/properties/${rootStore.currentProperty.id}/update-integration`, 'POST', { integration, enabled: !integrationConfig.enabled })
            rootStore.currentProperty.addIntegrationsFromJSON([config])
          }}
        />
      </div>
      {integrationConfig.enabled && !!Object.keys(integrationSettings.config).length && (
        <div className='bg-white shadow sm:rounded-lg p-4 mt-8 space-y-4'>
          {Object.keys(integrationSettings.config).map(key => (
            <div key={key}>
              {integrationSettings.config[key]?.type === 'text' &&
                <InputGroup
                  key={key}
                  name={key}
                  type='password'
                  label={integrationSettings.config[key].label}
                  placeholder={integrationSettings.config[key].placeholder}
                  value={integrationConfig.data[key] || ''}
                  onChange={() => {
                    integrationConfig.data[key] = event.target.value
                  }}
                />
              }
              {integrationSettings.config[key]?.type === 'array' &&
                <>
                  <div className='flex mb-3'>
                    <label className='block text-sm font-medium text-gray-700'>{integrationSettings.config[key].label}</label>
                    <PrimaryButton
                      secondary
                      label='Add'
                      onClick={() => {
                        integrationConfig.data[key].push('')
                      }}
                      className='ml-4'
                      Icon={PlusIcon}
                    />
                  </div>
                  {integrationConfig.data[key].map((option, index) => (
                    <div key={key + index} className='flex mb-2'>
                      <InputGroup
                        name={key + index}
                        type='text'
                        label={option.label}
                        placeholder={option.placeholder}
                        value={(integrationConfig.data[key] && integrationConfig.data[key][index]) || ''}
                        onChange={() => {
                          if (!integrationConfig.data[key]) integrationConfig.data[key] = []
                          integrationConfig.data[key][index] = event.target.value
                        }}
                      />
                      <PrimaryButton
                        secondary
                        label='Remove'
                        onClick={() => {
                          integrationConfig.data[key].splice(index, 1)
                        }}
                        className='ml-4'
                        Icon={MinusSmallIcon}
                      />
                    </div>
                  ))}
                </>
              }
            </div>
          ))}
          <PrimaryButton
            label={isSaving ? 'Saving...' : 'Save'}
            disabled={isSaving}
            onClick={async () => {
              setIsSaving(true)
              await rootStore.currentProperty.saveIntegration(integration)
              setIsSaving(false)
              toast.success('Integration settings saved!')
            }}
          />
        </div>
      )}
    </div>
  )
}

IntegrationSettings.propTypes = {}

export default observer(IntegrationSettings)
