import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import useRootStore from '../../../hooks/useRootStore'
import lenders from '../../../constants/lenders'
import Header from '../../headings/pageHeadings/Standard'
import OverviewCard from './OverviewCard'
import LenderActionCard from './LenderActionCard'
import ExtraDropdown from './ExtraDropdown'
import ChecklistCard from './ChecklistCard'
import LoadingState from './LoadingState'

function ApplicationDetail () {
  const rootStore = useRootStore()
  const { id } = useParams()
  const [loading, setLoading] = React.useState(true)

  const init = useCallback(async () => {
    const { application, merchant, logs, notes, lenderRelationship } = await rootStore.fetch(`/data/pages/applications/${id}`)
    rootStore.applicationStore.addApplicationFromJSON({ ...application, lenderRelationship }).addNotesFromJSON(notes)
    logs.forEach(rootStore.logStore.addLogFromJSON)
    if (merchant) rootStore.merchantStore.addMerchantFromJSON(merchant)
    setLoading(false)
  }, [rootStore, id])

  useEffect(() => {
    init()
  }, [rootStore, init, id])

  useEffect(() => {
    if (rootStore.socket) {
      rootStore.socket.emit('join-application', id)
      rootStore.socket.on('update', () => {
        setLoading(true)
        init()
      })
    }
  }, [rootStore.socket, init, id])

  const application = rootStore.applicationStore.findById(id)
  if (!application) return <LoadingState />

  const lender = lenders[application.suggestedLender]
  const lenderPreviewData = lender?.getPreviewData(application) || {}
  const lenderPreviewDataMapped = Object.keys(lenderPreviewData).map((key) => ({
    label: key,
    value: Array.isArray(lenderPreviewData[key])
      ? (
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {Object.keys(lenderPreviewData[key][0]).map((subKey) => (
                <th key={subKey} scope="col" className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900">
                  {subKey}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {lenderPreviewData[key].map((item, i) => {
              return (
                <tr key={i}>
                  {Object.keys(lenderPreviewData[key][0]).map((subKey) => (
                    <td
                      key={subKey}
                      className={classNames(
                        !i ? '' : 'border-t border-transparent',
                        'relative py-4 pl-3 sm:pl-3 pr-3 text-sm'
                      )}>
                      {lenderPreviewData[key][i][subKey]}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        )
      : lenderPreviewData[key]
  }))
  return (
    <>
      <Helmet>
        <title>{`Application ${application ? application.shortID : 'Loading...'}`}</title>
      </Helmet>
      <div>
        <Header
          pages={[
            { label: 'Applications', current: false, to: '/applications' },
            { label: application.shortID.toString(), current: true, to: `/applications/${id}` }
          ]}
          right={rootStore.currentUser.isDeveloper && <ExtraDropdown />}
        />
        <div className="space-y-3 sm:space-y-6 px-4 sm:px-8 py-6 sm:py-8 xl:py-10 2xl:py-14  max-w-6xl mx-auto">
          {rootStore.currentMerchant && lender && lender.actionSections.filter(action => action.shouldShow(application)).map((action) => (
            <LenderActionCard key={action.id} action={action} application={application} />
          ))}

          <OverviewCard application={application} lenderData={lenderPreviewDataMapped} loading={loading} />

          {/* Checklist */}
          <ChecklistCard application={application} />
        </div>
      </div>
    </>
  )
}

ApplicationDetail.propTypes = {}

export default observer(ApplicationDetail)
