import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table'
import day from 'dayjs'

import useRootStore from '../../../hooks/useRootStore'
import Header from '../../headings/pageHeadings/Standard'
import Table from '../../elements/lists/Table'
import EllipseDropdown from '../../elements/dropdowns/EllipseDropdown'
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'

const columnHelper = createColumnHelper()

const columns = [
  columnHelper.accessor('name', { header: 'Name' }),
  columnHelper.accessor('createdAt', {
    header: 'Member Since',
    cell: data => <span>{day(data.getValue()).format('MMM DD, YYYY')}</span>
  })
]

function MerchantList () {
  const rootStore = useRootStore()
  const [merchants, setMerchants] = React.useState(rootStore.merchantStore.merchants.filter(merchant => merchant.propertyID === rootStore.currentPropertyID))
  const [isLoading, setIsLoading] = React.useState(!merchants.length)

  useEffect(() => {
    async function init () {
      const { merchants } = await rootStore.fetch('/data/pages/merchants')
      setIsLoading(false)
      const m = merchants.map(rootStore.merchantStore.addMerchantFromJSON)
      setMerchants(m)
    }
    init()
  }, [rootStore])

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className="min-h-screen flex flex-col">
      <Header
        pages={[{ label: 'Merchants', current: true, to: '/merchants' }]}
        right={<EllipseDropdown links={[{ label: 'Add Merchant', onClick: () => rootStore.openDialog('addMerchant') }]} />}
      />
      {isLoading &&
      <LoadingState />
      }
      {!merchants.length && !isLoading &&
      <EmptyState />
      }
      {!!merchants.length &&
      <Table tableInstance={table} />
      }
    </div>
  )
}

export default observer(MerchantList)
