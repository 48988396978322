import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Button from '../../elements/buttons/Primary'
import Card from '../../layout/panels/BasicCard'
import integrations from '../../../constants/integrations'
import lenders from '../../../constants/lenders'
import useRootStore from '../../../hooks/useRootStore'
import SelectWithCheckMark from '../../forms/SelectMenus/SelectWithCheckMark'
import classNames from 'classnames'

function Plan ({ plan, setExpanded, isExpanded, setSelected, isSelected }) {
  return (
    <div className={classNames('border rounded shadow cursor-pointer hover:shadow-md overflow-hidden', { 'ring-2 ring-primary shadow-lg hover:shadow-lg': isSelected })} onClick={setSelected}>
      <div className='border-b py-2 px-2 text-sm font-medium text-gray-800'>{plan.description}</div>
      <div className='flex py-1.5 px-2'>
        <div className='w-1/2'>
          <div className='text-sm text-gray-700'>Interest Rate</div>
          <div className='font-medium text-sm'>{plan.minInterestRate}{plan.minInterestRate === plan.maxInterestRate ? '%' : `-${plan.maxInterestRate}%`}</div>
        </div>
        <div className='w-1/2'>
          <div className='text-sm text-gray-700'>Term</div>
          <div className='font-medium text-sm'>{plan.term} months</div>
        </div>
      </div>
      <div className='border-t bg-gray-50 flex items-center justify-center px-2 py-1.5' onClick={() => setExpanded(plan)}>
        {!isExpanded && <div className='text-xs text-gray-600 font-medium'>See Full Details</div>}
        {isExpanded &&
        <div className='text-xs text-gray-700'>{plan.details}</div>
        }
      </div>
    </div>
  )
}

function LenderSelect ({ plans }) {
  const rootStore = useRootStore()
  const { id } = useParams()
  const [expandedPlan, setExpandedPlan] = useState(null)

  const application = rootStore.applicationStore.findById(id)
  const [selectedPlan, setSelectedPlan] = useState()

  const lender = integrations[application.suggestedLender]
  const lenderInfo = lenders[application.suggestedLender]
  if (!lender) return <div>Loading...</div>
  console.log('plans', plans)

  return (
    <div data-cy='select-lender' className='flex flex-col items-center pt-16 pb-24 w-full max-w-4xl m-auto'>
      <Card className='w-80 max-w-full border mb-6'>
        <div className='border-b w-full py-4 flex justify-center'>
          <img src={lender.logoFull} className='h-8' />
        </div>
        {lenderInfo.prequalificationResult && lenderInfo.prequalificationResult(application, lender, selectedPlan)}
        {plans?.length &&
        <div className='py-2'>
          <div className='px-4 font-bold mb-2'>Select a plan:</div>
          <div className='px-4 space-y-2'>
            {plans.map(plan => (
              <Plan
                key={plan.value}
                plan={plan}
                setExpanded={(p) => setExpandedPlan(p === expandedPlan ? null : plan)}
                isExpanded={expandedPlan === plan}
                isSelected={selectedPlan === plan}
                setSelected={() => {
                  application.setAnswer('lenderRelationship.planNumber', selectedPlan === plan ? null : plan.value)
                  setSelectedPlan(selectedPlan === plan ? null : plan)
                }
                }
              />
            ))}
          </div>
        </div>
        }
      </Card>

      <Button
        cy='start-application'
        label={`Start ${lender.name}'s Application`}
        onClick={() => application.acceptLender()}
        disabled={plans && !selectedPlan}
      />
    </div>
  )
}

LenderSelect.propTypes = {
  plans: PropTypes.array
}

export default LenderSelect

/*
{plans?.length &&
          <div className="p-3">
            <SelectWithCheckMark
              label='Plan'
              name='plan'
              options={plans || []}
              value={application?.lenderRelationship?.planNumber || { label: 'Select...' }}
              onChange={e => {
                application.setAnswer('lenderRelationship.planNumber', e)
                setSelectedPlan(e)
              }}
            />
          </div>
        }
        */
