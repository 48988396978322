import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import Item from './Item'

function NavItem ({ to, label, count, Icon, subItems }) {
  return (
    <div className='relative' data-cy={to}>
      {to
        ? (
          <NavLink
            to={to}
            className={({ isActive }) => classNames('group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md text-white hover:bg-primaryDarker', {
              'text-gray-400': subItems && subItems.length > 0,
              'bg-primaryDarker': isActive
            })}>
            {({ isActive }) => (
              <Item label={label} count={count} Icon={Icon} isActive={isActive} />
            )}
          </NavLink>
          )
        : (
          <div className={classNames('group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md', { 'text-gray-500': subItems && subItems.length > 0 })}>
            <Item label={label} count={count} Icon={Icon} />
          </div>
          )
      }
    </div>
  )
}

NavItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  Icon: PropTypes.object,
  subItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }))
}

export default NavItem
