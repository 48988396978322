import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { observer } from 'mobx-react'

import states from '../../../constants/states'
import InputWithLabel from '../../forms/inputGroups/InputWithLabel'
import InputsWithSharedBorder from '../../forms/inputGroups/InputsWithSharedBorders'
import Button from '../../elements/buttons/Primary'
import Card from '../../layout/panels/BasicCard'
import Select from '../../forms/SelectMenus/SelectWithLabel'
import useRootStore from '../../../hooks/useRootStore'
import convertAddressComponents from '../../../utils/convertAddressComponents'
import validate from '../../../utils/validate'

function PreQualification () {
  const rootStore = useRootStore()
  const { merchantSlug } = useParams()
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [streetAddress2, setStreetAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [agreedOn, setAgreedOn] = useState()
  const [installStreetAddress, setInstallStreetAddress] = useState('')
  const [installStreetAddress2, setInstallStreetAddress2] = useState('')
  const [installCity, setInstallCity] = useState('')
  const [installState, setInstallState] = useState('')
  const [installZipCode, setInstallZipCode] = useState('')
  const [service, setService] = useState('')
  const [isSameAddress, setIsSameAddress] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState()
  const [inviteCode, setInviteCode] = useState('')
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    async function init () {
      const { merchant } = await rootStore.fetch('/data/pages/prequalification', 'get', null, { merchantSlug })
      rootStore.merchantStore.addMerchantFromJSON(merchant)
      if (searchParams.get('c')) setInviteCode(searchParams.get('c'))
    }
    init()
  }, [rootStore, merchantSlug, searchParams])

  const merchant = rootStore.merchantStore.findBySlug(merchantSlug)
  const requiredFieldsCompleted = !!agreedOn && !!firstName && !!lastName && !!streetAddress && !!city && !!state && !!zipCode && !!service && (isSameAddress === 'true' || (!!installStreetAddress && !!installCity && !!installState && !!installZipCode))

  return (
    <div data-cy='prequalification-form' className='flex flex-col items-center px-16 py-10'>
      <h2 className="mt-2 text-4xl font-bold text-gray-900 mb-4">Get Prequalified Today</h2>
      <p className="mt-1 text-lg text-gray-500 max-w-lg text-center">
        Let&apos;s start with a soft credit pull to find the best financing or leasing option for you.
      </p>

      <div className='w-full mt-8 max-w-4xl mx-auto'>
        <div className="flex space-x-6 w-full mb-8">
          <InputWithLabel
            label='First Name'
            name='firstName'
            type='text'
            value={firstName}
            className='flex-1'
            cy='first-name'
            onChange={e => setFirstName(e.target.value)}
          />
          <InputWithLabel
            label='Middle Name'
            name='middleName'
            placeholder='(Optional)'
            type='text'
            value={middleName}
            className='flex-1'
            cy='middle-name'
            onChange={e => setMiddleName(e.target.value)}
          />
          <InputWithLabel
            label='Last Name'
            name='lastName'
            type='text'
            value={lastName}
            className='flex-1'
            cy='last-name'
            onChange={e => setLastName(e.target.value)}
          />
        </div>

        <div className='mb-4'>
          <InputsWithSharedBorder
            label="Where do you live?"
            name='billingAddress'
            inputs={[
              {
                type: 'places',
                name: 'streetAddress',
                cy: 'streetAddress',
                placeholder: 'Street address',
                value: streetAddress,
                onChange: e => {
                  setStreetAddress(e.target.value)
                },
                onSelect: async (address, placeID) => {
                  const places = await geocodeByPlaceId(placeID)
                  if (places[0]) {
                    const address = convertAddressComponents(places[0].address_components)
                    setStreetAddress(address.streetAddress)
                    setCity(address.city)
                    setState(address.state)
                    setZipCode(address.zipCode)
                  }
                },
                errorMessage: 'Please enter a valid street address',
                isValid: () => {
                  return validate.streetAddress(streetAddress)
                }
              },
              {
                name: 'streetAddress2',
                cy: 'streetAddress2',
                placeholder: 'Apt, suite, etc.',
                value: streetAddress2,
                onChange: e => setStreetAddress2(e.target.value)
              },
              {
                name: 'city',
                cy: 'city',
                placeholder: 'City',
                value: city,
                errorMessage: 'Please enter a valid city',
                onChange: e => setCity(e.target.value),
                isValid: () => {
                  return validate.city(city)
                }
              },
              {
                name: 'state',
                cy: 'state',
                placeholder: 'State',
                type: 'option',
                options: [{ label: 'Select a state', value: '' }, ...states],
                value: state,
                onChange: e => setState(e.target.value)
              },
              {
                name: 'zipCode',
                cy: 'zipCode',
                placeholder: 'Zip Code',
                value: zipCode,
                onChange: e => setZipCode(e.target.value),
                errorMessage: 'Please enter a valid zip code',
                isValid: () => {
                  return validate.zipCode(zipCode)
                }
              }
            ]}
          />
        </div>

        <div className='mb-8'>
          <Select
            label='Is this the project address?'
            value={isSameAddress}
            name="isSameAddress"
            cy="isSameAddress"
            onChange={e => setIsSameAddress(e.target.value)}
            options={[
              { label: 'Select one', value: '' },
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ]}
          />
          {isSameAddress === 'false' && (
            <div className='mt-4'>
              <InputsWithSharedBorder
                label="Where will the project be completed?"
                name='installationAddress'
                inputs={[
                  {
                    type: 'places',
                    name: 'streetAddress',
                    placeholder: 'Street address',
                    value: installStreetAddress,
                    onChange: e => {
                      setInstallStreetAddress(e.target.value)
                    },
                    onSelect: async (address, placeID) => {
                      const places = await geocodeByPlaceId(placeID)
                      if (places[0]) {
                        const address = convertAddressComponents(places[0].address_components)
                        setInstallStreetAddress(address.streetAddress)
                        setInstallCity(address.city)
                        setInstallState(address.state)
                        setInstallZipCode(address.zipCode)
                      }
                    },
                    errorMessage: 'Please enter a valid street address',
                    isValid: () => {
                      return validate.streetAddress(streetAddress)
                    }
                  },
                  {
                    name: 'streetAddress2',
                    placeholder: 'Apt, suite, etc.',
                    value: installStreetAddress2,
                    onChange: e => setInstallStreetAddress2(e.target.value)
                  },
                  {
                    name: 'city',
                    placeholder: 'City',
                    value: installCity,
                    errorMessage: 'Please enter a valid city',
                    onChange: e => setInstallCity(e.target.value),
                    isValid: () => {
                      return validate.city(city)
                    }
                  },
                  {
                    name: 'state',
                    placeholder: 'State',
                    type: 'option',
                    options: [{ label: 'Select a state', value: '' }, ...states],
                    value: installState,
                    onChange: e => setInstallState(e.target.value)
                  },
                  {
                    name: 'zipCode',
                    placeholder: 'Zip Code',
                    value: installZipCode,
                    onChange: e => setInstallZipCode(e.target.value),
                    errorMessage: 'Please enter a valid zip code',
                    isValid: () => {
                      return validate.zipCode(zipCode)
                    }
                  }
                ]}
              />
            </div>
          )}
        </div>
        <Select
          label='What are you financing?'
          value={service}
          name='service'
          cy='service'
          onChange={e => setService(e.target.value)}
          options={[
            { label: 'Select one', value: '' },
            ...rootStore.currentProperty.services.map(service => ({ label: service.label, value: service.key }))
          ]}
        />
      </div>

      <div className='mt-10 max-w-4xl flex flex-col items-center'>
        <Card className='w-full mb-10 border-l-[24px] border-l-primaryDark py-5 px-4'>
          <div className='flex'>
            <p className='text-sm text-gray-600 leading-7'>
              <input
                data-cy='agreeToTerms'
                type='checkbox' className='m-1 rounded-sm' checked={!!agreedOn} onChange={() => setAgreedOn(agreedOn ? null : new Date())}
              /> By checking this box you are providing &#39;written instructions&#39; to FTL Finance under the Fair Credit Reporting Act authorizing FTL Finance to obtain
              information from your personal credit report or other information from Experian.  You authorize FTL Finance to obtain such information solely to conduct a pre-qualification
              for credit.
            </p>
          </div>
        </Card>

        <Button
          cy='submit'
          id='submit'
          disabled={!requiredFieldsCompleted || isSubmitting}
          label={isSubmitting ? 'One moment...' : 'Find My Lender'}
          onClick={async () => {
            setError()
            setIsSubmitting(true)
            const data = {
              experianAgreedOn: agreedOn,
              merchant: merchant.id,
              inviteCode,
              firstName,
              middleName,
              lastName,
              streetAddress,
              streetAddress2,
              city,
              state,
              zipCode,
              service,
              isSameAddress: true
            }
            if (isSameAddress === 'false') {
              data.isSameAddress = false
              data.installStreetAddress = installStreetAddress
              data.installStreetAddress2 = installStreetAddress2
              data.installCity = installCity
              data.installState = installState
              data.installZipCode = installZipCode
            }
            const { success, message, application } = await rootStore.fetch('/api/applications/prequalification', 'POST', data)
            if (!success) {
              setIsSubmitting(false)
              setError(message || 'There was an issue submitting your application. Please try again later.')
            } else {
              navigate(`/${merchantSlug}/apply/${application._id}`, { replace: true })
            }
          }}
        />

        {error &&
        <div className='mt-2 text-sm text-red-600' data-cy='experian-error'>
          {error}
        </div>
        }
      </div>
    </div>
  )
}

PreQualification.propTypes = {}

export default observer(PreQualification)
