import React from 'react'
import PropTypes from 'prop-types'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { observer } from 'mobx-react'

// import ListWithDescription from '../../forms/Checkboxes/ListWithDescription'
import CheckboxWithInlineDescription from '../../forms/Checkboxes/CheckboxWithInlineDescription'
import convertAddressComponents from '../../../utils/convertAddressComponents'
import InputGroup from '../../forms/inputGroups/InputWithLabel'
import InputsWithSharedBorders from '../../forms/inputGroups/InputsWithSharedBorders'
import MultiCheckboxWithInlineDescription from '../../forms/Checkboxes/MultiCheckboxWithInlineDescription'
import SelectWithCheckMark from '../../forms/SelectMenus/SelectWithCheckMark'
import states from '../../../constants/states'
import validate from '../../../utils/validate'

function Question ({ question, application, questionGroup }) {
  let answer
  if (question.key.includes('.')) {
    const [parentKey, childKey] = question.key.split('.')
    if (application[parentKey]) {
      answer = application[parentKey][childKey]
    }
  } else {
    answer = application[question.key]
  }
  let display = true
  if (question.keyDependency && question.dependencyValue) {
    let val
    if (question.keyDependency.includes('.')) {
      const [parentKey, childKey, subKey] = question.keyDependency.split('.')
      if (subKey) val = application[parentKey][childKey][subKey]
      else val = application[parentKey][childKey]
    } else {
      val = application[question.keyDependency]
    }
    display = val ? val === question.dependencyValue : false
  }

  let dependencyValue
  if (question.keyDependency && question.keyDependency.includes('.')) {
    const [parentKey, childKey] = question.keyDependency.split('.')
    dependencyValue = application[parentKey]?.[childKey]
  } else if (question.keyDependency) {
    dependencyValue = application[question.keyDependency]
  }
  const errorMessage = question.getErrorMessage ? question.getErrorMessage({ dependencyValue, answer }) : ''
  return (
    <div key={question.key} className={display && (question.span || 'sm:col-span-6')}>
      {['text', 'email', 'ssn', 'phone', 'day'].includes(question.type) && display && (
      <InputGroup
        name={question.key}
        cy={question.key}
        label={question.label}
        type={question.type}
        placeholder={question.placeholder}
        helpText={question.helpText}
        value={answer || ''}
        disabled={question.disabled}
        inputWidth={question.inputWidth}
        disclosure={question.disclosure}
        onChange={e => {
          application.setAnswer(question.key, e.target.value)
        }}
        errorMessage={errorMessage}
      />
      )}
      {question.type === 'message' && (question.message)}
      {question.type === 'dropdown' && (
      <SelectWithCheckMark
        label={question.label}
        cy={question.key}
        options={question.options}
        value={answer ? question.options.find(option => option.value === answer) : { label: 'Select' }}
        onChange={e => {
          application.setAnswer(question.key, e.value)
        }}
        errorMessage={errorMessage}
      />
      )}
      {question.type === 'multi-checkbox' && (
        <MultiCheckboxWithInlineDescription
          label={question.label(application)}
          className={question.className}
          description={question.description(application)}
          field={question.key}
          checked={answer}
          onChange={(e, key) => {
            application.setAnswer(question.key, e, key)
          }}
        />
      )}
      {question.type === 'checkbox' && display && (
      <CheckboxWithInlineDescription
        label={question.label}
        className={question.className}
        description={question.description}
        field={question.key}
        checked={answer}
        onChange={e => {
          application.setAnswer(question.key, e)
        }}
      />
      )}
      {question.type === 'dollar' && (
      <InputGroup
        name={question.key}
        cy={question.key}
        type='dollar'
        label={question.label}
        placeholder={question.placeholder}
        helpText={question.helpText}
        value={answer || ''}
        inputWidth={question.inputWidth}
        disclosure={question.disclosure}
        errorMessage={errorMessage}
        onChange={e => {
          application.setAnswer(question.key, e.target.value)
        }}
      />
      )}
      {question.type === 'address' && display && (
      <InputsWithSharedBorders
        label={question.label}
        type={question.type}
        name={question.key}
        className={question.key.includes('.') && 'gap-y-0'}
        canBeSameAsLabel={question.canBeSameAsLabel}
        disclosure={question.disclosure}
        sameAsValue={question.canBeSameAs ? (question.canBeSameAs.includes('.') ? application[question.canBeSameAs.split('.')[0]][question.canBeSameAs.split('.')[1]] : application[question.canBeSameAs]) : ''}
        // canPopulate={question.canPopulate}
        // populateAddress={e => application.setSameAddress(question.key, !e)}
        inputs={[
          {
            type: 'places',
            name: 'streetAddress',
            cy: 'streetAddress',
            placeholder: 'Street address',
            value: answer.streetAddress,
            onChange: e => {
              application.setAnswer(question.key, e.target.value, 'streetAddress')
            },
            onSelect: async (address, placeID) => {
              const places = await geocodeByPlaceId(placeID)
              if (places[0]) {
                const address = convertAddressComponents(places[0].address_components)
                application.setAnswer(question.key, address.streetAddress, 'streetAddress')
                application.setAnswer(question.key, '', 'streetAddress2')
                application.setAnswer(question.key, address.city, 'city')
                application.setAnswer(question.key, address.state, 'state')
                application.setAnswer(question.key, address.zipCode, 'zipCode')
              }
            },
            errorMessage: 'Please enter a valid street address',
            isValid: () => {
              return validate.streetAddress(answer.streetAddress)
            }
          },
          {
            name: 'streetAddress2',
            placeholder: 'Apt, suite, etc.',
            value: answer.streetAddress2,
            onChange: e => application.setAnswer(question.key, e.target.value, 'streetAddress2')
          },
          {
            name: 'city',
            cy: 'city',
            placeholder: 'City',
            value: answer.city,
            errorMessage: 'Please enter a valid city',
            onChange: e => application.setAnswer(question.key, e.target.value, 'city'),
            isValid: () => {
              return validate.city(answer.city)
            }
          },
          {
            name: 'state',
            cy: 'state',
            placeholder: 'State',
            type: 'option',
            options: [{ label: 'Select a state', value: '' }, ...states],
            value: answer.state,
            onChange: e => application.setAnswer(question.key, e.target.value, 'state')
          },
          {
            name: 'zipCode',
            cy: 'zipCode',
            placeholder: 'Zip Code',
            value: answer.zipCode,
            onChange: e => application.setAnswer(question.key, e.target.value, 'zipCode'),
            errorMessage: 'Please enter a valid zip code',
            isValid: () => {
              return validate.zipCode(answer.zipCode)
            }
          }
        ]}
      />
      )}
    </div>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  questionGroup: PropTypes.array.isRequired
}

export default observer(Question)
