const formatAmount = (amount) => {
  if (amount === undefined || amount === null) {
    return
  }
  if (amount.toString().includes('..')) {
    amount = (amount.toString()).replace('.', '')
  }
  const num = typeof amount === 'string' ? Number(amount.split('$').join('').split(',').join('')) : amount
  if (Number.isNaN(num)) {
    return
  }
  return num.toLocaleString(['en-Us'], { style: 'currency', currency: 'USD' })
}

const formatNumber = (phone) => {
  phone = phone.replace(/\D/g, '')
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return null
}

const formatNumberWithDashes = (phone) => {
  phone = phone.replace(/\D/g, '')
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }
  return null
}

const formatAddress = (address) => {
  if (address === undefined || address === null) {
    return
  }

  return address.streetAddress + ' ' + address.city + ', ' + address.state + ' ' + address.zipCode
}

export default {
  amount: formatAmount,
  phone: formatNumber,
  phoneWithDashes: formatNumberWithDashes,
  address: formatAddress
}
