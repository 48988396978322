import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'

import fetchUtil, { checkStatus, parseJSON } from '../utils/fetch'
import Applications from './Applications'
import Invites from './Invites'
import Merchants from './Merchants'
import Properties from './Properties'
import Users from './Users'
import Workflows from './Workflows'
import Logs from './Logs'

class RootStore {
  currentDialog = ''
  constructor (initialState = {}) {
    this.currentUserID = initialState.currentUserID
    this.currentPropertyID = initialState.currentPropertyID
    this.currentMerchantID = initialState.currentMerchantID
    this.environment = initialState.environment
    this.teamRelationship = initialState.teamRelationship
    this.merchantAccess = initialState.merchantAccess
    this.userTeamRelationships = initialState.userTeamRelationships || []

    this.userStore = new Users(this, initialState.users)
    this.propertyStore = new Properties(this, initialState.properties)
    this.workflowStore = new Workflows(this, initialState.workflows)
    this.merchantStore = new Merchants(this, initialState.merchants)
    this.inviteStore = new Invites(this, initialState.invites)
    this.applicationStore = new Applications(this, initialState.applications)
    this.logStore = new Logs(this, initialState.logs)
    makeObservable(this, {
      currentDialog: observable,
      currentMerchantID: observable,
      openDialog: action,
      closeDialog: action
    })
  }

  fetch (path, method, body, query) {
    return new Promise((resolve, reject) => {
      fetchUtil(path, method, body, query).then(checkStatus).then(parseJSON).then(resolve).catch(reject)
    })
  }

  get currentUser () {
    return this.userStore.findById(this.currentUserID)
  }

  get currentProperty () {
    return this.propertyStore.findById(this.currentPropertyID)
  }

  get currentMerchant () {
    return this.merchantStore.findById(this.currentMerchantID)
  }

  get colors () {
    return this.currentProperty ? this.currentProperty.colors : {}
  }

  initSocketIO = () => {
    if (window.io && !this.socket) {
      this.socket = window.io()
    }
  }

  openDialog = (key, data) => {
    this.currentDialog = key
    this.currentDialogData = data
  }

  closeDialog = () => {
    this.currentDialog = ''
  }
}

export const Context = createContext()

export default RootStore
