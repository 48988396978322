import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import useRootStore from '../../../hooks/useRootStore'
import Application from '../../../stores/Application'
import Button from '../../elements/buttons/Primary'

function LenderActionCard ({ application, action }) {
  const rootStore = useRootStore()

  return (
    <div data-cy={`action-card-${action.id}`} className='flex flex-col lg:flex-row bg-white items-center px-6 py-5 shadow sm:rounded-lg'>
      <div className='flex-1 mr-4'>
        <div className={classNames('text-base md:text-lg font-medium text-gray-900 mb-2', action.className)}>{action.title}</div>
        <div className={classNames('text-gray-500 text-sm md:text-base', action.className)}>{action.description}</div>
      </div>
      {action.label && <Button disabled={!rootStore.teamRelationship?.isAdmin} className='w-full mt-4 lg:w-auto lg:mt-0' label={action.label} onClick={() => rootStore.openDialog(`actionMerchant.${application.id}.${action.id}`)} />}
    </div>
  )
}

LenderActionCard.propTypes = {
  application: PropTypes.instanceOf(Application).isRequired,
  action: PropTypes.object.isRequired
}

export default LenderActionCard
